import React, { ReactNode } from 'react';
import { trackComponentError } from '@/myphoenix/utils/error-functions';

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback: ReactNode | null;
  componentName: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class FallbackErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error): void {
    const { componentName } = this.props;
    trackComponentError(componentName, { error });
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export default FallbackErrorBoundary;
