import { useMemo } from 'react';
import { useGetAttendancesByMembershipIdsQuery, useGetActivityGroupsBySectionIdsQuery } from '@/store/queries/v2/attendances';
import { CourseActivityGroups } from '@/types/api/learning';
import { StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { ByIdObject } from '@/helpers/object';

export type Attendance = {
  id?: string,
  membershipId?: string,
  attendance?: string,
};

const convertToByMembershipIdObject = (
  memberships: StudentCourseMembership[],
  sourceIds: string[],
  attendances: Attendance[],
  activityGroups: CourseActivityGroups[],
) => {
  let byMembershipId: ByIdObject = {};
  if (memberships && Array.isArray(memberships) && attendances && Array.isArray(attendances)
  && activityGroups && Array.isArray(activityGroups)) {
    memberships.forEach((membership: StudentCourseMembership) => {
      const { id: membershipId, sourceId: sectionId } = membership;
      const filteredAttendances = attendances?.filter(
        (attendance) => attendance?.membershipId === membershipId,
      );
      const sourceIndex: number = sourceIds.findIndex((sourceId) => sourceId === sectionId);
      if (membershipId && sourceIndex > -1) {
        byMembershipId = {
          ...byMembershipId,
          [membershipId]: {
            attendances: filteredAttendances,
            activityGroups: activityGroups[sourceIndex].activitygroups,
            allowedAbsences: activityGroups[sourceIndex].allowedAbsences,
          },
        };
      }
    });
  }
  return { byMembershipId };
};

export const useGetAttendancesHook = (memberships: StudentCourseMembership[]) => {
  const membershipIds: string[] = memberships.map(
    (membership: StudentCourseMembership) => membership.id,
  );

  const sourceIds: string[] = memberships.map(
    (membership: StudentCourseMembership) => membership.sourceId,
  );

  const {
    data: attendanceData = [],
    isFetching: isGetAttendancesByMembershipIdsFetching,
    isError: isGetAttendancesByMembershipIdsError,
  } = useGetAttendancesByMembershipIdsQuery(
    { membershipIds },
    { skip: !membershipIds || membershipIds?.length === 0 },
  );

  const {
    data: activityGroupData = [],
    isFetching: isGetActivityGroupsBySectionIdsFetching,
    isError: isGetActivityGroupsBySectionIdsError,
  } = useGetActivityGroupsBySectionIdsQuery(
    { sourceIds },
    { skip: !sourceIds || sourceIds?.length === 0 },
  );

  const data = useMemo(() => {
    if (
      isGetAttendancesByMembershipIdsFetching
      || isGetAttendancesByMembershipIdsError
      || isGetActivityGroupsBySectionIdsFetching
      || isGetActivityGroupsBySectionIdsError
    ) {
      return {};
    }

    const allAttendance: Attendance[] = [];
    attendanceData?.forEach((allAttendances) => (
      allAttendances.attendances.forEach(
        (attendance: { id: string, membershipId: string, attendance: string }) => (
          allAttendance.push(attendance)
        ),
      )));

    const attendances = convertToByMembershipIdObject(
      memberships,
      sourceIds,
      allAttendance,
      activityGroupData,
    );
    return {
      attendances,
    };
  }, [
    memberships,
    sourceIds,
    isGetAttendancesByMembershipIdsFetching,
    isGetAttendancesByMembershipIdsError,
    isGetActivityGroupsBySectionIdsFetching,
    isGetActivityGroupsBySectionIdsError,
    attendanceData,
    activityGroupData]);
  return {
    data,
    isFetching: isGetAttendancesByMembershipIdsFetching || isGetActivityGroupsBySectionIdsFetching,
    isError: isGetAttendancesByMembershipIdsError || isGetActivityGroupsBySectionIdsError,
  };
};
