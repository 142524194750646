import DroppedSingleTile from '@/components/molecules/dropped-tile';
import {
  getDroppedCourseMemberships,
  getEnrolledCourseMemberships,
} from '@/myphoenix/utils/course-functions';
import type { CourseInfoData } from '@/types/courseInfoData';
import type { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

const PROGRAM_TYPE = 'program';
const getFullName = (person?: { firstName?: string, lastName?: string }) => (
  person?.firstName && person?.lastName
    ? `${person.firstName} ${person.lastName}`.trim()
    : undefined
);

function DroppedScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: ({ currentCourseMemberships }: CourseInfoData) => (
      !!getDroppedCourseMemberships(currentCourseMemberships).length
      && !getEnrolledCourseMemberships(currentCourseMemberships).length
    ),
    Component({ courseInfoData }: { courseInfoData: CourseInfoData }) {
      const {
        parentComponentName,
        futureCourseMembershipsInNext7Days,
        currentCourseMemberships,
        loading: { courses: coursesAreLoading },
        currentCourses,
        primaryProgram: { name: programName, type: programType },
        enrollmentRep,
        academicCounselor,
      } = courseInfoData;

      const droppedCurrentCourseMemberships = getDroppedCourseMemberships(currentCourseMemberships);

      const counselorName = programType?.toLowerCase() === PROGRAM_TYPE
        ? getFullName(enrollmentRep) : getFullName(academicCounselor);
      const counselorPhone = programType?.toLowerCase() === PROGRAM_TYPE
        ? enrollmentRep?.phoneNumber : academicCounselor?.phoneNumber;

      return (
        <DroppedSingleTile
          parentComponentName={parentComponentName}
          small={
            !!droppedCurrentCourseMemberships.length
            && !!futureCourseMembershipsInNext7Days.length
          }
          courses={coursesAreLoading ? null : currentCourses}
          counselor={counselorName || null}
          phone={counselorPhone}
          primaryProgramName={programName}
        />
      );
    },
  };
}

export default DroppedScenario;
