import { useState } from 'react';
import dynamic, { DynamicOptionsLoadingProps } from 'next/dynamic';
import { Box } from '@mui/material';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import FallbackErrorBoundary from '@/helpers/fallback-error-boundary';
import { MYPHX_HEADER_MODULE_NAME } from '../myphx/constants';
import { CAREERS_STEP_CARD_MODULE } from '../careers/constants';

interface RemoteComponentProps {
  isEnabled: boolean
  componentName: string;
  moduleName: string;
  moduleProps?: object | null;
  loadingComponent?: (loadingProps: DynamicOptionsLoadingProps) => JSX.Element;
  fallbackComponent?: JSX.Element | null;
}

function NullLoadingComponent(): JSX.Element {
  return null;
}

function RemoteComponentContainer(
  {
    isEnabled,
    componentName,
    moduleName,
    moduleProps = null,
    loadingComponent = NullLoadingComponent,
    fallbackComponent = null,
  }: RemoteComponentProps,
) {
  const [isImported, setIsImported] = useState<boolean>(true);
  const dataComponentName = componentNameToMyPhxKebabFormat(
    `Mfe${componentName.charAt(0).toUpperCase() + componentName.slice(1)}`,
  );
  const validateImport = (module: any) => {
    setIsImported(module?.default?.length > 0);
    return isImported ? module : null;
  };

  if (!isEnabled) {
    return fallbackComponent;
  }

  let RemoteComponent;
  switch (moduleName) {
    case MYPHX_HEADER_MODULE_NAME:
      // eslint-disable-next-line import/no-cycle
      RemoteComponent = dynamic(() => import('myphx/Header').then(validateImport), {
        ssr: false,
        loading: loadingComponent,
      });
      break;
    case CAREERS_STEP_CARD_MODULE:
      RemoteComponent = dynamic(() => import('careerPersonalizationStepCard/StepCard').then(validateImport), {
        ssr: false,
        loading: loadingComponent,
      });
      break;
    default:
      RemoteComponent = null;
      break;
  }

  if (!isImported) {
    return fallbackComponent;
  }

  return (
    <FallbackErrorBoundary fallback={fallbackComponent} componentName={dataComponentName}>
      <Box data-component={dataComponentName}>
        <RemoteComponent {...moduleProps} />
      </Box>
    </FallbackErrorBoundary>
  );
}

export default RemoteComponentContainer;
