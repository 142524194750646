import { styled } from '@mui/material/styles';
import { Card as MuiCard, Typography, CardContent } from '@mui/material';

export const Card = styled(MuiCard)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(2),
  boxShadow: '0 0.1rem 0.2rem rgba(0,0,0,0.2)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  paddingBottom: theme.spacing(1),
}));
export const Header = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '1.4rem',
  color: theme.palette.text.disabled,
}));
export const Content = styled(CardContent)(() => ({
  padding: 0,
  '&:last-child': {
    padding: 0,
  },
}));
export const Text = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.6),
  marginBottom: theme.spacing(2.5),
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(6.7),
  },
}));
