import { useEffect, useMemo, useState } from 'react';
import {
  COURSE_INFO_START_MARK_NAME,
  COURSE_INFO_RENDERED_MARK_NAME,
} from '@/myphoenix/utils/event-functions';
import { addPerformanceMark } from '@/helpers/window-functions';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { toDateTime } from '@/myphoenix/utils/date-time-functions';
import {
  getCurrentCourseMemberships,
  getCurrentCourses,
  getDroppedCourses,
  getEnrolledCourses,
  getFutureCourseMemberships,
  getFutureCourses,
  getPastCourseMemberships,
  getPastCourses,
  augmentSectionsFromMemberships,
  aggregateCourseSkills,
} from '@/myphoenix/utils/course-functions';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import {
  useGetSkillsByProgramIdQuery,
  useGetProgramProgressDataByProgramIdQuery,
  useGetProgramInfoByProgramOfferingIdQuery,
} from '@/store/queries/programs';
import { useGetStudentLoaQuery, useGetCourseOutcomesQuery } from '@/store/queries/student';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import type { CourseInfoData, Instructor } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { useGetPrimaryProgramContactsByPersonIdHook } from '@/store/hooks/useGetPrimaryProgramContactsByPersonIdHook';
import { useGetLatestWorkflowQuery } from '@/store/queries/workflows';
import { useGetTasksQuery } from '@/store/queries/notifications';
import { StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { useGetMembershipsByPersonIdAndDateRangeQuery } from '@/store/queries/v2/student';
import { Course } from '@/types/courseInfoData';
import { useGetCoursesBySourceIdsQuery } from '@/store/queries/v2/courses';
import useGetInstructorsBySourceIdsTypeAndRole from '@/store/hooks/useGetInstructorsBySourceIdsTypeAndRole';
import { useGetApplicationInfoByIdQuery } from '@/store/queries/application';
import { useGetAllSkillsForCoursesQuery } from '@/store/queries/skill';
import { Box } from '@mui/material';
import { useGetBuilderDataModelByNameQuery } from '@/store/queries/builder.io';
import { COURSE_INFO } from '@/helpers/builder/models';
import { CourseInfoAuthoredData, EnrollmentTiles } from '@/helpers/builder/data-models/types/courseInfo';
import { getEditApplicationLink } from '@/myphoenix/utils/enrollment-functions';
import ErrorScenario from './scenarios/Error';
import LoadingScenario from './scenarios/Loading';
import ReenrollIScenario from './scenarios/ReenrollI';
import ReenrollScenario from './scenarios/Reenroll';
import PrepareForClassNewStudentScenario from './scenarios/PrepareForClassNewStudent';
import PrepareForClassReenrollScenario from './scenarios/PrepareForClassReenroll';
import AlumniReenrollScenario from './scenarios/AlumniReenroll';
import DroppedScenario from './scenarios/Dropped';
import CurrentCourseScenario from './scenarios/CurrentCourse';
import WithdrawnWithUpcomingCoursesScenario from './scenarios/WithdrawnWithUpcomingCourses';
import WithdrawnScenario from './scenarios/Withdrawn';
import AlumniSingleCourseScenario from './scenarios/AlumniSingleCourse';
import AlumniScenario from './scenarios/Alumni';
import LeaveOfAbsenceScenario from './scenarios/LeaveOfAbsence';
import UpcomingCourseScenario from './scenarios/UpcomingCourse';
import GraduatedScenario from './scenarios/Graduated';
import NearGraduatedScenario from './scenarios/NearGraduated';
import RescheduleScenario from './scenarios/Reschedule';
import WelcomeScenario from './scenarios/Welcome';
import UpcomingSmallScenario from './scenarios/UpcomingSmall';

export const componentName = 'CourseInfo';

// Define the scenarios in priority order
const scenarios: { name: string, instance: CourseInfoScenarioInterface }[] = [
  { name: 'ErrorScenario', instance: ErrorScenario() },
  { name: 'LoadingScenario', instance: LoadingScenario() },
  { name: 'ReenrollIScenario', instance: ReenrollIScenario() },
  { name: 'ReenrollScenario', instance: ReenrollScenario() },
  { name: 'PrepareForClassNewStudentScenario', instance: PrepareForClassNewStudentScenario() },
  { name: 'PrepareForClassReenrollScenario', instance: PrepareForClassReenrollScenario() },
  { name: 'AlumniReenrollScenario', instance: AlumniReenrollScenario() },
  { name: 'UpcomingSmallScenario', instance: UpcomingSmallScenario() },
  { name: 'DroppedScenario', instance: DroppedScenario() },
  { name: 'CurrentCourseScenario', instance: CurrentCourseScenario() },
  { name: 'WithdrawnWithUpcomingCoursesScenario', instance: WithdrawnWithUpcomingCoursesScenario() },
  { name: 'AlumniSingleCourseScenario', instance: AlumniSingleCourseScenario() },
  { name: 'WithdrawnScenario', instance: WithdrawnScenario() },
  { name: 'LeaveOfAbsenceScenario', instance: LeaveOfAbsenceScenario() },
  { name: 'UpcomingCourseScenario', instance: UpcomingCourseScenario() },
  { name: 'AlumniScenario', instance: AlumniScenario() },
  { name: 'GraduatedScenario', instance: GraduatedScenario() },
  { name: 'NearGraduatedScenario', instance: NearGraduatedScenario() },
  { name: 'RescheduleScenario', instance: RescheduleScenario() },
  { name: 'WelcomeScenario', instance: WelcomeScenario() },
];

function CourseInfo() {
  const [renderedScenarioName, setRenderedScenarioName] = useState<string>(null);
  if (!renderedScenarioName) {
    addPerformanceMark(COURSE_INFO_START_MARK_NAME);
  }

  useEffect(() => {
    if (renderedScenarioName) {
      addPerformanceMark(COURSE_INFO_RENDERED_MARK_NAME, renderedScenarioName);
    }
  }, [renderedScenarioName]);

  // Get all the data and flags
  const personId = getPersonIdFromSessionStorage();

  const {
    data: {
      selectedProgramId,
      primaryProgram = {},
      programsList,
    } = {},
    isFetching: primaryProgramLoading,
    isError: primaryProgramError,
  } = useGetProgramsByIdHook(personId);
  const selectedProgram = programsList?.byId[selectedProgramId] || primaryProgram;

  const { data: loa = null, isLoading: loaLoading } = useGetStudentLoaQuery(
    { personId },
  );

  const {
    data: { applicationType, applicationStartDate, application, applicationId } = {},
    isLoading: applicationLoading,
  } = useGetLatestWorkflowQuery({ personId });

  const {
    data: { enrollmentTasks, completedTasks } = {},
    isLoading: tasksLoading,
    isError: tasksError,
  } = useGetTasksQuery(
    { personId, applicationId },
    { skip: !applicationId },
  );
  const hasCompletedEnrollmentTasks = completedTasks?.filter(({ type }) => type === 'ENROLLMENT').length >= 1;

  const {
    isLoading: programProgressLoading,
    data: {
      programProgress: primaryProgramProgress,
    } = {},
  } = useGetProgramProgressDataByProgramIdQuery(
    { programId: primaryProgram?.id },
    { skip: !primaryProgram?.id },
  );

  const {
    data: memberships = [],
    isFetching: isGetMembershipsFetching,
    isError: isGetMembershipsError,
  } = useGetMembershipsByPersonIdAndDateRangeQuery(
    { personId },
    { skip: !personId },
  ) as {
    data: StudentCourseMembership[],
    isFetching: boolean,
    isError: boolean,
  };
  const sourceIds = memberships?.map(
    (membership: { sourceId?: string }) => membership.sourceId,
  );
  const currentCourseMemberships = getCurrentCourseMemberships(memberships);
  const futureCourseMembershipsInNext30Days = getFutureCourseMemberships(memberships, 30);
  const futureCourseMembershipsInNext7Days = getFutureCourseMemberships(memberships, 7);
  const futureCourseMembershipsInNext180Days = getFutureCourseMemberships(memberships, 180);
  const pastCourseMemberships = getPastCourseMemberships(memberships);

  const {
    data: sections = [],
    isFetching: isGetCoursesFetching,
    isError: isGetCoursesError,
  } = useGetCoursesBySourceIdsQuery(
    { sourceIds },
    { skip: !sourceIds.length },
  ) as {
    data: Course[],
    isFetching: boolean,
    isError: boolean,
  };
  const courses: Course[] = sections.length
    ? augmentSectionsFromMemberships(memberships, sections)
    : sections;
  const currentCourses = getCurrentCourses(courses);
  const futureCoursesInNext30Days = getFutureCourses(courses, 30);
  const futureCoursesInNext7Days = getFutureCourses(courses, 7);
  const futureCoursesInNext180Days = getFutureCourses(courses, 180);
  const pastCourses = getPastCourses(courses);
  const enrolledCurrentCourses = getEnrolledCourses(currentCourses);
  const droppedCurrentCourses = getDroppedCourses(currentCourses);

  const {
    data: { academicCounselor, enrollmentRep } = {},
    isFetching: universityContactsLoading,
  } = useGetPrimaryProgramContactsByPersonIdHook(personId);

  const { data: courseInfoAuthoredData } = useGetBuilderDataModelByNameQuery(
    { modelName: COURSE_INFO },
  ) as { data: CourseInfoAuthoredData };

  const enrollmentTiles : EnrollmentTiles = {
    postSubmitTasksText: courseInfoAuthoredData?.enrollmentTiles?.postSubmitTasksText || 'View enrollment tasks',
    postSubmitTasksUrl: courseInfoAuthoredData?.enrollmentTiles?.postSubmitTasksUrl || '/message-center.html',
    returnToApplicationText: courseInfoAuthoredData?.enrollmentTiles
      ?.returnToApplicationText || 'Return to application',
    returnToApplicationUrl: courseInfoAuthoredData?.enrollmentTiles
      ?.returnToApplicationUrl || getEditApplicationLink(
      Number(application?.version),
    ),
  };

  const enrolledAndDroppedCourses = useMemo(() => (
    droppedCurrentCourses?.length
      ? [...enrolledCurrentCourses, ...droppedCurrentCourses]
      : enrolledCurrentCourses
  ), [enrolledCurrentCourses, droppedCurrentCourses]);

  const { data: skillsForCourses, isLoading: courseSkillsLoading } = useGetAllSkillsForCoursesQuery(
    { courses: enrolledAndDroppedCourses },
    { skip: !enrolledAndDroppedCourses?.length },
  );

  const courseSkills = useMemo(
    () => aggregateCourseSkills(skillsForCourses),
    [skillsForCourses],
  );

  const {
    data: primaryProgramCourseOutcomes = null,
    isLoading: courseOutcomesLoading,
  } = useGetCourseOutcomesQuery({
    programId: primaryProgram?.id,
  }, { skip: !primaryProgram?.id });

  const {
    data: skillsForProgram,
    isLoading: programSkillsLoading,
  } = useGetSkillsByProgramIdQuery({
    programId: primaryProgram?.code,
    version: primaryProgram?.version,
  }, { skip: !primaryProgram?.code });

  const canUserViewSkills = useMemo(() => {
    if (primaryProgram && skillsForProgram) {
      const skillLaunchDate = skillsForProgram?.custom_metadata?.find(
        (metadata: { title: string }) => metadata?.title === 'Skills Launch Date',
      );
      if (!skillLaunchDate?.metadata_value) return false;
      const skillStartDate = toDateTime(skillLaunchDate?.metadata_value);
      const programStartDate = toDateTime(primaryProgram?.startDate);
      if (!skillStartDate.isValid || !programStartDate.isValid) return false;
      return programStartDate >= skillStartDate;
    }
    return false;
  }, [skillsForProgram, primaryProgram]);

  const {
    data: instructors = [],
    isFetching: instructorsLoading,
  } = useGetInstructorsBySourceIdsTypeAndRole({ sourceIds });

  const instructorsBySectionId = useMemo(() => {
    if (!instructors?.length) return {};
    return instructors.reduce((acc: { [key: string]: Instructor }, curr: Instructor) => ({
      ...acc,
      [curr.sectionId]: curr,
    }), {});
  }, [instructors]);

  const {
    data: { programOfferingId } = {},
    isLoading: applicationInfoLoading,
  } = useGetApplicationInfoByIdQuery(
    { applicationId },
    { skip: !applicationId },
  );

  const {
    data: programOffering,
    isLoading: programOfferingLoading,
  } = useGetProgramInfoByProgramOfferingIdQuery(
    { programOfferingId },
    { skip: !programOfferingId },
  );

  const courseInfoData: CourseInfoData = {
    parentComponentName: 'CourseInfo',
    loading: {
      primaryProgram: primaryProgramLoading,
      tasks: tasksLoading,
      enrollmentApp: applicationLoading,
      courseMemberships: isGetMembershipsFetching,
      loa: loaLoading,
      programProgress: programProgressLoading,
      programOffering: programOfferingLoading,
      universityContacts: universityContactsLoading,
      courses: isGetCoursesFetching,
      courseSkills: courseSkillsLoading,
      courseOutcomes: courseOutcomesLoading,
      programSkills: programSkillsLoading,
      instructors: instructorsLoading,
      applicationInfo: applicationInfoLoading,
    },
    error: {
      primaryProgram: primaryProgramError,
      tasks: tasksError,
      courseMemberships: isGetMembershipsError,
      courses: isGetCoursesError,
    },
    primaryProgram: {
      name: primaryProgram?.description,
      isCBEDA: primaryProgram?.cbeDaProgram === 'TRUE',
      type: primaryProgram?.type,
      qualificationLevel: primaryProgram?.qualificationLevel,
    },
    academicCounselor: {
      firstName: academicCounselor?.[0]?.firstName,
      lastName: academicCounselor?.[0]?.lastName,
      phoneNumber: academicCounselor?.[0]?.phoneNumber || '(800) 366-9699',
      email: academicCounselor?.[0]?.email,
    },
    enrollmentRep: {
      firstName: enrollmentRep?.[0]?.firstName,
      lastName: enrollmentRep?.[0]?.lastName,
      phoneNumber: enrollmentRep?.[0]?.phoneNumber || '800-858-2523',
      email: enrollmentRep?.[0]?.email,
    },
    enrollmentApp: {
      type: applicationType,
      startTime: applicationStartDate,
      submittedTime: application?.submittedTime,
      reviewStatus: application?.reviewStatus,
      version: application?.version,
      ID: applicationId,
      submissionStatus: application?.submissionStatus,
      program: programOffering || {},
    },
    hasCompletedEnrollmentTasks,
    hasIncompleteEnrollmentTasks: !!enrollmentTasks?.length,
    currentCourseMemberships,
    futureCourseMembershipsInNext30Days,
    futureCourseMembershipsInNext7Days,
    futureCourseMembershipsInNext180Days,
    pastCourseMemberships,
    currentCourses,
    futureCoursesInNext30Days,
    futureCoursesInNext7Days,
    futureCoursesInNext180Days,
    pastCourses,
    courseSkills,
    canUserViewSkills,
    instructorsBySectionId,
    leaveOfAbsenceDate: loa?.endDate,
    primaryProgramCourseOutcomes,
    primaryProgramProgress,
    selectedProgram,
    enrollmentStatus: primaryProgram?.statuses?.find((status: { statusType: string }) => (status.statusType === 'EnrollmentStatus')) || null,
    admissionStatus: primaryProgram?.statuses?.find((status: { statusType: string }) => (status.statusType === 'AdmissionStatus')) || null,
    courseInfoAuthoredData: { ...courseInfoAuthoredData, enrollmentTiles },
  };

  // Loop through the scenarios and return the first that should show
  const matchedScenario = scenarios.find(
    (scenario) => scenario.instance.shouldShow(courseInfoData),
  );

  if (!matchedScenario) return null;

  if (matchedScenario.name !== renderedScenarioName) {
    setRenderedScenarioName(matchedScenario.name);
  }
  const { Component } = matchedScenario.instance;

  return (
    <Box
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      data-testid="course-info"
      width="100%"
    >
      { Component && <Component courseInfoData={courseInfoData} /> }
    </Box>
  );
}

export default CourseInfo;
