import { sortMemberships } from '@/helpers/filter-courses';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { useGetProgramProgressDataByProgramIdQuery } from '@/store/queries/programs';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import useGetMembershipsAndCourses from '@/hooks/useGetMembershipsAndCourses';
import ProgramProgress from '..';

function HomeProgramProgress() {
  const personId = getPersonIdFromSessionStorage();

  const {
    data: { primaryProgram } = {},
  } = useGetProgramsByIdHook(personId);

  const {
    memberships,
    courses,
    isFetching: isGetMembershipAndCoursesFetching,
  } = useGetMembershipsAndCourses(personId);

  const {
    data: { auditDetails = [], creditsSummary } = {},
    isFetching: isProgramProgressFetching,
  } = useGetProgramProgressDataByProgramIdQuery({
    programId: primaryProgram?.id,
  }, { skip: !primaryProgram?.id });

  const isLoading = isGetMembershipAndCoursesFetching || isProgramProgressFetching;

  const sortedMembershipIds = sortMemberships(memberships);
  const currentCourses = sortedMembershipIds.currentMembershipIds.map((
    membershipId,
  ) => courses.find((course: { id: string }) => course.id === membershipId));
  const futureCourses = sortedMembershipIds.futureMembershipIds.map((
    membershipId,
  ) => courses.find((course: { id: string }) => course.id === membershipId));
  const enrollmentStatus = primaryProgram?.statuses ? primaryProgram.statuses.find((status: { statusType: string }) => (status.statusType === 'EnrollmentStatus')) : null;

  const programCompleted = enrollmentStatus && enrollmentStatus.status === 'C'
    && !currentCourses.length && !futureCourses.length;

  const finishedCourses = !!auditDetails && enrollmentStatus && enrollmentStatus.status === 'F'
    && !currentCourses.length && !futureCourses.length && !isLoading
    && creditsSummary?.notScheduled === 0;

  const alumni = enrollmentStatus && enrollmentStatus.status === 'G';
  const showGradOnly = true;

  if (primaryProgram?.type !== 'Degree' && primaryProgram?.type !== 'Certificate') {
    return null;
  }

  if (!alumni && !programCompleted && !finishedCourses) {
    return null;
  }
  return (
    <ProgramProgress studentProgramId={primaryProgram?.id} showGradOnly={showGradOnly} />
  );
}

export default HomeProgramProgress;
