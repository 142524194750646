import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Icon from '@/components/atoms/icon';
import { withTransientProps } from '@/helpers/with-transient-props';

export const StyledButton = styled('button', withTransientProps)<
{ $isFontOverride?: boolean }>(({ theme, $isFontOverride }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: theme.palette.common.black,
  fontSize: $isFontOverride ? theme.spacing(1.6) : theme.spacing(1.4),
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
}));

export const Border = styled('div')(({ theme }) => ({
  border: `0.1rem solid ${theme.palette.primary.main}`,
  padding: theme.spacing(1.6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const Text = styled(Typography, withTransientProps)<
{ $isFontOverride?: boolean }>(({ theme, $isFontOverride }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  // fontSize: '1.2rem',
  fontSize: $isFontOverride ? theme.spacing(1.6) : theme.spacing(1.2),
  marginBottom: theme.spacing(1.6),
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  marginTop: theme.spacing(-0.3),
  marginRight: theme.spacing(0.7),
}));
