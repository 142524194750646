import { Card, Stack, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@/components/atoms/icon';

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2.0, 0.5, 2.0, 2.0),
  '& .MuiCardContent-root:last-child': {
    paddingBottom: 0,
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1.5, 0.5, 1.5, 1.5),
    width: '100%',
  },
  animation: '$appear 0.5s ease',
  transformOrigin: '0% 0%',
}));

export const StyledContent = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  padding: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    padding: theme.spacing(0),
  },
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  height: theme.spacing(6),
  width: theme.spacing(6),
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: '70%',
  height: theme.spacing(12),
  padding: theme.spacing(1, 0),
  paddingRight: theme.spacing(2),
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '95%',
    padding: theme.spacing(0),
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  strokeWidth: 1,
  color: theme.palette.almostBlack.l30,
  margin: `${theme.spacing(2)} 0`,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    height: theme.spacing(10),
    margin: `0 ${theme.spacing(0.6)} 0 0`,
    width: theme.spacing(0.1),
  },
}));

export const TrophyBox = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: theme.spacing(30),
  justifyContent: 'center',
}));
