import { styled } from '@mui/material/styles';
import { Card, Grid, Typography, Divider } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2.0),
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  color: theme.palette.text.disabled,
  marginRight: theme.spacing(0.8),
}));

export const HeadingWrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  marginLeft: 0,
  marginTop: 0,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3.6),
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2.0, 0),
  display: 'block',
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(2.0, 7.2, 2.0, 0),
  },
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(1.5, 0),
  },
}));

export const DonutGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(0, 3, 2.5, 0),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(37),
  },
}));

export const GpaGrid = styled(Grid)(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(3.4, 0, 0, 4.7),
  },
}));

export const LoadingGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
