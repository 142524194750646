export const getAllCookies = () => {
  if (typeof document === 'undefined') {
    return new Map();
  }
  const cookieData = document.cookie.split(';').map((cookie) => {
    const trimmed = (cookie || '').trim();
    const key = trimmed.substring(0, trimmed.indexOf('='));
    const value = trimmed.substring(trimmed.indexOf('=') + 1);
    return [key, value];
  });
  return new Map(cookieData);
};

export const getIpAddressFromCookie = () => {
  const cookies = getAllCookies();
  return cookies.get('remote-address');
};
