import { styled } from '@mui/material/styles';
import { withTransientProps } from '@/helpers/with-transient-props';

export const ProgressCircle = styled(
  'circle',
  withTransientProps,
)<{ $numeratorColor?: string }>(({ theme, $numeratorColor = theme.palette.secondary.main }) => ({
  fill: 'transparent',
  stroke: $numeratorColor,
}));

export const CircleRing = styled('circle')(() => ({
  fill: 'transparent',
  stroke: '#FFF',
}));

export const DivRoot = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  '& .MuiAvatar-root': {
    position: 'absolute',
    width: '76%',
    height: '76%',
    top: '12%',
    left: '12%',
    fontSize: theme.spacing(4.4),
    '& img': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(5.4),
    },
  },
}));
export const DivInnerDataContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const DivDataInfo = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
  '& p': {
    lineHeight: '1',
    display: 'inline',
  },
}));
