import { Card as MuiCard, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  alignItems: 'stretch',
}));

export const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  height: '100%',
}));
