import { useMediaQuery } from '@mui/material';
import GenericWithdrawTile from '@/components/molecules/generic-withdraw-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { toDateTime } from '@/myphoenix/utils/date-time-functions';

const PROGRAM_TYPE = 'program';
const getFullName = (person?: { firstName?: string, lastName?: string }) => (
  person?.firstName && person?.lastName
    ? `${person.firstName} ${person.lastName}`.trim()
    : undefined
);

function WithdrawnScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => courseInfoData?.enrollmentStatus?.status?.toUpperCase() === 'W',
    Component: (
      {
        courseInfoData: {
          enrollmentStatus,
          academicCounselor,
          enrollmentRep,
          primaryProgram: { type: programType, name: primaryProgramName },
        },
      }: { courseInfoData: CourseInfoData },
    ) => {
      const isSmall = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

      const counselorName = programType?.toLowerCase() === PROGRAM_TYPE
        ? getFullName(enrollmentRep) : getFullName(academicCounselor);
      const counselorPhone = programType?.toLowerCase() === PROGRAM_TYPE
        ? enrollmentRep?.phoneNumber : academicCounselor?.phoneNumber;

      return (
        <GenericWithdrawTile
          primaryProgramName={primaryProgramName}
          counselor={counselorName || null}
          parentComponentName="course-info"
          phone={counselorPhone}
          small={isSmall}
          subType={enrollmentStatus?.subStatus}
          withdrawnDate={toDateTime(enrollmentStatus?.statusDate)}
        />
      );
    },
  };
}

export default WithdrawnScenario;
