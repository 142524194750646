import usePreviousCourseState from './previousCourse.state';
import PreviousCourse from './previousCourse.ui';

function PreviousCourseContainer() {
  const { previousCourseList, shouldShow } = usePreviousCourseState();

  if (!shouldShow) {
    return null;
  }

  return (
    <PreviousCourse previousCourseList={previousCourseList} />
  );
}
export default PreviousCourseContainer;
