import VultronCard from '@/components/atoms/vultron-card';
import PrepareForClass, { PrepareForClassTileType } from '@/components/molecules/alumni-reenroll-tile/prepare-for-class';
import ApplicationStatus, { ApplicationStatusTileType } from '@/components/molecules/alumni-reenroll-tile/application-status';

type AlumniReEnrollTileType = PrepareForClassTileType & ApplicationStatusTileType;

function AlumniReEnrollTile({
  // Props for PrepareForClass tile
  showTermInfo,
  termStartDate,
  termEndDate,
  isCBEDA,
  contactFirstName,
  contactLastName,
  contactPhoneNumber,
  contactEmail,
  postSubmitTasksText,
  postSubmitTasksUrl,
  returnToApplicationText,
  returnToApplicationUrl,
  // Props for ApplicationStatus tile
  programTitle,
  applicationReviewStatus,
  applicationSubmissionStatus,
  applicationSubmittedAt,
  hasIncompleteEnrollmentTasks,
  hasCompletedEnrollmentTasks,
  applicationId,
  applicationType,
}: AlumniReEnrollTileType) {
  return (
    <VultronCard
      componentName="AlumniReEnrollTile"
      leftChild={(
        <PrepareForClass
          showTermInfo={showTermInfo}
          termStartDate={termStartDate}
          termEndDate={termEndDate}
          applicationSubmissionStatus={applicationSubmissionStatus}
          hasIncompleteEnrollmentTasks={hasIncompleteEnrollmentTasks}
          isCBEDA={isCBEDA}
          contactFirstName={contactFirstName}
          contactLastName={contactLastName}
          contactPhoneNumber={contactPhoneNumber}
          contactEmail={contactEmail}
          postSubmitTasksText={postSubmitTasksText}
          postSubmitTasksUrl={postSubmitTasksUrl}
          returnToApplicationText={returnToApplicationText}
          returnToApplicationUrl={returnToApplicationUrl}
        />
      )}
      rightChild={(
        <ApplicationStatus
          programTitle={programTitle}
          applicationSubmissionStatus={applicationSubmissionStatus}
          applicationReviewStatus={applicationReviewStatus}
          returnToApplicationText={returnToApplicationText}
          returnToApplicationUrl={returnToApplicationUrl}
          applicationSubmittedAt={applicationSubmittedAt}
          hasIncompleteEnrollmentTasks={hasIncompleteEnrollmentTasks}
          hasCompletedEnrollmentTasks={hasCompletedEnrollmentTasks}
          applicationId={applicationId}
          applicationType={applicationType}
        />
      )}
    />
  );
}

export default AlumniReEnrollTile;
