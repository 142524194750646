import { styled } from '@mui/material/styles';
import { Box, Card, Typography, CardContent } from '@mui/material';
import StandardLink from '@/components/atoms/standard-link';
import { withTransientProps } from '@/helpers/with-transient-props';

export const StyledCard = styled(Card, withTransientProps)<{ $dangerzone?: boolean }>(
  ({ theme, $dangerzone = false }) => ({
    padding: theme.spacing(2.0, 2.0, 3.0, 2.0),
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2.0, 5.5, 2.0, 2.0),
    },
    border: $dangerzone ? `${theme.spacing(0.2)} solid ${theme.palette.primary.main}` : 'unset',
  }),
);

export const StyledHeading = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  color: theme.palette.text.disabled,
  marginRight: theme.spacing(0.8),
}));

export const HeadingWrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  marginBottom: theme.spacing(3.2),
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1.4),
  },
}));

export const StyledCardContentRoot = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0,
}));

export const StyledCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}));

export const StyledTextContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3.1),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(54.0),
    marginLeft: theme.spacing(3.2),
    marginTop: 0,
  },
}));

export const StyledCountDownText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.6),
}));

export const StyledDonutBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(17.5),
  minWidth: theme.spacing(12.5),
  [theme.breakpoints.up('lg')]: {
    width: theme.spacing(15.3),
  },
  '& div div div p:first-of-type': {
    fontSize: theme.spacing(4.0),
  },
}));

export const StyledWarningTitle = styled('b')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const DangerTextLarge = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.2),
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecorationColor: theme.palette.almostBlack.d20,
  '&:hover, &:focus': {
    textDecorationColor: theme.palette.primary.main,
  },
}));

export const CTAWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'flex-end',
  },
  alignSelf: 'flex-start',
}));
