import { styled } from '@mui/material/styles';
import Icon from '@/components/atoms/icon';
import { Grid, Typography } from '@mui/material';
import StandardLink from '@/components/atoms/standard-link';
import StandardButton from '@/components/atoms/standard-button';

export const CourseIcon = styled(Icon)(({ theme }) => ({
  flex: `0 1 ${theme.spacing(3.4)}`,
  height: theme.spacing(5),
  width: theme.spacing(5),
  margin: `${theme.spacing(1)} ${theme.spacing(0.7)} 0`,
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(0.6),
    flex: `0 1 ${theme.spacing(3.2)}`,
  },
}));

export const Button = styled(StandardButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: theme.spacing(2.8),
  height: theme.spacing(4),
  textTransform: 'none',
  boxShadow: 'none',
  padding: theme.spacing(0, 1),
  marginBottom: theme.spacing(1.2),
  fontSize: theme.spacing(1.6),
  width: '100%',
  float: 'right',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(24),
    padding: theme.spacing(3, 0),
    fontWeight: 900,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
  },

}));

export const CodeLink = styled(StandardLink)(({ theme }) => ({
  display: 'inline-block',
  fontSize: theme.spacing(1.6),
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2.4),
  borderBottom: `${theme.spacing(0.3)} solid`,
  '&:hover, &:focus': {
    textDecoration: 'none',
    borderColor: theme.palette.primary.main,
  },
  '&:focus': {
    outlineStyle: 'solid',
    outlineColor: theme.palette.text.primary,
    outlineOffset: theme.spacing(0.3),
  },
}));

export const StatusText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  fontStyle: 'italic',
  color: theme.palette.primary.main,
  lineHeight: theme.spacing(1.6),
  marginTop: theme.spacing(0.7),
  marginLeft: theme.spacing(0.7),
}));

export const SmallText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontStyle: 'italic',
  fontSize: theme.spacing(1.2),
  fontWeight: 600,
  minWidth: theme.spacing(7.8),
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(1.2),
  },
}));

export const CodeLinkList = styled(StandardLink)(({ theme }) => ({
  display: 'inline-block',
  fontSize: theme.spacing(1.6),
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(0.6),
  borderBottom: `${theme.spacing(0.3)} solid`,
  '&:hover, &:focus': {
    textDecoration: 'none',
    borderColor: theme.palette.primary.main,
  },
  '&:focus': {
    outlineStyle: 'solid',
    outlineColor: theme.palette.text.primary,
    outlineOffset: theme.spacing(0.3),
  },
}));

export const ButtonGrid = styled(Grid)(() => ({
  margin: 'auto 0',
}));
