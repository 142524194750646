import { styled } from '@mui/material/styles';
import {
  Box,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  IconButton,
} from '@mui/material';

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    maxWidth: theme.spacing(68.3),
    maxHeight: '100vh',
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(46),
    },
  },
}));

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  height: theme.spacing(4.6),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ActionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: theme.spacing(5.4),
}));

export const AccessibleIconButton = styled(IconButton)(({ theme }) => ({
  '&.Mui-disabled svg': {
    fill: theme.palette.text.disabled,
  },
  // Set to browser defaults
  '&.MuiButtonBase-root': {
    outline: '',
    WebkitTapHighlightColor: '',
    '-moz-appearance': '',
    '-webkit-appearance': '',
    '&::-moz-focus-inner': {
      borderStyle: '',
    },
  },
  '&.Mui-focusVisible': {
    outline: 'revert',
  },
}));

export const SuccessBox = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.cactusGreen.l40,
}));

export const ErrorBox = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.custom.brandRed,
}));
