import Enrollment from '@/components/molecules/cbeda-enrollment';
import Attendance from '@/components/molecules/cbeda-attendance';
import { DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST } from './constants';

const componentName:string = 'CbedaEngagement';
export interface CBEDAEngagementProps {
  isWithinEnrollmentPeriod: boolean,
  postedInTermBeforeToday: boolean,
  postedToday: boolean,
  enrollmentDaysLeft: number,
  lastActivityDate: string,
  isPastEnrollmentAndShouldShowAttendanceEngagement: boolean,
  daysLeftToPost: number,
}
function CBEDAEngagement({
  isWithinEnrollmentPeriod,
  postedInTermBeforeToday,
  postedToday,
  enrollmentDaysLeft,
  lastActivityDate,
  isPastEnrollmentAndShouldShowAttendanceEngagement,
  daysLeftToPost,
}: CBEDAEngagementProps) {
  if ((isWithinEnrollmentPeriod && !postedInTermBeforeToday)
    || (isWithinEnrollmentPeriod && postedToday)) {
    return (
      <Enrollment
        parentComponentName={componentName}
        postedToday={postedToday}
        daysLeft={postedToday ? DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST : enrollmentDaysLeft}
      />
    );
  }
  if (isPastEnrollmentAndShouldShowAttendanceEngagement) {
    if (daysLeftToPost > 0) {
      return (
        <Attendance
          parentComponentName={componentName}
          lastPost={lastActivityDate}
          daysLeft={postedToday ? DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST : daysLeftToPost}
          postedToday={postedToday}
        />
      );
    }
  }
  return null;
}
export default CBEDAEngagement;
