import LinkTile from '@/components/atoms/link-tile';
import { Grid } from '@mui/material';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import TILE_DATA from '@/components/organisms/link-tile-six-pack/constants';
import { AppIcon } from '@/constants/icons';

function LinkTileSixPack() {
  return (
    <Grid
      data-component={componentNameToMyPhxKebabFormat('LinkTileSixPack')}
      container
      rowSpacing={{ xs: 2, sm: 4 }}
      columnSpacing={1}
      columns={{ xs: 1, sm: 3 }}
      width="sm"
    >
      {TILE_DATA.map((tile) => (
        <Grid key={tile.title} item xs={1}>
          <LinkTile
            icon={tile.icon as AppIcon}
            title={tile.title}
            descriptor={tile.descriptor}
            link={tile.link}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default LinkTileSixPack;
