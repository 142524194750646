import { Grid, Fade, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { navigate } from '@/helpers/window-functions';
import ArrowLink from '@/components/atoms/arrow-link';
import SingleTile from '@/components/molecules/single-tile';
import { componentNameToMyPhxKebabFormat, resolveExternalConfigs } from '@/helpers/string-utils';
import InfoCard from '@/components/molecules/info-card';
import config from '@/helpers/config';
import { DegreeCard, DegreeContent, DegreeHeading, DegreeTitle, BodyTextWrapper, BodyText, LinkWrapper, TopLinkWrapper, InfoContainer, InfoIcon, InfoContent, InfoTextWrapper, InfoTitleText, InfoBodyText, InfoButtonWrapper, InfoButton, SpinnerWrapper } from './AlumniSingleTile.styles';
import { AlumniSingleTileProps, DegreeTileProps, ProgramTileProps, CertificateTileProps } from './AlumniSingleTile.types';

const { uop: { baseUrl: uopBaseUrl } } = config;

// SUBCOMPONENTS
function DegreeTile(props: DegreeTileProps) {
  const {
    degreeTileData,
    loading,
    parentComponentName,
    componentName,
    primaryProgramName,
    level,
  } = props;
  const InfoCardContent = (
    <InfoContainer>
      <InfoContent>
        <InfoIcon icon="icon-grad-cap-mc" id="icon-grad-cap-mc" />
        <InfoTextWrapper>
          <InfoTitleText variant="h5" component="h3">{degreeTileData.title || "You're now an alum!"}</InfoTitleText>
          <InfoBodyText variant="body4">{degreeTileData.mainContent || 'Join our vibrant alumni community to take advantage of all the resources available to you – for life.'}</InfoBodyText>
        </InfoTextWrapper>
      </InfoContent>
      <InfoButtonWrapper>
        <InfoButton
          sx={{ marginRight: '0.6rem' }}
          href={degreeTileData.primaryBtnCB || `${uopBaseUrl}/alumni.html`}
          variant="outlined"
          color="primary"
        >
          {degreeTileData.primaryBtnTxt || 'Alumni Association'}
        </InfoButton>
        <InfoButton
          href={degreeTileData.secondaryBtnCB || `${uopBaseUrl}/alumni/benefits-discounts.html`}
          variant="contained"
          color="primary"
        >
          {degreeTileData.secondaryBtnTxt || 'Alumni Benefits'}
        </InfoButton>
      </InfoButtonWrapper>
    </InfoContainer>
  );
  return (
    <div>
      {loading ? (
        <SpinnerWrapper>
          <Fade in timeout={500} style={{ transitionDelay: '500ms' }}>
            <CircularProgress size={150} />
          </Fade>
        </SpinnerWrapper>
      ) : (
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignContent="center"
        >
          <Grid item sm={7}>
            <InfoCard
              parentComponentName={`${parentComponentName}_${componentName}`}
              heading={degreeTileData.heading || 'Alumni Resources'}
              content={InfoCardContent}
              alumniTile
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <DegreeCard
              data-component={componentNameToMyPhxKebabFormat(`${componentName}UpSellTile`)}
            >
              <DegreeContent>
                <DegreeHeading variant="body5" component="h2">
                  {degreeTileData.headingRight || "We've got you covered"}
                </DegreeHeading>
                <DegreeTitle variant="h6" component="p">
                  {degreeTileData.titleRight || 'Your educational path is a lifelong journey'}
                </DegreeTitle>
                <BodyTextWrapper>
                  <BodyText variant="body4">
                    {resolveExternalConfigs(
                      degreeTileData.mainContentRight ? degreeTileData.mainContentRight : '',
                      [primaryProgramName],
                      `Wherever you are along your path, we have programs, certificates and courses
                    to elevate your skillset and your resume. Learn how to take
                    your ${primaryProgramName} to the next level.`,
                    )}
                  </BodyText>
                </BodyTextWrapper>
              </DegreeContent>
              <LinkWrapper>
                <TopLinkWrapper>
                  <ArrowLink
                    link={resolveExternalConfigs(
                      degreeTileData.primaryBtnTxtCBRight ? degreeTileData.primaryBtnTxtCBRight : '',
                      [level.nextQualificationLevel],
                      `${uopBaseUrl}/degrees/${level.nextQualificationLevel}`,
                    )}
                    clickEvent={{
                      componentName,
                      properties: [{ nextQualificationLevel: level.nextQualificationLevel }],
                    }}
                  >
                    {degreeTileData.primaryBtnTxtRight || 'View programs'}
                  </ArrowLink>
                </TopLinkWrapper>
                <ArrowLink
                  link={`${uopBaseUrl}/programs/continuing-education/individual-courses.html`}
                  clickEvent={{
                    componentName,
                    properties: [{ nextQualificationLevel: 'single courses' }],
                  }}
                >
                  {degreeTileData.secondaryBtnTxtRight || 'View single courses'}
                </ArrowLink>
              </LinkWrapper>
            </DegreeCard>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

function CertificateTile(props: CertificateTileProps) {
  const {
    loading,
    parentComponentName,
    componentName,
    tileType,
    certificateTileData,
    primaryProgramName,
    small,
  } = props;
  const theme = useTheme();
  return (
    <div>
      {loading ? (<SingleTile parentComponentName={`${parentComponentName}_${componentName}`} loading />) : (
        <SingleTile
          parentComponentName={`${parentComponentName}_${componentName}`}
          data-component={componentNameToMyPhxKebabFormat(`${componentName}Certificate`)}
          tileType={tileType}
          icon="icon-grad-cap-mc"
          iconColor={theme.palette.custom.skyBound}
          title={certificateTileData?.mainContent?.value || 'Congratulations! You have officially graduated from your certificate program.'}
          mainContent={certificateTileData?.subContent?.value || 'Learning never stops. Are you ready for another program?'}
          label={primaryProgramName}
          primaryButtonText={certificateTileData?.primaryBtnTxt?.value || 'Apply Now'}
          primaryButtonCallback={() => navigate(
            (certificateTileData?.primaryBtnCB?.value || `${uopBaseUrl}/application/admissions.html`),
          )}
          secondaryButtonText={certificateTileData?.secondaryBtnTxt?.value || 'Find programs'}
          secondaryButtonCallback={() => navigate(
            certificateTileData?.secondaryBtnCB?.value || uopBaseUrl,
          )}
          small={small}
        />
      )}
    </div>
  );
}

function ProgramTile(props: ProgramTileProps) {
  const {
    loading,
    parentComponentName,
    componentName,
    programTileData,
    courseTitle,
    tileType,
    small,
  } = props;
  const theme = useTheme();
  return (
    <div>
      {loading ? (<SingleTile parentComponentName={`${parentComponentName}_${componentName}`} loading />) : (
        <SingleTile
          parentComponentName={`${parentComponentName}_${componentName}`}
          data-component={componentNameToMyPhxKebabFormat(`${componentName}Program`)}
          tileType={tileType}
          icon="icon-thumbs-up-mc"
          iconColor={theme.palette.custom.skyBound}
          title={programTileData?.mainContent?.value || 'Congratulations! You have completed your course.'}
          mainContent={programTileData?.subContent?.value || 'Are you looking for another course or ready to start a program?'}
          label={courseTitle}
          primaryButtonText={programTileData?.primaryBtnTxt?.value || 'Find courses'}
          primaryButtonCallback={() => navigate(
            programTileData?.primaryBtnCB?.value
          || `${uopBaseUrl}/programs/continuing-education/individual-courses.html`,
          )}
          small={small}
        />
      )}
    </div>
  );
}

// MAIN COMPONENT
function AlumniSingleTile(props: AlumniSingleTileProps) {
  const {
    loading = false,
    small = false,
    studentProgramType = '',
    qualificationLevel = '',
    primaryProgramName = '',
    courseTitle = '',
    degreeTileData = {},
    certificateTileData = {},
    programTileData = {},
    parentComponentName = '',
  } = props;

  const QualificationLevel = qualificationLevel.toUpperCase();
  const StudentProgramType = studentProgramType.toUpperCase();
  const componentName = 'AlumniSingleTile';
  const tileType = 'alumni';

  const qMapping = [
    {
      id: 'A',
      value: 'associates',
    },
    {
      id: 'UG',
      value: 'undergraduate',
    },
    {
      id: 'GR',
      value: 'graduate',
    },
    {
      id: 'DO',
      value: 'doctorate',
    },
  ];
  const level = (() => {
    let nextQualificationLevel = '';
    let currentLevel = '';
    if (['A', 'UG', 'GR', 'DO'].includes(QualificationLevel)) {
      const selected = qMapping.find((a) => a.id === QualificationLevel);
      currentLevel = selected.value;
      const currentLevelIndex = qMapping.indexOf(selected);
      if (currentLevelIndex + 1 < qMapping.length) {
        nextQualificationLevel = qMapping[currentLevelIndex + 1].value;
      }
    }
    return { nextQualificationLevel, currentLevel };
  })();

  return (
    <>
      {StudentProgramType === 'DEGREE' && (
        <DegreeTile
          degreeTileData={degreeTileData}
          loading={loading}
          parentComponentName={parentComponentName}
          componentName={componentName}
          primaryProgramName={primaryProgramName}
          level={level}
        />
      )}
      {StudentProgramType === 'CERTIFICATE' && (
        <CertificateTile
          loading={loading}
          parentComponentName={parentComponentName}
          componentName={componentName}
          tileType={tileType}
          certificateTileData={certificateTileData}
          primaryProgramName={primaryProgramName}
          small={small}
        />
      )}
      {StudentProgramType === 'PROGRAM' && (
      <ProgramTile
        loading={loading}
        parentComponentName={parentComponentName}
        componentName={componentName}
        programTileData={programTileData}
        courseTitle={courseTitle}
        tileType={tileType}
        small={small}
      />
      )}
    </>
  );
}

export default AlumniSingleTile;
