import ArrowLink from '@/components/atoms/arrow-link';
import Icon from '@/components/atoms/icon';
import ToggleTooltip from '@/components/atoms/tooltip';
import theme from '@/helpers/theme';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { useMediaQuery, TableContainer, Paper, TableRow, TableBody, Typography, TableHead, TableCell } from '@mui/material';
import {
  Root,
  StyledCourse,
  StyledCourseCode,
  StyledCourseDescription,
  CourseTitle,
  CourseLink,
  StyledTableCell,
  GradeContainer,
  DueDate,
  HiddenSpan,
  StyledTable,
  StyledGrade,
} from '@/components/molecules/previous-course/PreviousCourse.styles';
import { visuallyHidden } from '@mui/utils';
import { getGradeDisplayText } from '@/helpers/special-grades';

export type PreviousCourseListProps = {
  outComeDeadDate?: string,
  membershipId?: string,
  courseCode?: string,
  courseTitle?: string,
  toolTip?: string,
  specialGrades?: boolean,
  grade?: string,
  linkURL?: string
};

export type PreviousCourseProps = {
  previousCourseList: PreviousCourseListProps[],
};

export default function PreviousCourse({
  previousCourseList,
}: PreviousCourseProps) {
  const componentName = 'PreviousCourse';
  const isMobile = useMediaQuery(
    theme.breakpoints.only('xs'),
  );

  return (
    <Root data-component={componentNameToMyPhxKebabFormat(componentName)}>
      <TableContainer component={Paper}>
        <StyledTable role="table">
          <caption>{previousCourseList?.length > 1 ? 'PREVIOUS COURSES' : 'PREVIOUS COURSE'}</caption>
          <TableHead role="rowgroup" sx={visuallyHidden}>
            <TableRow role="row">
              <TableCell role="columnheader">Course</TableCell>
              <TableCell role="columnheader">Grade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody role="rowgroup">
            {previousCourseList?.map((course) => (
              <TableRow role="row" key={course.courseCode}>
                <StyledCourse role="cell">
                  <StyledCourseDescription>
                    <StyledCourseCode href={course.linkURL}>
                      {course.courseCode}
                    </StyledCourseCode>
                    <CourseTitle noWrap variant="body4" component="span">{course.courseTitle}</CourseTitle>
                  </StyledCourseDescription>
                  <CourseLink>
                    <ArrowLink
                      link={course.linkURL}
                      ariaLabel={`Go to class ${course.courseCode}`}
                      clickEvent={{
                        componentName,
                        properties: [{ courseCode: course.courseCode }],
                      }}
                    >
                      Go to class
                    </ArrowLink>
                  </CourseLink>
                </StyledCourse>
                <StyledTableCell role="cell" $hasSpecialGrades={course.specialGrades} $hasCourseGrades={!!course.grade}>
                  <GradeContainer>
                    {(course.grade) && (
                      <Icon icon="icon-star" aria-hidden="true" color={theme.palette.primary.main} id="grades" />
                    )}
                    {(!course.grade) && (
                      <Icon icon="icon-star-1" aria-hidden="true" color={theme.palette.almostBlack.l30} id="no-grades" />
                    )}
                    <Typography variant="body4">
                      Grade:
                      {isMobile && <br />}
                      <StyledGrade>
                        {getGradeDisplayText(course?.grade)}
                        {(course.grade === 'B-' || course.grade === 'C-' || course.grade === 'D-')
                          ? <HiddenSpan>minus</HiddenSpan> : ''}
                      </StyledGrade>
                      {course.specialGrades && (
                        <ToggleTooltip
                          iconWidth={1.8}
                          iconHeight={1.8}
                          iconColor={theme.palette.almostBlack.main}
                          tooltipAriaLabel={course.toolTip}
                          tooltipMessage={course.toolTip}
                          placement="top"
                        />
                      )}
                    </Typography>
                  </GradeContainer>
                  {course.specialGrades
                    && course.outComeDeadDate
                    && (
                      <DueDate variant="body4">
                        {'Due: '}
                        <span>
                          {course.outComeDeadDate}
                        </span>
                      </DueDate>
                    )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Root>
  );
}
