import React, { PropsWithChildren, useState } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import {
  ContentContainer,
  LinkText,
  DismissText,
  Arrow,
  Button,
} from './AlumniNAEGuidance.styles';

type TooltipProps = PropsWithChildren<{
  program: string,
  isOpen: boolean,
  onDismiss?: Function
  componentName?: string
}>;

function AlumniNAEGuidance({
  program, isOpen, onDismiss = () => { }, componentName = 'AlumniNAEGuidance',
}: TooltipProps) {
  const [open, setOpen] = useState(true);

  const onClick = () => {
    setOpen(false);
    onDismiss();
  };

  return (
    <Box
      data-testid="alumni_nae_guidance"
      display={open && isOpen ? 'block' : 'none'}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
    >
      <ContentContainer>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={10}>
            <Typography variant="body4" fontWeight={900}>
              Looking for your
              {' '}
              {program}
              ?
            </Typography>
          </Grid>
          <Grid item xs={2} display={{ xs: 'none', sm: 'block' }}>
            <Button
              onClick={() => onClick()}
              aria-label="Dismiss help text"
              variant="text"
              size="small"
            >
              <DismissText
                variant="body4"
              >
                Dismiss
              </DismissText>
            </Button>
          </Grid>
        </Grid>
        <Typography variant="body4" pt={0.8} component="div" id="AcademicPlan">
          Visit
          {' '}
          <LinkText
            href={makeAbsoluteUrl('/academic-plan.html')}
            aria-describedby="AcademicPlan"
          >
            Academic Plan
          </LinkText>
          {' '}
          to see your previous program courses, credits, and GPA.
        </Typography>
        <Grid display={{ xs: 'block', sm: 'none' }}>
          <Button
            onClick={() => onClick()}
            aria-label="Dismiss help text"
            variant="text"
            size="small"
          >
            <DismissText
              pt={1}
              variant="body4"
            >
              Dismiss
            </DismissText>
          </Button>
        </Grid>
      </ContentContainer>
      <Arrow />
    </Box>
  );
}

export default AlumniNAEGuidance;
