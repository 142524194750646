import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowLink from '@/components/atoms/arrow-link';
import {
  Card, Header, Content, Text,
} from './ResourceTile.styles';

export type ResourceTileProps = {
  header: string,
  description: string,
  links: { [item: string]: string }[],
  size?: 'big' | 'lil'
};

function ResourceTile({
  header, description, size, links,
}: ResourceTileProps) {
  const componentName = 'ResourceTile';
  return (
    <Card>
      <Box component="div" pb={size === 'big' ? 10 : 2}>
        <Header variant="h5">
          {header}
        </Header>
        <Text variant="body4">
          {description}
        </Text>
      </Box>
      <Content>
        {links.map((link) => (
          <Box component="div" display="block" key={link?.label} pb={1}>
            <ArrowLink
              link={link?.href}
              clickEvent={{ componentName }}
            >
              <Typography variant="body4" color="primary">{link?.label}</Typography>
            </ArrowLink>
          </Box>
        ))}
      </Content>
    </Card>
  );
}

export default ResourceTile;
