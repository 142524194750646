import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { toDateString } from '@/myphoenix/utils/date-time-functions';
import { useGetCourseOutcomesQuery } from '@/store/queries/student';
import useGetMembershipsAndCourses from '@/hooks/useGetMembershipsAndCourses';
import { getPreviousCourses } from '@/myphoenix/utils/course-functions';
import { getClassroomClassUrl, getClassroomKey } from '@/myphoenix/utils/classroom-functions';
import { getTooltipVariations, hasSpecialGrades } from '@/helpers/special-grades';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';

function usePreviousCourseState() {
  const personId = getPersonIdFromSessionStorage();

  const {
    courses,
    isFetching: membershipsAndCoursesFetching,
    isError: membershipsAndCoursesError,
  } = useGetMembershipsAndCourses(personId);

  const {
    data: { primaryProgram } = {},
    isFetching: programsFetching,
    isError: programsError,
  } = useGetProgramsByIdHook(personId);

  const {
    data: primaryProgramCourseOutcomes = null,
    isError: courseOutcomesError,
    isLoading: courseOutcomesLoading,
  } = useGetCourseOutcomesQuery({
    programId: primaryProgram?.id,
  }, { skip: !primaryProgram?.id });

  const previousCourses = getPreviousCourses(courses);

  const courseOutcomeDateString = (deadDate: string) => (deadDate ? toDateString(deadDate) : '');

  const courseOutcomeDeadDate = (membershipId: string) => courseOutcomeDateString(
    primaryProgramCourseOutcomes?.[membershipId]?.deadDate,
  );

  const previousCourseList = previousCourses.length ? previousCourses.map((course) => (
    {
      outComeDeadDate: courseOutcomeDeadDate(course?.membershipId),
      membershipId: course.membershipId,
      courseCode: course.templateCode,
      courseTitle: course.title,
      toolTip: getTooltipVariations(course?.grade, courseOutcomeDeadDate(course?.membershipId)) || '',
      specialGrades: hasSpecialGrades(course.grade),
      grade: course?.grade,
      linkURL: getClassroomClassUrl(
        getClassroomKey({ isCBEDA: primaryProgram?.cbeDaProgram === 'TRUE' }),
        { sectionId: course?.id },
      ),
    }
  )) : [];

  const isError = courseOutcomesError || programsError || membershipsAndCoursesError;
  const isFetching = courseOutcomesLoading || programsFetching || membershipsAndCoursesFetching;

  const shouldShow = !isError && !isFetching && previousCourseList?.length > 0;

  return {
    previousCourseList,
    shouldShow,
  };
}

export default usePreviousCourseState;
