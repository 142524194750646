import { Typography, Box } from '@mui/material';
import StandardButton from '@/components/atoms/standard-button';
import { styled } from '@mui/material/styles';
import { withTransientProps } from '@/helpers/with-transient-props';
import StandardLink from '@/components/atoms/standard-link';

export const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.almostBlack.l30,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(0.8),
  fontSize: theme.spacing(1.4),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(2),
  },
  lineHeight: 0,
}));

export const CardBody = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(2.4),
  fontWeight: 300,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    fontWeight: 400,
  },
  fontSize: theme.spacing(1.6),
})) as typeof Typography;

export const SubHeading = styled(Typography, withTransientProps)<{ $small?: boolean }>(
  ({ theme, $small }) => ({
    fontWeight: 700,
    lineHeight: theme.spacing(2.8),
    display: 'block',
    fontSize: theme.spacing(2),
    ...($small && {
      fontSize: theme.spacing(2.4),
    }),
    paddingTop: 1,
    width: '70%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
      width: '100%',
    },
  }),
) as typeof Typography;

export const TermHeading = styled('h2')(({ theme }) => ({
  display: 'grid',
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0),
    justifyContent: 'space-between',
    display: 'flex',
  },
}));

export const ContactHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 500,
}));

export const ContactBox = styled(Box)(({ theme }) => ({
  alignItems: 'start',
  flexDirection: 'column',
  justifyContent: 'start',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 0,
  },
}));

export const Button = styled(StandardButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: theme.spacing(2.8),
  height: theme.spacing(4),
  textTransform: 'none',
  boxShadow: 'none',
  padding: theme.spacing(0, 1),
  marginBottom: theme.spacing(1.2),
  fontSize: theme.spacing(1.6),
  float: 'right',
  top: theme.spacing(0.5),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(0),
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(24),
    height: theme.spacing(5),
    bottom: 0,
    fontWeight: 900,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
  },

}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  fontSize: theme.spacing(1.4),
  fontWeight: 400,
  marginBottom: theme.spacing(5.8),
  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
  },
  color: theme.palette.text.primary,
  borderBottom: `${theme.spacing(0.2)} solid`,
  '&:hover, &:focus': {
    textDecoration: 'none',
    borderColor: theme.palette.primary.main,
  },
}));

export const ChatTextLinkButton = styled(StandardButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  verticalAlign: 'baseline',
  background: 'none',
  minWidth: '0px',
  fontWeight: '400',
  fontSize: '1.6rem',
  textTransform: 'none',
  boxShadow: 'none',
  borderBottom: `0.2rem solid ${theme.palette.text.primary}`,
  borderRadius: 0,
  padding: 0,
  lineHeight: '1.2',
  '&:hover': {
    borderBottom: `0.2rem solid ${theme.palette.primary.main}`,
    textTransform: 'none',
    boxShadow: 'none',
    background: 'none',
  },
  '&:focus': {
    borderBottom: `0.2rem solid ${theme.palette.primary.main}`,
    background: 'none',
    boxShadow: 'none',
  },
}));

export const Content = styled(Box)(() => ({
  height: '100%',
  flexDirection: 'column',
  display: 'flex',
}));

export const BodyBox = styled(Box)(() => ({
  flex: '1 1 auto',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-between',
}));
