import { styled } from '@mui/material/styles';
import { Box, Card, Typography, CardContent, CircularProgress } from '@mui/material';
import { withTransientProps } from '@/helpers/with-transient-props';
import StandardLink from '@/components/atoms/standard-link';

export const StyledCard = styled(Card, withTransientProps)<{ $dangerzone?: boolean }>(
  ({ theme, $dangerzone = false }) => ({
    width: '100%',
    padding: theme.spacing(2.0, 2.0, 2.0, 2.0),
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
    border: $dangerzone ? `${theme.spacing(0.2)} solid ${theme.palette.primary.main}` : 'unset',
  }),
);

export const CTAWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'flex-end',
  },
  alignSelf: 'flex-start',
}));

export const ErrorCard = styled(Card)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2.0, 2.0, 2.0, 2.0),
}));

export const HeadingWrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  marginBottom: theme.spacing(3),
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1.4),
  },
  '& h2': {
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.palette.almostBlack.l20,
    marginRight: theme.spacing(0.6),
    marginTop: theme.spacing(0.2),
  },
}));

export const StyledCardContentRoot = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0,
}));

export const StyledCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  // justifyContent: 'flex-start',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
  },
}));

export const StyledTextContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  '& p': {
    margin: `${theme.spacing(1)} 0 0 0`,
  },
  '& p:first-of-type': {
    margin: '0',
  },
}));

export const StyledCountDownText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.6),
  display: 'block',
}));

export const StyledDonutBox = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(7.5),
  marginBottom: theme.spacing(2.7),
  [theme.breakpoints.up('sm')]: {
    maxWidth: theme.spacing(7.5),
    marginRight: theme.spacing(1.2),
    marginBottom: 0,
  },
  '& div div div span:first-of-type': {
    fontSize: theme.spacing(3),
    marginBottom: theme.spacing(0.2),
    fontWeight: 600,
  },
}));

export const DangerTextSmall = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const DangerTextLarge = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.2),
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const TimeRunningOut = styled(Box)(({ theme }) => ({
  '& p': {
    marginBottom: theme.spacing(1),
  },
}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecorationColor: theme.palette.almostBlack.d20,
  '&:hover, &:focus': {
    textDecorationColor: theme.palette.primary.main,
  },
}));

export const SpinnerWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
}));

export const Loading = styled(CircularProgress)(({ theme }) => ({
  minWidth: theme.spacing(15),
  minHeight: theme.spacing(15),
  alignSelf: 'center',
}));
