import { styled } from '@mui/material/styles';
import { Box, Card, Typography, CardContent } from '@mui/material';
import Icon from '@/components/atoms/icon';
import StandardButton from '@/components/atoms/standard-button';

export const DegreeCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  margin: '0 auto',
  boxShadow: `0 ${theme.spacing(0.1)} ${theme.spacing(0.2)} rgba(0,0,0,0.2)`,
  padding: theme.spacing(2, 2, 2.6, 2.3),
  '& .MuiCardActions-root': {
    padding: 0,
  },
}));

export const DegreeContent = styled(CardContent)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(2.5),
  [theme.breakpoints.up('md')]: {
    marginRight: 0,
  },
}));

export const DegreeHeading = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.4),
  display: 'block',
  color: theme.palette.text.disabled,
  fontWeight: 700,
  textTransform: 'uppercase',
  lineHeight: theme.spacing(1.6),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(1.5),
  },
})) as typeof Typography;

export const DegreeTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  lineHeight: theme.spacing(2.8),
  display: 'block',
})) as typeof Typography;

export const BodyTextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(1.2),
  maxWidth: theme.spacing(62.5),
  '& div:last-child,p:last-child': {
    marginBottom: 0,
  },
}));

export const BodyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const LinkWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4.8),
  },
}));

export const TopLinkWrapper = styled(Box)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(2),
}));

export const InfoContainer = styled(Box)(() => ({
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
}));

export const InfoIcon = styled(Icon)(({ theme }) => ({
  margin: `${theme.spacing(1)} ${theme.spacing(1.5)} 0 0`,
  width: theme.spacing(5.3),
  height: theme.spacing(3.6),
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(5.3),
    height: theme.spacing(4.1),
  },
}));

export const InfoContent = styled(Box)(({ theme }) => ({
  flex: '1 0',
  minWidth: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(0.7),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export const InfoTitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  marginTop: theme.spacing(1.2),
  marginBottom: theme.spacing(1),
})) as typeof Typography;

export const InfoTextWrapper = styled(Box)(({ theme }) => ({
  flex: '1',
  flexDirection: 'column',
  marginBottom: theme.spacing(3.9),
}));

export const InfoBodyText = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  color: theme.palette.text.primary,
}));

export const InfoButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(7),
  },
}));

export const InfoButton = styled(StandardButton)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  borderRadius: theme.spacing(2.8),
  height: theme.spacing(4),
  width: '100%',
  marginBottom: theme.spacing(1.5),
}));

export const SpinnerWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
}));
