import SingleTile from '@/components/molecules/single-tile';
import { toSpokenDateFromISO } from '@/myphoenix/utils/date-time-functions';
import { openClass } from '@/myphoenix/utils/classroom-functions';

type ScheduledTileProps = {
  parentComponentName: string,
  courseObject: { [key: string]: any },
  instructorObject?: { [key: string]: any },
  isNewStudent?: boolean,
  isCBEDA?: boolean,
  small?: boolean,
};

export default function ScheduledTile({
  parentComponentName,
  courseObject,
  instructorObject = {},
  isNewStudent = false,
  isCBEDA = false,
  small = false,
}: ScheduledTileProps) {
  const { startDate, endDate } = courseObject;

  const instructorDetails = () => {
    if (instructorObject.firstName && instructorObject.lastName) {
      return `${instructorObject.firstName} ${instructorObject.lastName}`;
    }
    return courseObject.type !== 'WS' ? 'Your instructor will be assigned before your course starts.' : '';
  };

  const newStudentContent = ` ${courseObject.title} (${courseObject.templateCode})
    will begin ${toSpokenDateFromISO(startDate)} and conclude on
    ${toSpokenDateFromISO(endDate)}.`;

  const existingStudentContent = `${courseObject.title}
    ${courseObject.templateCode && `(${courseObject.templateCode})`}
    ${startDate || endDate ? ' will' : ''}
    ${startDate && ` begin ${toSpokenDateFromISO(startDate)}`}
    ${endDate && ` ${startDate ? 'and' : ''} conclude on ${toSpokenDateFromISO(endDate)}.`} `;

  return (
    <SingleTile
      parentComponentName={`${parentComponentName}_ScheduledTile`}
      tileType="scheduled"
      icon="icon-calendar-mc"
      title={isNewStudent ? 'Welcome to your first class' : 'Upcoming course start'}
      mainContent={isNewStudent ? newStudentContent : existingStudentContent}
      instructor={instructorDetails()}
      primaryButtonText="See course overview"
      primaryButtonCallback={() => openClass(isCBEDA, courseObject?.sectionId)}
      small={small}
    />
  );
}
