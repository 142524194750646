import React, { useState } from 'react';
import { Typography, TooltipProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconType } from '@/constants/icons';
import StandardLink from '@/components/atoms/standard-link';
import {
  StyledTooltipFab,
  StyledTooltip,
  StyledTooltipContainer,
  StyledIcon,
} from './Tooltip.styles';

export interface TooltipStyle {
  tooltipMessageObject?: any;
  tooltipLink?: string;
  tooltipLinkLabel?: string;
  tooltipAriaLabel?: string;
  tooltipMessage?: string;
  placement: TooltipProps['placement'];
  customIcon?: keyof typeof IconType,
  iconColor?: string,
  iconWidth?: number,
  iconHeight?: number,
  children?: React.ReactElement<any, any>;
}

function ToggleTooltip({
  tooltipMessageObject = null,
  tooltipMessage = '',
  tooltipLinkLabel = '',
  tooltipLink = '',
  tooltipAriaLabel,
  placement = 'top',
  children,
  customIcon = 'icon-info-solid',
  iconColor = '',
  iconHeight,
  iconWidth,
  ...other
}: TooltipStyle) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const toolTipContent = (
    <StyledTooltipContainer tooltipLink={tooltipLink}>
      <Typography
        color="inherit"
        data-testid="tooltipMessage"
        component="p"
        dangerouslySetInnerHTML={{ __html: tooltipMessage }}
      />
      {tooltipLink && (
        <StandardLink
          data-testid="tooltipLink"
          href={tooltipLink}
        >
          {tooltipLinkLabel}
        </StandardLink>
      )}
    </StyledTooltipContainer>
  );

  return (
    <div>
      <StyledTooltip
        {...other}
        title={tooltipMessage ? toolTipContent : tooltipMessageObject}
        placement={placement}
        arrow
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        enterTouchDelay={0}
        enterNextDelay={4000}
        leaveTouchDelay={4000}
        data-testid="toggleTooltip"
        aria-label={`${tooltipAriaLabel} click to open`}
        disableFocusListener
      >
        <StyledTooltipFab
          $height={iconHeight && iconHeight}
          $width={iconWidth && iconWidth}
          onClick={(e) => { e.stopPropagation(); setOpen(!open); }}
        >
          <StyledIcon
            id="1"
            icon={customIcon}
            $height={iconHeight && iconHeight}
            $width={iconWidth && iconWidth}
            color={iconColor || theme.palette.almostBlack.l20}
          />
        </StyledTooltipFab>
      </StyledTooltip>
    </div>
  );
}

export default ToggleTooltip;
