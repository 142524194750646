import UseGetEngagementState from '@/components/molecules/engagement/useGetEngagement.state';
import Engagement from '@/components/molecules/engagement/engagement.ui';

export default function EngagementContainer({
  parentComponentName = '',
}: {
  parentComponentName?: string;
}) {
  const componentName = parentComponentName ? `${parentComponentName}Engagement` : 'Engagement';

  const {
    link,
    errorMessage,
    errorLinkText,
    toolTipMessage,
    postedToday,
    daysLeft,
    formattedDropDate,
    isInDangerZone,
    isFirstWeek,
    daysSinceLastPost,
    showEngagementTile,
    isError,
    isFetching,
  } = UseGetEngagementState();
  return showEngagementTile
    ? (
      <Engagement
        componentName={componentName}
        link={link}
        errorMessage={errorMessage}
        errorLinkText={errorLinkText}
        toolTipMessage={toolTipMessage}
        postedToday={postedToday}
        daysLeft={daysLeft}
        formattedDropDate={formattedDropDate}
        isInDangerZone={isInDangerZone}
        isFirstWeek={isFirstWeek}
        daysSinceLastPost={daysSinceLastPost}
        isError={isError}
        isFetching={isFetching}
      />
    )
    : null;
}
