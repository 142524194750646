import ReEnrollmentTile from '@/components/molecules/reenrollment-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { isReEnrollI } from '@/myphoenix/utils/enrollment-functions';

function ReenrollIScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => !courseInfoData?.hasIncompleteEnrollmentTasks
    && !courseInfoData?.hasCompletedEnrollmentTasks
    && isReEnrollI(courseInfoData.enrollmentApp.type)
    && !courseInfoData?.currentCourseMemberships?.length
    && !courseInfoData?.futureCourseMembershipsInNext7Days?.length,
    Component: (
      { courseInfoData: { enrollmentApp, courseInfoAuthoredData } }
      : { courseInfoData: CourseInfoData },
    ) => {
      const { enrollmentTiles } = courseInfoAuthoredData;
      const { returnToApplicationUrl, returnToApplicationText } = enrollmentTiles;
      return (
        <ReEnrollmentTile
          enrollmentAppStatus={enrollmentApp?.submissionStatus}
          programName={enrollmentApp?.program?.description}
          applicationId={enrollmentApp?.ID}
          applicationType={enrollmentApp?.type}
          returnToApplicationText={returnToApplicationText}
          returnToApplicationUrl={returnToApplicationUrl}
        />
      );
    },
  };
}

export default ReenrollIScenario;
