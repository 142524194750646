import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { useDisplayEventTrackingOnMount } from '@/hooks/useDisplayEventTracking';
import { Container } from '@mui/system';
import { Card } from './Interrupter.styles';

interface InterrupterProps {
  parentComponentName: string,
  children: React.ReactNode,
}

export default function Interrupter({
  parentComponentName,
  children,
}: InterrupterProps) {
  const componentName = parentComponentName ? `${parentComponentName}Interrupter` : 'Interrupter';
  useDisplayEventTrackingOnMount(
    componentName,
    [
      { personId: getPersonIdFromSessionStorage() },
    ],
  );
  return (
    <Card
      container
      direction="column"
      maxWidth="md"
      data-component={componentNameToMyPhxKebabFormat(componentName)}
    >
      <Container maxWidth="md">
        {children}
      </Container>
    </Card>
  );
}
