import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ClassSkillsWrapper = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  fontWeight: '300',
  '& .MuiList-root': {
    '&.MuiList-padding': {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      lineHeight: theme.spacing(1.5),
    },
    '& .MuiListItem-root': {
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '95%',
      color: theme.palette.common.black,
      padding: '0',
      lineHeight: theme.spacing(2.0),
      '&:last-of-type': {
        width: 'auto',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'inline',
        overflow: 'initial',
        textOverflow: 'initial',
        whiteSpace: 'initial',
        lineHeight: theme.spacing(1.5),
        '&:after': {
          content: '"|"',
          marginLeft: theme.spacing(0.4),
          marginRight: theme.spacing(0.4),
        },
      },
    },
  },
  '& .MuiLink-root': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    lineHeight: theme.spacing(1.5),
    '&:hover svg': {
      transform: 'translate(0.4rem, 0)',
    },
    '& svg': {
      marginLeft: theme.spacing(0.5),
      transition: 'transform 330ms ease-in-out',
    },
  },
}));

export const ToggleTooltipWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  verticalAlign: 'super',
  marginLeft: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0.3),
    verticalAlign: 'inherit',
  },
}));
