import DegreeTile from '@/components/molecules/degree-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

function GraduatedScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: ({
      enrollmentStatus,
      currentCourseMemberships,
      futureCourseMembershipsInNext7Days,
    }: CourseInfoData) => enrollmentStatus?.status === 'C'
      && currentCourseMemberships.length === 0
      && futureCourseMembershipsInNext7Days.length === 0,
    Component: (
      { courseInfoData: { parentComponentName, primaryProgram } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <DegreeTile
        parentComponentName={parentComponentName}
        primaryProgramName={primaryProgram.name}
      />
    ),
  };
}

export default GraduatedScenario;
