import CBEDAEngagement from '@/components/organisms/cbeda-engagement/CBEDAEngagement.ui';
import UseGetCBEDAEngagementState from '@/components/organisms/cbeda-engagement/CBEDAEngagement.state';

function CbedaEngagementContainer() {
  const {
    showCbeDaEngagementTile,
    isWithinEnrollmentPeriod,
    postedInTermBeforeToday,
    postedToday,
    enrollmentDaysLeft,
    lastActivityDate,
    isPastEnrollmentAndShouldShowAttendanceEngagement,
    daysLeftToPost,
  } = UseGetCBEDAEngagementState();
  return showCbeDaEngagementTile ? (
    <CBEDAEngagement
      isWithinEnrollmentPeriod={isWithinEnrollmentPeriod}
      postedToday={postedToday}
      postedInTermBeforeToday={postedInTermBeforeToday}
      // eslint-disable-next-line max-len
      isPastEnrollmentAndShouldShowAttendanceEngagement={isPastEnrollmentAndShouldShowAttendanceEngagement}
      daysLeftToPost={daysLeftToPost}
      enrollmentDaysLeft={enrollmentDaysLeft}
      lastActivityDate={lastActivityDate}
    />
  )
    : null;
}
export default CbedaEngagementContainer;
