const heading: string = 'Engagement';
const toolTipMessage: string = 'To maintain engagement, you’ll need to complete a Knowledge Check or a Competency Assessment.';
const linkLabel: string = 'Go to your classes';
const postedTodayMessage: string = 'You’ve met your term enrollment requirement.';
const postedTodaySecondLine: string = 'You’re doing great – keep it up! Completing your academic activities and participating in class is the key to success.';
const between10And14DaysToPostMessage: string = 'Be sure to post in at least one of your scheduled classes to avoid being withdrawn from all your scheduled courses for the term without receiving a grade.';
const between6And10DaysToPostMessage: string = 'Be sure to post soon in at least one of your scheduled classes to avoid being withdrawn from all your scheduled courses for the term without receiving a grade.';
const between4And5DaysToPostMessage: string = 'You’ll need to post soon in at least one of your scheduled classes to avoid being withdrawn from all your scheduled courses for the term without receiving a grade.';
const between1And4DaysToPostMessage: string = 'Time is running out!';
const between1And4DaysToPostSecondLine: string = 'Stay on track by posting in at least one of your classes to meet your term enrollment requirement and avoid being withdrawn from all your scheduled courses for the term without receiving a grade.';

export {
  heading,
  toolTipMessage,
  linkLabel,
  postedTodayMessage,
  postedTodaySecondLine,
  between10And14DaysToPostMessage,
  between6And10DaysToPostMessage,
  between4And5DaysToPostMessage,
  between1And4DaysToPostMessage,
  between1And4DaysToPostSecondLine,
};
