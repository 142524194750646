import {
  CardContent, Grid,
} from '@mui/material';

import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import {
  CardContainer, Card,
} from './VultronCard.styles';

function VultronCard({ leftChild, rightChild, id, componentName }: any) {
  return (
    <Grid
      spacing={2}
      container
      flexDirection="row"
      data-testid={id}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
    >
      <CardContainer item sm={7} xs={12}>
        <Card>
          <CardContent sx={{ height: '100%' }}>
            {leftChild}
          </CardContent>
        </Card>
      </CardContainer>
      <Grid item xs={12} sm={5}>
        <Card>
          <CardContent>
            {rightChild}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default VultronCard;
