import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BigTile = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  paddingBottom: theme.spacing(1),
}));
export const LilTile = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(1),
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(0),
  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(1),
  },
}));
