import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const Card = styled(Grid)(({ theme }) => ({
  maxWidth: '100%',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

export default Card;
