import SingleTile from '@/components/molecules/single-tile';
import configs from '@/helpers/config';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { navigate } from '@/helpers/window-functions';

type DegreeTileProps = {
  parentComponentName?: string,
  primaryProgramName: string,
};

export default function DegreeTile({
  parentComponentName,
  primaryProgramName,
}: DegreeTileProps) {
  const componentName = 'DegreeSingleTile';
  const tileType = 'degree-completion';
  const eCampusBase = configs.ecampus.baseUrl;

  return (
    <SingleTile
      parentComponentName={`${parentComponentName}_${componentName}`}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      tileType={tileType}
      icon="icon-grad-cap-mc"
      label={primaryProgramName}
      title="Congratulations! You're almost a graduate."
      mainContent={`Congrats on all your hard work! All your coursework requirements for ${primaryProgramName} are complete and your graduation status is now pending. Get registered for your commencement ceremony, then take a moment to relax and enjoy your accomplishment.`}
      primaryButtonText="Prepare for commencement"
      primaryButtonCallback={() => navigate('/commencement.html')}
      secondaryButtonText="View registration schedule"
      secondaryButtonCallback={() => navigate(`${eCampusBase}/Content/CommencementWeb/Default.aspx`)}
    />
  );
}
