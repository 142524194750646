import Emojis from '@/constants/emojis';

type EmojiProps = {
  emojiKey: string,
  label: string
};
declare type EmojiType = keyof typeof Emojis;

function Emoji(props: EmojiProps) {
  const { emojiKey, label = '' } = props;
  return emojiKey ? (
    <span
      role="img"
      aria-label={label}
      aria-hidden={label ? 'false' : 'true'}
    >
      {
        Emojis[emojiKey as EmojiType]
      }
    </span>
  ) : null;
}

export default Emoji;
