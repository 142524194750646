import ReEnrollmentTile from '@/components/molecules/reenrollment-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { isReEnrollI, reEnrollStudent } from '@/myphoenix/utils/enrollment-functions';

function ReenrollScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow:
    (courseInfoData: CourseInfoData) => !courseInfoData?.hasIncompleteEnrollmentTasks
    && !courseInfoData?.hasCompletedEnrollmentTasks
    && reEnrollStudent(
      courseInfoData?.enrollmentStatus,
      courseInfoData?.admissionStatus,
      courseInfoData?.enrollmentApp?.startTime,
    )
    && !isReEnrollI(courseInfoData?.enrollmentApp?.type)
    && !courseInfoData?.currentCourseMemberships?.length
    && !courseInfoData?.futureCourseMembershipsInNext7Days?.length,
    Component: (
      { courseInfoData: { enrollmentApp, courseInfoAuthoredData } }
      : { courseInfoData: CourseInfoData },
    ) => {
      const { enrollmentTiles } = courseInfoAuthoredData;
      const { returnToApplicationUrl, returnToApplicationText } = enrollmentTiles;

      return (
        <ReEnrollmentTile
          enrollmentAppStatus={enrollmentApp?.submissionStatus}
          programName={enrollmentApp?.program?.description}
          applicationId={enrollmentApp?.ID}
          applicationType={enrollmentApp?.type}
          returnToApplicationText={returnToApplicationText}
          returnToApplicationUrl={returnToApplicationUrl}
        />
      );
    },

  };
}

export default ReenrollScenario;
