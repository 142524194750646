import { withTransientProps } from '@/helpers/with-transient-props';
import { Card, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@/components/atoms/icon';
import StandardLink from '@/components/atoms/standard-link';

export const StyledCard = styled(
  Card,
  withTransientProps,
)<{
  $loading: boolean;
}>(({ theme, $loading = false }) => ({
  padding: theme.spacing(1.2, 1.4),
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: $loading ? 'center' : 'space-between',
  alignItems: $loading ? 'center' : 'flex-start',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
  },
}));

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  maxWidth: theme.spacing(2.4),
  maxHeight: theme.spacing(2.4),
}));

export const StyledDivContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  maxWidth: theme.spacing(53),
  marginBottom: theme.spacing(0.5),
  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
    alignItems: 'flex-start',
  },
}));

export const StyledTypographyMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.almostBlack.l20,
  fontSize: theme.spacing(1.4),
  lineHeight: theme.spacing(1.6),
  marginLeft: theme.spacing(0.6),
  [theme.breakpoints.between('xs', 'sm')]: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.spacing(1.6),
    lineHeight: theme.spacing(2.4),
    marginLeft: theme.spacing(1.0),
  },
}));

export const TypographyErrorText = styled(Typography)(({ theme }) => ({
  maxWidth: theme.spacing(24.5),
  marginLeft: theme.spacing(3.0),
  marginBottom: theme.spacing(0.5),
  fontSize: theme.spacing(1.4),
  lineHeight: theme.spacing(2.4),
}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  color: theme.palette.primary.main,
  lineHeight: theme.spacing(2.4),
  '&:hover, &:focus': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  '&:hover svg': {
    transform: 'translate(0.4rem, 0)',
  },
  '& svg': {
    transition: 'transform 0.2s linear',
  },
  '&:focus': {
    outline: `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
    outlineOffset: theme.spacing(0.3),
  },
  marginLeft: theme.spacing(3.0),
  [theme.breakpoints.up('md')]: {
    marginLeft: 0,
  },
  alignSelf: 'flex-start',
}));
export const StyledIconEdit = styled(Icon)(({ theme }) => ({
  minWidth: theme.spacing(2.4),
}));

export const StyledIconLongArrow = styled(Icon)(({ theme }) => ({
  marginLeft: theme.spacing(0.7),
  marginRight: theme.spacing(0),
  overflow: 'visible',
  width: theme.spacing(4.2),
  height: theme.spacing(1.1),
  fill: theme.palette.primary.main,
}));

export const StyledErrorButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: theme.palette.common.black,
  fontSize: theme.spacing(1.4),
  lineHeight: theme.spacing(2.4),
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  marginLeft: theme.spacing(3.0),
  [theme.breakpoints.up('md')]: {
    marginLeft: 0,
  },
  alignSelf: 'flex-start',
  padding: 0,
}));

export const IconRefresh = styled(Icon)(({ theme, color }) => ({
  marginTop: theme.spacing(-0.3),
  marginRight: theme.spacing(0.7),
  fill: color,
}));
