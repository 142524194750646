import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AppIcon } from '@/constants/icons';
import { StyledCard, StyledLink, StyledIcon } from './LinkTile.styles';

type LinkTileProps = {
  icon?: AppIcon;
  title: string;
  descriptor?: string;
  link: string;
};

function LinkTile({ icon = 'empty', title = '', descriptor = '', link }: LinkTileProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledCard>
      <StyledLink href={link} data-testid="card-link">
        <StyledIcon id={icon} icon={icon} />
        <Typography variant="body4" component="h3" fontWeight={700}>{title}</Typography>
        {!isMobile && <Typography variant="body5" color={theme.palette.almostBlack.l30}>{descriptor}</Typography>}
      </StyledLink>
    </StyledCard>
  );
}

export default LinkTile;
