// Time based personalizations
enum Emojis {
  SUN_BEHIND_CLOUD = '⛅',
  SUNNY = '☀️',
  CRESENT_MOON = '🌙',
  GRINNING_FACE = '😁',
  PERSON_RAISING_HAND = '🙋',
  BIRTHDAY_CAKE = '🎂',

  // Countdown personalizations
  MUSCLE = '💪',
  THUMBS_UP = '👍',
  CHART_WITH_UPWARD_TREND = '📈',
  ATHETIC_SHOE = '👟',
  GOLD_STAR = '🌟',
  GOLD_MEDAL = '🏅',
  CLAPPING_HANDS = '👏',
  MORTAR_BOARD = '🎓',
  AMERICAN_FLAG = '🇺🇸',
}

export default Emojis;
