import UpcomingTile from '@/components/molecules/upcoming-tile';
import ScheduledTile from '@/components/molecules/scheduled-tile';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import {
  getScheduledCourseMemberships,
  getScheduledCourses,
  sortByMembershipStartDate,
  sortByStartDate,
} from '@/myphoenix/utils/course-functions';
import { diffFromToday } from '@/myphoenix/utils/date-time-functions';
import type { CourseInfoData, Instructor } from '@/types/courseInfoData';
import type { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { useState } from 'react';

function WithdrawnWithUpcomingCoursesScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => {
      if (courseInfoData?.futureCourseMembershipsInNext7Days.length > 0) {
        const firstScheduledCourseMembership = getScheduledCourseMemberships(
          sortByMembershipStartDate(courseInfoData.futureCourseMembershipsInNext7Days),
        )?.[0];
        const daysForNextScheduled = diffFromToday(firstScheduledCourseMembership?.startDate);
        return courseInfoData?.enrollmentStatus?.status?.toUpperCase() === 'W'
          && firstScheduledCourseMembership
          && daysForNextScheduled < 181;
      }
      return false;
    },
    Component: (
      {
        courseInfoData: {
          futureCoursesInNext7Days,
          pastCourses,
          instructorsBySectionId,
          primaryProgram: { isCBEDA = false },
        },
      }: { courseInfoData: CourseInfoData },
    ) => {
      const [selectedIndex, setSelectedIndex] = useState(0);

      const scheduledFutureCourses = getScheduledCourses(sortByStartDate(futureCoursesInNext7Days));
      const firstScheduledCourse = scheduledFutureCourses?.[0];

      const scheduledCoursesWithSameStartDate = scheduledFutureCourses.filter(
        (course) => course.startDate === firstScheduledCourse.startDate,
      );
      const sectionIdsForScheduledCourses = scheduledFutureCourses.flatMap(
        (course) => course?.sectionId,
      );

      const instructorsForCourses = sectionIdsForScheduledCourses
        .reduce<{ [key: string]: Instructor }>(
        (acc, sectionId) => (
          instructorsBySectionId[sectionId]
            ? { ...acc, [sectionId]: instructorsBySectionId[sectionId] } : acc
        ),
        {},
      );

      const pastCoursesStartDates = pastCourses.map((pastCourse) => diffFromToday(pastCourse.startDate, 'days'));
      const isNewStudent = pastCoursesStartDates?.length
        ? Math.max(...pastCoursesStartDates) <= -455
        : true;
      if (scheduledCoursesWithSameStartDate?.length > 1) {
        return (
          <UpcomingTile
            upcomingClasses={scheduledCoursesWithSameStartDate}
            instructors={instructorsForCourses}
            link={makeAbsoluteUrl('/academic-plan.html')}
            isCBEDA={isCBEDA}
            large
            selectedCourseIndex={selectedIndex}
            setSelectedCourseIndex={setSelectedIndex}
          />
        );
      }

      return firstScheduledCourse ? (
        <ScheduledTile
          courseObject={firstScheduledCourse}
          instructorObject={instructorsForCourses?.[firstScheduledCourse?.sectionId]}
          isCBEDA={isCBEDA}
          isNewStudent={isNewStudent}
          parentComponentName="course-info"
        />
      ) : null;
    },
  };
}

export default WithdrawnWithUpcomingCoursesScenario;
