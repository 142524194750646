import { Box, Typography, styled } from '@mui/material';

export const MarginWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.spacing(1.6),
  lineHeight: theme.spacing(2),
  marginBottom: theme.spacing(1.5),
}));

export const StyledGpa = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontSize: theme.spacing(4),
  fontWeight: 700,
  lineHeight: theme.spacing(6),
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(5),
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'block',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    alignItems: 'start',
    flexWrap: 'nowrap',
  },
}));
