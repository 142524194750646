import RemoteComponentContainer from '@/micro-frontends/remote-component-container';
import SkillCard from '@/components/molecules/skill-card';
import useGetStepCardState from '@/micro-frontends/careers/step-card/useGetStepCard.state';
import LoadingComponent from '@/micro-frontends/careers/step-card/Loading';
import { CAREERS_STEP_CARD_MODULE } from '../constants';

function MFECareersStepCard() {
  const { isEnabled, hasSavedCareer, hasSavedJobTitle } = useGetStepCardState();

  return (
    <RemoteComponentContainer
      isEnabled={isEnabled}
      componentName="careerPersonalizationStepCard"
      moduleName={CAREERS_STEP_CARD_MODULE}
      moduleProps={{ hasSavedCareer, hasSavedJobTitle }}
      loadingComponent={LoadingComponent}
      fallbackComponent={<SkillCard />}
    />
  );
}

export default MFECareersStepCard;
