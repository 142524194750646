import { Grid, Theme, useMediaQuery } from '@mui/material';
import {
  FormattedDonutChartType,
} from '@/types/donut';
import DonutLegend from '@/components/molecules/donut-chart/donut-legend';
import ShareProgramProgress from '@/components/molecules/share-program-progress';
import Image from 'next/image';
import {
  DivDataInfo,
  DivInnerDataContainer,
  TypographyNumerator,
  CircleChunk,
  CircleRing,
  TypographyPercentageSeperator,
  TypographyDenominator,
  DashIcon,
  DivRoot,
  DonutGrid,
  LegendGrid,
  StyledAvatar,
} from './DonutChart.styles';

type DonutChartProps = {
  data: FormattedDonutChartType[],
  hasLegend?: boolean,
  showAvatar?: boolean,
  firstName?: string,
  lastName?: string,
  showNumericalValuesInLegend?: boolean,
  imageContentType?: string,
  encodedImageBytes?: string,
  shareProgress?: boolean,
  downloadFileName?: string;
  isCBEDA?: boolean,
  programName: string,
};
function DonutChart({
  data,
  hasLegend = true,
  showAvatar = false,
  firstName,
  lastName,
  showNumericalValuesInLegend = false,
  imageContentType = null,
  encodedImageBytes = null,
  shareProgress = false,
  downloadFileName,
  isCBEDA = false,
  programName,
}: DonutChartProps) {
  const donutWidth = 2;
  const donutRadius = 16;
  const donutDiameter = donutRadius * 2 + donutWidth;
  const dataValues = data.map((index) => (index.value));
  const dataSum = dataValues.reduce((previous, current) => previous + current, 0);
  const completionPercent = Math.round((data[0].value / dataSum) * 100);
  const valuePercentages = dataValues.map((x) => (x / dataSum) * 100);
  const avatarContent = `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}`;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const buildDonutChunks = () => {
    let currentLength = 0;
    if (data) {
      return data.map((chunk, index) => {
        currentLength += index !== 0 ? valuePercentages[index - 1] : 0;
        return (
          <CircleChunk
            color={chunk.color}
            key={`countdown-chunk-${chunk.label}`}
            cx={donutDiameter / 2}
            cy={donutDiameter / 2}
            r={donutRadius}
            strokeWidth={donutWidth}
            strokeDasharray={`${Math.max(0, valuePercentages[index] - 0.25)} ${100 - valuePercentages[index] + 0.25
            }`}
            strokeDashoffset={index !== 0 ? 100 - currentLength + (24 + 0.25) : 24 + 0.25}
            data-testid="donut-chunk"
          />
        );
      });
    }
    return null;
  };

  const renderInnerContent = () => {
    if (showAvatar) {
      if (imageContentType && encodedImageBytes) {
        return (
          <StyledAvatar>
            <Image
              src={`data:${imageContentType};base64,${encodedImageBytes}`}
              alt="Profile avatar picture"
              layout="fill"
              aria-hidden="true"
            />
          </StyledAvatar>
        );
      }
      return (
        <StyledAvatar>
          {avatarContent}
        </StyledAvatar>
      );
    }
    return (
      <DivDataInfo
        aria-hidden="false"
        role="img"
        aria-labelledby="coursesRemainingCountdown"
      >
        <DivInnerDataContainer>
          <TypographyNumerator variant="body4" id="coursesRemainingCountdown">
            {data[0].value}
          </TypographyNumerator>
          <TypographyPercentageSeperator variant="body5" id="coursesRemainingTextCountdown">
            <DashIcon icon="icon-small-line-separator" id="icon-small-line-separator" />
            {completionPercent}
            %
            <DashIcon icon="icon-small-line-separator" id="icon-small-line-separator" />
          </TypographyPercentageSeperator>
          <TypographyDenominator variant="body4">{dataSum}</TypographyDenominator>
        </DivInnerDataContainer>
      </DivDataInfo>
    );
  };

  return (
    <Grid container direction={isMobile ? 'column' : 'row'} wrap="nowrap">
      <DonutGrid item>
        <DivRoot>
          <svg viewBox={`0 0 ${donutDiameter} ${donutDiameter}`} aria-hidden="true">
            <CircleRing
              cx={donutDiameter / 2}
              cy={donutDiameter / 2}
              r={donutRadius}
              strokeWidth={donutWidth}
            />
            {buildDonutChunks()}
          </svg>
          {renderInnerContent()}
        </DivRoot>
      </DonutGrid>
      {hasLegend && (
        <LegendGrid item>
          <DonutLegend
            data={data}
            showNumericalValues={showNumericalValuesInLegend}
            shareProgress={shareProgress}
            downloadFileName={downloadFileName}
          >
            <ShareProgramProgress name={`${firstName} ${lastName}`} creditsAccrued={data[0].value} totalCredits={dataSum} program={programName} isCBEDA={isCBEDA} />
          </DonutLegend>
        </LegendGrid>
      )}
    </Grid>
  );
}

export default DonutChart;
