import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const CourseCode = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0.5),
  },
}));
export const CourseTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0.5),
  },
}));

export const CourseDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    marginTop: 0,
  },
}));
