import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: theme.spacing(-1),
  zIndex: '-2',
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none', // This will make it so the confetti doesn't block clicks on things below it
  '& canvas': {
    height: '100vh',
    maxWidth: '100vw',
    display: 'block',
    margin: '0 auto',
    width: '100vw',
  },
}));
