import Config from '@/helpers/config';

// todo integrate with our cms
const TILE_DATA = [
  {
    icon: 'icon-apple-book',
    title: 'Academic Plan',
    descriptor: 'Program & schedule info',
    link: '/academic-plan.html',
  },
  {
    icon: 'icon-money',
    title: 'Financial Plan',
    descriptor: 'Account & financial info',
    link: `${Config.ecampus.baseUrl}financialplan/`,
  },
  {
    icon: 'icon-support-mc',
    title: 'Help & Support',
    descriptor: 'Faculty & advisor support',
    link: '/university-contacts.html',
  },
  {
    icon: 'icon-library-mc',
    title: 'University Library',
    descriptor: 'Learning resources & tools',
    link: 'https://library.phoenix.edu/', // hardcoded for prod because there is no qa
  },
  {
    icon: 'icon-virtual-student-union',
    title: 'Virtual Student Union',
    descriptor: 'Student services & support',
    link: '/vsu.html',
  },
  {
    icon: 'icon-phoenix-link-careers',
    title: 'Career Services',
    descriptor: 'Career services for life\u2122',
    link: '/vsu/career-services.html',
  },
];

export default TILE_DATA;
