import { styled } from '@mui/material/styles';
import { Theme } from '@mui/system';
import StandardButton from '../standard-button';

interface StyledStandardButtonProps {
  version?: 'default' | 'red-hover';
}

// eslint-disable-next-line import/prefer-default-export
export const StyledStandardButton = styled(StandardButton, { shouldForwardProp: (props) => props !== 'version' })<StyledStandardButtonProps>(({ theme, version }: { theme?: Theme, version?: 'default' | 'red-hover'; }) => ({
  fontSize: theme.spacing(1.6),
  color: theme.palette.common.black,
  borderBottom: `${theme.spacing(0.2)} solid`,
  textDecoration: 'none',
  lineHeight: theme.spacing(2.4),
  borderRadius: 0,
  minWidth: 0,
  padding: 0,
  '&:hover, &:focus': {
    outline: 'none',
    backgroundColor: 'transparent',
    ...(version === 'default'
      ? { borderBottomColor: theme.palette.common.black }
      : { borderBottomColor: theme.palette.primary.main }),
  },
  // Set to browser defaults
  '&.MuiButtonBase-root': {
    outline: '',
    WebkitTapHighlightColor: '',
    '-moz-appearance': '',
    '-webkit-appearance': '',
    '&::-moz-focus-inner': {
      borderStyle: '',
    },
  },
  '&.Mui-focusVisible': {
    outline: 'revert',
    outlineOffset: theme.spacing(0.2),
  },
}));
