import { useTheme } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { DivRoot, ProgressCircle, CircleRing, DivDataInfo, DivInnerDataContainer } from './ProgressDonut.styles';

type ProgressDonutProps = PropsWithChildren<{
  progressConfig?: { accrued: number, total: number },
}>;

function ProgressDonut(
  props: ProgressDonutProps,
) {
  const { progressConfig, children } = props;
  const theme = useTheme();
  const donutWidth = 2;
  const donutRadius = 16;
  const donutDiameter = donutRadius * 2 + donutWidth;

  const buildDonutChunks = () => {
    let currentLength = 0;
    const circles = [];
    const chunkPercentage = (1 / progressConfig.total) * 100;
    for (let i = 0; i < progressConfig.total; i += 1) {
      currentLength += i !== 0 ? chunkPercentage : 0;
      circles.push(
        <ProgressCircle
          key={`countdown-chunk-${i}`}
          $numeratorColor={
            i < progressConfig.accrued
              ? theme.palette.donut.systemGreen
              : theme.palette.almostBlack.l70
            }
          cx={donutDiameter / 2}
          cy={donutDiameter / 2}
          r={donutRadius}
          strokeWidth={donutWidth}
          strokeDasharray={`${Math.max(0, chunkPercentage + 0.25)} ${100 - chunkPercentage - 0.25
          }`}
          strokeDashoffset={
            i !== 0 ? 100 - currentLength + (24 + 0.25) : 24 + 0.25
          }
          data-testid="donut-chunk"
        />,
      );
    }
    return circles;
  };

  const renderInnerContent = () => (
    <DivDataInfo>
      <DivInnerDataContainer>
        {children}
      </DivInnerDataContainer>
    </DivDataInfo>
  );

  return (
    <DivRoot>
      <svg viewBox={`0 0 ${donutDiameter} ${donutDiameter}`} aria-hidden="true">
        <CircleRing
          cx={donutDiameter / 2}
          cy={donutDiameter / 2}
          r={donutRadius}
          strokeWidth={donutWidth}
        />
        {buildDonutChunks()}
      </svg>
      {renderInnerContent()}
    </DivRoot>
  );
}

export default ProgressDonut;
