import { useGetPhonesByPersonIdQuery } from '@/store/queries/v2/contacts';

const useGetPrimaryOrDefaultPhoneByPersonIdHook = (personId: string, skip: boolean = false) => {
  const {
    data = [],
    isFetching,
    isError,
  } = useGetPhonesByPersonIdQuery({ personId }, { skip: skip || !personId });

  return {
    data: data?.length
      ? data?.find((phone) => phone.primary === 'true') || data[0]
      : null,
    isFetching,
    isError,
  };
};

export default useGetPrimaryOrDefaultPhoneByPersonIdHook;
