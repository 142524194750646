import { PropsWithChildren } from 'react';
import { List } from '@mui/material';
import {
  FormattedDonutChartType,
} from '@/types/donut';
import SocialModal from '@/components/organisms/social-modal';
import { StyledBox, StyledValue, StyledLabel, StyledListItem, ModalContainer } from './DonutLegend.styles';

type DonutLegendProps = PropsWithChildren<{
  data: FormattedDonutChartType[],
  showNumericalValues?: boolean,
  shareProgress?: boolean,
  downloadFileName?: string;
}>;

function DonutLegend(props: DonutLegendProps) {
  const {
    data,
    showNumericalValues = true,
    shareProgress = false,
    downloadFileName,
    children,
  } = props;
  return (
    <>
      <List>
        {data?.map((chunk) => (
          <StyledListItem key={`legend-item-${chunk.label}`}>
            <StyledBox color={chunk.color} />
            <StyledLabel variant="body5">
              {chunk.label}
            </StyledLabel>
            {showNumericalValues && (
            <StyledValue variant="body5">
              {chunk.value}
            </StyledValue>
            )}
          </StyledListItem>
        ))}
      </List>
      {shareProgress && (
        <ModalContainer>
          <SocialModal ctaText="Share progress" ctaIcon="icon-share" downloadFileName={downloadFileName}>
            {children}
          </SocialModal>
        </ModalContainer>
      )}
    </>
  );
}

export default DonutLegend;
