import { useMemo } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowLink from '@/components/atoms/arrow-link';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { useGetSkillsByProgramIdQuery } from '@/store/queries/programs';
import { toDateTime } from '@/myphoenix/utils/date-time-functions';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import constants from '@/constants/skills';
import { TrophyBox, StyledDivider, StyledIcon, StyledCard, StyledStack, StyledContent } from './SkillCard.styles';

function SkillCard(): JSX.Element {
  const componentName = 'SkillCard';
  const personId = getPersonIdFromSessionStorage();

  const {
    data: { primaryProgram } = {},
    isFetching: programsLoading,
  } = useGetProgramsByIdHook(personId);

  const { data: skillsForProgram,
    isLoading: skillsLoading } = useGetSkillsByProgramIdQuery({
    programId: primaryProgram?.code,
    version: primaryProgram?.version,
  }, { skip: !primaryProgram?.code });

  const loading = skillsLoading || programsLoading;

  const canUserViewSkills = useMemo(() => {
    if (primaryProgram && skillsForProgram) {
      const skillLaunchDate = skillsForProgram?.custom_metadata?.find(
        (metadata: { title: string }) => metadata?.title === 'Skills Launch Date',
      );
      if (!skillLaunchDate?.metadata_value) {
        return false;
      }
      const skillStartDate = toDateTime(skillLaunchDate?.metadata_value);
      const programStartDate = toDateTime(primaryProgram?.startDate);
      if (!skillStartDate.isValid || !programStartDate.isValid) return false;
      return programStartDate >= skillStartDate;
    }
    return false;
  }, [skillsForProgram, primaryProgram]);

  const { breakpoints, palette } = useTheme();
  const isMobile: boolean = useMediaQuery(breakpoints.down('sm'));
  const skillMessage: string = canUserViewSkills ? "Your program is mapped to career-relevant skills. See how many skills you've demonstrated so far!" : 'Collecting all your skills in one place helps you be prepared to search for job opportunities.';
  return (
    <StyledCard data-component={componentNameToMyPhxKebabFormat(componentName)}>
      <Typography
        component="h2"
        variant="body4"
        sx={{
          color: palette.almostBlack.l30,
          marginBottom: '1rem',
          fontWeight: '600',
        }}
      >
        SKILLS
      </Typography>
      <StyledContent>
        <StyledStack>
          <Typography variant="body4" sx={{ lineHeight: '1.5' }}>{loading ? '' : skillMessage}</Typography>
          <ArrowLink
            link={constants.skillsLinkUrl}
            clickEvent={{ componentName, properties: [{ canUserViewSkills }] }}
          >
            <Typography variant="body4">View Skills Profile</Typography>
          </ArrowLink>
        </StyledStack>
        <StyledDivider orientation={isMobile ? 'horizontal' : 'vertical'} />
        <TrophyBox>
          <StyledIcon id="icon-misc-trophy" icon="icon-misc-trophy" />
        </TrophyBox>
      </StyledContent>
    </StyledCard>
  );
}

export default SkillCard;
