// @ts-ignore
import Confetti from 'react-confetti';
import { useTheme } from '@mui/material/styles';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { Wrapper } from './Confetti.styles';

type ConfettiProps = {
  colors?: string[];
  ariaLabel?: string;
  extraClass?: string;
};

export const componentName = 'Confetti';

function ConfettiComponent({ colors = null, ariaLabel = '', extraClass = '' }: ConfettiProps) {
  const theme = useTheme();

  const defaultColors = [
    theme.palette.confetti.main,
    theme.palette.confetti.muteBlue,
    theme.palette.confetti.forestGreen,
    theme.palette.confetti.sunriseOrange,
  ];
  return (
    <Wrapper
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      className={extraClass}
      role="alert"
      aria-label={ariaLabel}
      data-testid="confetti-canvas"
    >
      <Confetti colors={colors ?? defaultColors} />
      {/* <ConfettiCanvas
        colors={colors}
        duration={0.065}
        paperCount={95}
        ribbonCount={11}
        ribbonParticleCount={30}
        ribbonParticleDist={8.0}
        ribbonParticleThick={8.0}
        ribbonParticleAngle={45}
        ribbonParticleMass={1}
        ribbonParticleDrag={0.05}
      /> */}
    </Wrapper>
  );
}

export default ConfettiComponent;
