import { Fade, Typography } from '@mui/material';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import Donut from '@/components/molecules/donut-countdown';
import ErrorMessage from '@/components/atoms/error-message';
import ToggleTooltip from '@/components/atoms/tooltip';
import ArrowLink from '@/components/atoms/arrow-link';
import AttendanceMessage, { tooltipMessage } from '@/components/molecules/attendance-tile/AttendanceTileMessage';
import { useDisplayEventTrackingOnMount } from '@/hooks/useDisplayEventTracking';
import {
  HeadingWrapper,
  StyledCard,
  StyledCardContent,
  StyledCardContentRoot,
  StyledCountDownText,
  StyledDonutBox,
  StyledTextContent,
  ErrorCard,
  Loading,
  SpinnerWrapper,
  CTAWrapper,
} from './AttendanceTile.styles';

interface AttendanceTileProps {
  componentName: string,
  courseTemplateCode: string,
  totalAbsences: number,
  recentPost: 'today' | 'yesterday' | 'thisWeek' | 'none',
  isFirstWeekOfCourse: boolean,
  dayOfWeek: string,
  displayDonut: boolean,
  hideTooltip: boolean,
  isFetching: boolean,
  isError: boolean,
  classRoomLink: string,
  isInDangerZone: boolean,
  daysLeft: number
}

function AttendanceTile(
  {
    componentName,
    courseTemplateCode,
    totalAbsences,
    recentPost,
    isFirstWeekOfCourse,
    dayOfWeek,
    displayDonut,
    hideTooltip,
    isFetching,
    isError,
    classRoomLink,
    isInDangerZone,
    daysLeft,
  }: AttendanceTileProps,
) {
  useDisplayEventTrackingOnMount(
    componentName,
    [{ dayOfWeek },
      { daysLeftToPost: daysLeft },
      { isFirstWeekOfCourse },
      { message: daysLeft }],
  );

  if (isFetching) {
    return (
      <StyledCard>
        <SpinnerWrapper>
          <Fade in timeout={500} style={{ transitionDelay: '500ms' }}>
            <Loading data-testid="loading-spinner" />
          </Fade>
        </SpinnerWrapper>
      </StyledCard>
    );
  }

  if (isError) {
    return (
      <ErrorCard>
        <ErrorMessage errorComponent={componentName} />
      </ErrorCard>
    );
  }

  return (
    <StyledCard
      $dangerzone={isInDangerZone || undefined}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
    >
      <StyledCardContentRoot>
        <HeadingWrapper>
          <Typography variant="body5" component="h2">
            Attendance
          </Typography>
          {!hideTooltip && (
            <ToggleTooltip
              tooltipAriaLabel="tooltip attendance"
              tooltipMessage={tooltipMessage}
              placement="top"
              iconHeight={1.5}
              iconWidth={1.5}
            />
          )}
        </HeadingWrapper>
        <StyledCardContent>
          <StyledTextContent>
            {displayDonut && (
            <StyledDonutBox>
              <Donut
                attendanceCountDownConfig={{
                  recentPost,
                  remaining: daysLeft,
                  total: 7,
                }}
              />
            </StyledDonutBox>
            )}
            <StyledCountDownText
              variant="body4"
              id="engagement-text"
            >
              <AttendanceMessage
                absences={totalAbsences}
                templateCode={courseTemplateCode}
                recentPost={recentPost}
                daysLeftToPost={daysLeft}
                dayOfWeek={dayOfWeek}
                isFirstWeekOfCourse={isFirstWeekOfCourse}
              />
            </StyledCountDownText>
          </StyledTextContent>
          {!isFetching && (
            <CTAWrapper>
              <ArrowLink
                link={classRoomLink}
                clickEvent={{ componentName, properties: [{ daysLeft }] }}
              >
                {isInDangerZone ? 'Post now' : 'Go to class'}
              </ArrowLink>
            </CTAWrapper>
          )}
        </StyledCardContent>
      </StyledCardContentRoot>
    </StyledCard>
  );
}

export default AttendanceTile;
