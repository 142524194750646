import ErrorSingleTile from '@/components/molecules/error-single-tile';
import { getClassUrl, goToClassInfoLink } from '@/myphoenix/utils/classroom-functions';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { useRouter } from 'next/router';

function ErrorScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => Object.keys(courseInfoData.error).some(
      (key) => courseInfoData.error[key as keyof CourseInfoData],
    ),
    Component: (
      { courseInfoData: { parentComponentName, primaryProgram, error } }
      : { courseInfoData: CourseInfoData },
    ) => {
      const router = useRouter();
      return (
        <ErrorSingleTile
          parentComponentName={parentComponentName}
          errorTitle="Oh dear!"
          errorText="We're sorry for the inconvenience, there seems to be a problem. Let's give it another try when you are ready!"
          errorComponent="CourseInfo"
          errorButtonText="Try again"
          handlePrimaryButtonClick={() => { router.reload(); }}
          errorSecondaryButtonText="Access classroom"
          errorSecondaryButtonLink={error.primaryProgram
            ? goToClassInfoLink : getClassUrl(primaryProgram.isCBEDA)}
        />
      );
    },
  };
}

export default ErrorScenario;
