// TODO: Refactor
export const getBrowser = () => {
  const ua = navigator.userAgent; let tem; let
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem !== null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem !== null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

export const getOS = (osVal) => {
  if (osVal) {
    if (osVal.includes('Win')) return 'Windows';
    if (osVal.includes('Mac')) return 'Mac';
    if (osVal.includes('Linux')) return 'Linux';
  }
  return '';
};

export const scrubPhoneNumber = (number) => {
  const match = (`${number}`)
    .replace(/\D/g, '')
    .match(/.{1,10}$/);
  return match ? match[0] : '';
};
