import { Typography } from '@mui/material';
import { useDisplayEventTrackingOnMount } from '@/hooks/useDisplayEventTracking';
import { getBrightspaceClassUrl } from '@/myphoenix/utils/brightspace-functions';
import ToggleTooltip from '@/components/atoms/tooltip';
import {
  componentNameToMyPhxKebabFormat,
  mapVariableToValue,
} from '@/helpers/string-utils';
import { createEventObject } from '@/myphoenix/utils/event-functions';
import ArrowLink from '@/components/atoms/arrow-link';
import Donut from '@/components/molecules/donut-countdown';
import {
  StyledCard,
  StyledHeading,
  HeadingWrapper,
  StyledCardContentRoot,
  StyledCardContent,
  StyledTextContent,
  StyledCountDownText,
  StyledDonutBox,
  StyledWarningTitle,
  DangerTextLarge,
  StyledLink,
  CTAWrapper,
} from './CBEDAEnrollment.styles';
import {
  heading,
  toolTipMessage,
  linkLabel,
  postedTodayMessage,
  postedTodaySecondLine,
  between10And14DaysToPostMessage,
  between6And10DaysToPostMessage,
  between4And5DaysToPostMessage,
  between1And4DaysToPostMessage,
  between1And4DaysToPostSecondLine,
} from './message/constants';

type CBEDAEnrollmentProps = {
  parentComponentName: string,
  postedToday: boolean,
  daysLeft: number,
};

function AttendanceMessage({
  days,
  postedToday,
} : { days: number, postedToday: boolean }): JSX.Element {
  if (days > 1 && days <= 3 && !postedToday) {
    return (
      <>
        <p>
          <StyledWarningTitle>
            {mapVariableToValue(
              'Time is running out!',
              between1And4DaysToPostMessage || '',
            )}
          </StyledWarningTitle>
        </p>
        <p>
          {mapVariableToValue(
            'Stay on track by posting in at least one of your classes to meet your term enrollment requirement and avoid being withdrawn from all your scheduled courses for the term without receiving a grade.',
            between1And4DaysToPostSecondLine || '',
          )}
        </p>
      </>
    );
  } if (days >= 4 && days <= 5 && !postedToday) {
    return (
      <p>
        {mapVariableToValue(
          'You’ll need to post soon in at least one of your scheduled classes to avoid being withdrawn from all your scheduled courses for the term without receiving a grade.',
          between4And5DaysToPostMessage || '',
        )}
      </p>
    );
  } if (days >= 6 && days <= 10 && !postedToday) {
    return (
      <p>
        {mapVariableToValue(
          'Be sure to post soon in at least one of your scheduled classes to avoid being withdrawn from all your scheduled courses for the term without receiving a grade.',
          between6And10DaysToPostMessage || '',
        )}
      </p>
    );
  } if (days > 10 && days <= 14 && !postedToday) {
    return (
      <p>
        {mapVariableToValue(
          'Be sure to post in at least one of your scheduled classes to avoid being withdrawn from all your scheduled courses for the term without receiving a grade.',
          between10And14DaysToPostMessage || '',
        )}
      </p>
    );
  } if (postedToday) {
    return (
      <>
        <p>
          <b>
            {mapVariableToValue(
              'You’ve met your term enrollment requirement.',
              postedTodayMessage || '',
            )}
          </b>
        </p>
        <p>
          {mapVariableToValue(
            'You’re doing great – keep it up! Completing your academic activities and participating in class is the key to success.',
            postedTodaySecondLine || '',
          )}
        </p>
      </>
    );
  }
  return null;
}

function DangerMessage({
  componentName,
  daysLeft,
  link,
}:{
  componentName: string,
  daysLeft: number,
  link: string
}) {
  return (
    <>
      <DangerTextLarge>
        Today is your last day to post.
      </DangerTextLarge>
      <Typography
        variant="body4"
        component="p"
      >
        Stay on track by posting an
        {' '}
        <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer" aria-label="A R A">ARA</StyledLink>
        {' '}
        to meet your weekly attendance requirement. You have until Midnight,
        {' '}
        <StyledLink href="/kb/article/Arizona-Clock-in-Blackboard" rel="noreferrer">Arizona time</StyledLink>
        {' '}
        tonight to post an
        {' '}
        <span aria-label="A R A">ARA</span>
        .
      </Typography>
      <Typography
        variant="body4"
        component="b"
      >
        You&apos;ll be automatically dropped from your course if you miss this week.
      </Typography>
      <CTAWrapper>
        <ArrowLink
          link={link}
          clickEvent={{ componentName, properties: [{ daysLeft }] }}
        >
          Post now
        </ArrowLink>
      </CTAWrapper>
    </>
  );
}

function CBEDAEnrollment({
  parentComponentName = '',
  postedToday = false,
  daysLeft = 14,
}: CBEDAEnrollmentProps) {
  const componentName = `${parentComponentName}EnrollmentTile`;
  const link = getBrightspaceClassUrl();
  let linkLabelValue = linkLabel;

  useDisplayEventTrackingOnMount(
    componentName,
    [{ daysLeft }, { postedToday }],
  );

  const isInDangerZone = (daysLeft === 1 && !postedToday);

  if (daysLeft > 1 && daysLeft <= 3 && !postedToday) {
    linkLabelValue = 'Post now';
  }

  return (
    <StyledCard
      data-testid="cbeda_engagement-enrollment"
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      $dangerzone={isInDangerZone || undefined}
    >
      <StyledCardContentRoot
        data-raise-event-on-display={createEventObject(
          componentName,
          [{ daysLeft }, { postedToday }],
        )}
      >
        <HeadingWrapper>
          <StyledHeading variant="body5">
            {heading}
          </StyledHeading>
          <ToggleTooltip
            tooltipMessage={toolTipMessage}
            tooltipAriaLabel={`tooltip ${heading}`}
            placement="top"
          />
        </HeadingWrapper>
        {isInDangerZone
          ? (
            <DangerMessage
              componentName={componentName}
              daysLeft={daysLeft}
              link={link}
            />
          ) : (
            <StyledCardContent>
              <StyledDonutBox>
                <Donut
                  cbeDaEngagementConfig={{
                    remaining: daysLeft,
                    postedToday,
                  }}
                />
              </StyledDonutBox>
              <StyledTextContent>
                <StyledCountDownText
                  variant="body4"
                  id="engagement-text"
                >
                  <AttendanceMessage days={daysLeft} postedToday={postedToday} />
                </StyledCountDownText>
                <ArrowLink
                  ariaLabel={linkLabelValue}
                  link={link}
                  clickEvent={{ componentName, properties: [{ daysLeft }] }}
                >
                  {linkLabelValue}
                </ArrowLink>
              </StyledTextContent>
            </StyledCardContent>
          )}
      </StyledCardContentRoot>
    </StyledCard>
  );
}

export default CBEDAEnrollment;
