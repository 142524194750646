import React, { useEffect } from 'react';
import { trackComponentError, reloadOnError } from '@/myphoenix/utils/error-functions';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import {
  StyledButton, Border, Text, StyledIcon,
} from './ErrorMessage.styles';

/**
 * Error React Component
 */

type ErrorMessageProps = {
  errorMessage?: string,
  errorLinkText?: string,
  errorComponent?: string,
  isFontOverride?: boolean,
};

function ErrorMessage({
  errorMessage = 'We\'re sorry! We experienced an error retrieving this data. Please refresh your browser.',
  errorLinkText = 'Reload',
  errorComponent = 'N/A',
  isFontOverride,
}: ErrorMessageProps) {
  useEffect(() => {
    trackComponentError(errorComponent, errorMessage);
  }, [errorComponent, errorMessage]);

  return (
    <Border data-component={componentNameToMyPhxKebabFormat('ErrorMessage')}>
      <Text $isFontOverride={isFontOverride} variant="body6">{errorMessage}</Text>
      <StyledButton
        type="button"
        $isFontOverride={isFontOverride}
        onClick={() => { reloadOnError(); }}
      >
        <StyledIcon id="icon-refresh" icon="icon-refresh" />
        {errorLinkText}
      </StyledButton>
    </Border>
  );
}

export default ErrorMessage;
