import { Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StandardLink from '@/components/atoms/standard-link';

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.4),
  fontWeight: 700,
  color: theme.palette.almostBlack.l30,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(1.6),
  lineHeight: theme.spacing(1.4),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(2),
  },
})) as typeof Typography;

export const ProgramTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.8),
  marginBottom: theme.spacing(1.6),
  fontWeight: 700,
})) as typeof Typography;

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  display: 'inline-block',
  paddingBottom: theme.spacing(0.1),
  fontSize: theme.spacing(1.4),
  fontWeight: 700,
  color: theme.palette.text.primary,
  borderBottom: `${theme.spacing(0.2)} solid`,
  textDecoration: 'none',
  marginBottom: theme.spacing(0.75),
  '&:hover, &:focus': {
    borderBottomColor: theme.palette.primary.main,
  },
})) as typeof Link;

export const LoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  margin: `${theme.spacing(4)} auto`,
}));
