import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StandardLink from '@/components/atoms/standard-link';
import StandardButton from '@/components/atoms/standard-button';

export type StyledTooltipContainerProps = {
  tooltipLink: string;
};

export const ContentContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  textAlign: 'unset',
  background: theme.palette.custom.darkBlack,
  color: theme.palette.common.white,
  boxShadow: `0 0 ${theme.spacing(2)} rgb(0 0 0 / 20%)`,
  borderRadius: theme.spacing(0.3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2.4),
  position: 'relative',
}));

export const LinkText = styled(StandardLink)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: `${theme.spacing(0.2)} solid ${theme.palette.common.white}`,
  textDecoration: 'none',
  '&:hover, &:focus': {
    borderBottom: `${theme.spacing(0.2)} solid ${theme.palette.common.white}`,
    color: theme.palette.common.white,
  },
}));

export const DismissText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.common.white}`,
  cursor: 'pointer',
  lineHeight: theme.spacing(1.875),
  float: 'right',
})) as typeof Typography;

export const Arrow = styled('span')(({ theme }) => ({
  borderTop: `${theme.spacing(1)} solid ${theme.palette.custom.darkBlack}`,
  position: 'relative',
  borderRight: `${theme.spacing(1)} solid transparent`,
  borderLeft: `${theme.spacing(1)} solid transparent`,
  left: '47%',
  top: theme.spacing(0.8),
  [theme.breakpoints.up('sm')]: {
    left: '10%',
  },
}));

export const Button = styled(StandardButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    '&:hover': {
      outline: 'none',
    },
    '&:focus': {
      outlineColor: theme.palette.common.white,
    },
  },
  float: 'right',
  display: 'block',
  minWidth: theme.spacing(0),
}));
