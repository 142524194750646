import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { withTransientProps } from '@/helpers/with-transient-props';

export const DivDataInfo = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
  '& p': {
    lineHeight: '1',
    display: 'inline',
  },
}));
export const DivInnerDataContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
export const TypographyNumerator = styled(Typography, withTransientProps)<{
  $numeratorColor?: string }>(({ theme, $numeratorColor = theme.palette.secondary.main }) => ({
  color: $numeratorColor,
  fontSize: theme.spacing(3.2),
  fontWeight: '600',
}));
export const TypographyPercentageSeparator = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.custom.darkGrey,
    fontSize: theme.spacing(1.4),
    marginBottom: theme.spacing(0.3),
    '& svg': {
      width: theme.spacing(1.5),
      [theme.breakpoints.up('md')]: {
        width: 'unset',
      },
    },
  }),
);
export const TypographyCreditBreakdown = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.2),
  marginTop: theme.spacing(1.1),
  lineHeight: theme.spacing(1.1),
  textAlign: 'center',
}));
export const TypographyCBEMessaging = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  lineHeight: theme.spacing(1.4),
  maxWidth: theme.spacing(8.5),
  color: theme.palette.almostBlack.l30,
  marginTop: theme.spacing(0.3),
}));

export const SpanCreditsNewLine = styled('span')(() => ({
  display: 'block',
  textAlign: 'center',
}));

export const DivRoot = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  '& .MuiAvatar-root': {
    position: 'absolute',
    width: '76%',
    height: '76%',
    top: '12%',
    left: '12%',
    fontSize: theme.spacing(4.4),
    '& img': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(5.4),
    },
  },
}));
export const CircleRing = styled('circle')(() => ({
  fill: 'transparent',
  stroke: '#FFF',
}));

export const CircleChunk = styled(
  'circle',
  withTransientProps,
)<{ $isDark?: boolean, fillcolor?: string }>(({ theme,
  $isDark = false,
  fillcolor = theme.palette.secondary.main }) => ({
  fill: 'transparent',
  stroke: $isDark ? theme.palette.almostBlack.l70 : fillcolor,
}));

export const CBEDAEngagementCircle = styled(
  'circle',
  withTransientProps,
)<{ $numeratorColor?: string }>(({ theme, $numeratorColor = theme.palette.secondary.main }) => ({
  fill: 'transparent',
  stroke: $numeratorColor,
}));
export const NumeratorSmall = styled(Typography)(({ theme }) => ({
  color: theme.palette.almostBlack.l20,
  fontWeight: '600',
  marginBottom: '-0.6rem',
}));
export const TypographyAttendanceSeparator = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.almostBlack.l30,
    marginBottom: theme.spacing(0.3),
    fontWeight: '400',
    '& svg': {
      width: theme.spacing(1.5),
      [theme.breakpoints.up('md')]: {
        width: 'unset',
      },
    },
  }),
);

export const EngagementCircle = styled(
  'circle',
  withTransientProps,
)<{ $remainingColor?: string }>(({ $remainingColor }) => ({
  fill: 'transparent',
  stroke: $remainingColor,
}));
