import { getIpAddressFromCookie } from '@/helpers/cookies';
import { getBrowser, getOS, scrubPhoneNumber } from '@/helpers/chat';
import configs from '@/helpers/config';

const { chatBot } = configs;

const getBrowserPlatform = () => {
  if (typeof navigator !== 'undefined') {
    return navigator.platform ?? '';
  }
  return '';
};

type GenesysChatProfile = {
  firstName: string;
  lastName: string;
  email: string;
  profileId: string;
  studentIRN: string;
  phoneNumber: string;
  serviceName?: string;
  proactiveChatCategory?: string;
};

export const startChat = ({
  firstName,
  lastName,
  email,
  profileId,
  studentIRN,
  phoneNumber,
  serviceName,
  proactiveChatCategory = '',
}: GenesysChatProfile) => {
  const browser = getBrowser();
  const ipAddress = getIpAddressFromCookie();
  const serviceNameOrDefault = typeof serviceName === 'string' && serviceName !== '' ? serviceName : chatBot.serviceName.default;
  const browserPlatform = getBrowserPlatform();
  const formData = {
    autoSubmit: true,
    service: serviceNameOrDefault,
    firstname: firstName,
    lastname: lastName,
    email,
    currentOS: browserPlatform,
    Browser: browser.name,
    BrowserVersion: browser.version,
    IPAddress: ipAddress,
    profileId,
    StudentIRN: studentIRN,
  };

  const userData = {
    service: serviceNameOrDefault,
    FirstName: firstName,
    LastName: lastName,
    EmailAddress: email,
    PhoneNumber: scrubPhoneNumber(phoneNumber),
    StudentIRN: studentIRN,
    profileId,
    SourceURL: window.location.href,
    BrowserVersion: browser.version,
    Browser: browser.name,
    OperatingSystem: getOS(browserPlatform),
    IPAddress: ipAddress,
    IdentifyCreateContact: 0,
    sessionId: '',
    proactiveChatFlag: proactiveChatCategory,
  };

  if (window?.ChatWidget) {
    // eslint-disable-next-line no-underscore-dangle
    if (window?._genesys?.widgets?.webchat) {
      // eslint-disable-next-line no-underscore-dangle
      window._genesys.widgets.webchat.userData = userData;
    }

    window.ChatWidget.command('WebChat.open', {
      userData,
      form: formData,
    });

    // Leaving here if we have to look more into handling issues on the Genesys side
    // const chatResult = window.ChatWidget.command('WebChat.open', {
    //   userData,
    //   form: formData,
    // });
    //
    // setTimeout(() => {
    //   if ((chatResult?.state() || 'resolved') !== 'resolved') {
    //     window.ChatWidget.command('WebChat.close');
    //     window.ChatWidget.command('WebChat.open', {
    //       userData,
    //       form: formData,
    //     });
    //   }
    // }, 500);
  }
};

export default startChat;
