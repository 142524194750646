import { useState } from 'react';
import type { CourseInfoData, Instructor } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import UpcomingTile from '@/components/molecules/upcoming-tile';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import {
  getMostRecentPastCourseMembership,
  getScheduledCourseMemberships,
  getScheduledCourses,
} from '@/myphoenix/utils/course-functions';
import { getCurrentDate, toDateTime } from '@/myphoenix/utils/date-time-functions';
import ScheduledTile from '@/components/molecules/scheduled-tile';

function UpcomingCourseScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: ({ futureCourseMembershipsInNext30Days }: CourseInfoData) => (
      getScheduledCourseMemberships(futureCourseMembershipsInNext30Days).length >= 1
    ),
    Component({ courseInfoData }: { courseInfoData: CourseInfoData }) {
      const {
        futureCoursesInNext30Days,
        pastCourseMemberships,
        primaryProgram: { isCBEDA },
        instructorsBySectionId,
      } = courseInfoData;

      const scheduledCourses = getScheduledCourses(futureCoursesInNext30Days);

      // Safely get storageKey if it exists
      const storageKey = 'UpcomingCourse';
      const sessionIndex = Number(sessionStorage.getItem(storageKey) || undefined);
      const validSessionIndex = !Number.isNaN(sessionIndex)
        && scheduledCourses?.[sessionIndex] !== undefined;

      const initialIndex = validSessionIndex ? sessionIndex : 0;
      const [currentCourseIndex, setCurrentCourseIndex] = useState(initialIndex);

      // Calback that checks if new index is valid and updates storageKey
      const setCurrentCourseIndexAndStorageKey = (newIndex: number) => {
        if (scheduledCourses?.[newIndex] !== undefined) {
          sessionStorage.setItem(storageKey, `${newIndex}`);
          setCurrentCourseIndex(newIndex);
        }
      };

      // Get instructors for courses
      const sectionIdsForCourses = scheduledCourses.flatMap(
        (course) => course?.sectionId,
      );

      const instructorsForCourses = sectionIdsForCourses.reduce(
        (acc: { [key: string]: Instructor }, sectionId) => (
          instructorsBySectionId[sectionId] ? {
            ...acc, [sectionId]: instructorsBySectionId[sectionId],
          } : acc
        ),
        {},
      );

      if (scheduledCourses.length > 1) {
        return (
          <UpcomingTile
            upcomingClasses={scheduledCourses}
            instructors={instructorsForCourses}
            link={makeAbsoluteUrl('/academic-plan.html')}
            linkLabelBeforeStartDate="Begins"
            linkLabelNoStartDate="See more"
            linkLabelSROnly="Redirects to Academic Plan"
            large
            isCBEDA={isCBEDA}
            selectedCourseIndex={currentCourseIndex}
            setSelectedCourseIndex={setCurrentCourseIndexAndStorageKey}
          />
        );
      }

      const mostRecentPastMembership = getMostRecentPastCourseMembership(pastCourseMemberships);
      const mostRecentPastMembershipStartDate = mostRecentPastMembership?.startDate
        ? toDateTime(mostRecentPastMembership.startDate) : null;
      const fourHundredFiftyFiveDaysAgo = getCurrentDate().minus({ days: 455 });
      const newStudent = !mostRecentPastMembership
          || !mostRecentPastMembershipStartDate
          || mostRecentPastMembershipStartDate <= fourHundredFiftyFiveDaysAgo;

      const firstScheduledCourse = scheduledCourses.length ? scheduledCourses[0] : null;
      return firstScheduledCourse ? (
        <ScheduledTile
          courseObject={firstScheduledCourse}
          instructorObject={instructorsForCourses?.[firstScheduledCourse?.membershipId]}
          isCBEDA={isCBEDA}
          isNewStudent={newStudent}
          parentComponentName="course-info"
        />
      ) : null;
    },
  };
}

export default UpcomingCourseScenario;
