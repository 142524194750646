import { Interval } from 'luxon';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook/index';
import { getClassroomClassUrl, getClassroomKey } from '@/myphoenix/utils/classroom-functions';
import useGetPersonsHook from '@/store/hooks/useGetPersonsHook/index';
import { sortMemberships } from '@/helpers/filter-courses';
import { FeatureVariableKeys } from '@/helpers/features';
import useGetMembershipsAndCourses from '@/hooks/useGetMembershipsAndCourses';
import { useGetAttendancesByMembershipIdQuery, useGetActivityGroupsBySectionIdQuery } from '@/store/queries/v2/attendances';
import { getCurrentDate, diffFromToday, toDateTime } from '@/myphoenix/utils/date-time-functions';
import { useFeatureVariableValue } from '@/helpers/feature-provider';

const UseGetAttendanceTileState = () => {
  const today = getCurrentDate();
  const dayOfWeek = today.toFormat('cccc');
  const personId = getPersonIdFromSessionStorage();
  const blackOutUntil: string = useFeatureVariableValue(FeatureVariableKeys.AttendanceTileBlackoutEndDate, 'N/A', false);
  const featureFlagOn = (!blackOutUntil
    || (blackOutUntil !== 'N/A' && diffFromToday(blackOutUntil) <= 0));
  const enabledCourses: string[] = useFeatureVariableValue(FeatureVariableKeys.AttendanceTileEnabledCourses, 'N/A', false).split(',');
  const {
    data: { primaryProgram = {} },
    isFetching: isProgramsHookFetching,
    isError: isProgramsHookError,
  } = useGetProgramsByIdHook(personId) || {};
  const {
    lastActivityDate,
    cbeDaProgram,
  } = primaryProgram;
  const isCBEDA = cbeDaProgram?.toLowerCase() === 'true';
  const {
    data: personsHookData,
    isFetching: isPersonsHookFetching,
    isError: isPersonsHookError,
  } = useGetPersonsHook(personId);
  const userDefinedAttributes = personsHookData?.userDefined;
  const {
    memberships,
    courses,
    isFetching: isMembershipsAndCoursesFetching,
    isError: isMembershipsAndCoursesError,
  } = useGetMembershipsAndCourses(personId, !featureFlagOn);
  // For purposes of current course attendance, will skip any dropped courses
  const notDroppedMemberships = memberships?.filter((membership) => membership?.statusSubCode !== 'DR' && membership.statusSubCode !== 'TA');
  const sortedMembershipIds = sortMemberships(notDroppedMemberships);
  let currentMembershipId = '';
  const currentCourse = sortedMembershipIds.currentMembershipIds
    .map((membershipId) => courses
      .find((course: { id: string }) => {
        if (course.id === membershipId) {
          currentMembershipId = membershipId;
          return true;
        } return false;
      }))[0];
  const currentMembership = memberships.find(
    (membership) => membership.sourceId === currentMembershipId,
  );
  const { data: attendances,
    isFetching: isAttendancesFetching,
    isError: isAttendancesError } = useGetAttendancesByMembershipIdQuery(
    { membershipId: currentMembership?.id },
    { skip: !featureFlagOn || !currentMembership?.id },
  );
  const { data: allowedAbsencesData,
    isFetching: isActivityGroupsFetching,
    isError: isActivityGroupsError } = useGetActivityGroupsBySectionIdQuery(
    { sectionId: currentMembership?.sourceId },
    { skip: !featureFlagOn || !currentMembership?.sourceId },
  );
  const courseStartDate = toDateTime(currentCourse?.startDate);
  const academicWeekTuesdayStart = today.set({ weekday: 2 });
  const academicWeekMondayEnd = academicWeekTuesdayStart.plus({ days: 6 });
  const LAD = toDateTime(lastActivityDate);
  const mostRecentPost = () => {
    if (LAD.hasSame(today, 'day')) {
      return 'today';
    }
    if (LAD.hasSame(today.plus({ days: -1 }), 'day') && (today.weekday !== 2)) {
      return 'yesterday';
    }
    if (Interval
      .fromDateTimes(academicWeekTuesdayStart, academicWeekMondayEnd.plus(1))
      .contains(LAD)) {
      return 'thisWeek';
    }
    return 'none';
  };
  const recentPost: 'today' | 'yesterday' | 'thisWeek' | 'none' = mostRecentPost();

  const isFirstWeekOfCourse = courseStartDate <= today
   && today <= courseStartDate.plus({ days: 7 });
  const calculateAbsences = () => {
    let absences = 0;
    if (attendances) {
      attendances.attendances?.forEach((item: { attendance: string }) => {
        if (item.attendance) {
          if (item.attendance === 'N') {
            absences += 1;
          }
        }
      });
    }
    return absences;
  };
  const totalAbsences = calculateAbsences();
  const displayDonut = dayOfWeek === 'Friday'
  || dayOfWeek === 'Saturday'
  || dayOfWeek === 'Sunday'
  || (dayOfWeek === 'Monday' && totalAbsences === 0)
  || recentPost !== 'none'
  || (totalAbsences === 1 && dayOfWeek !== 'Monday');
  const hideTooltip = isFirstWeekOfCourse
  && (dayOfWeek === 'Tuesday'
  || dayOfWeek === 'Wednesday'
  || dayOfWeek === 'Thursday')
  && totalAbsences === 0
  && recentPost === 'none';
  const hasAttribute = userDefinedAttributes?.includes('myphxAttendanceTileGroup');
  const hasEnabledCourse = currentCourse?.type !== 'CL'
      && currentCourse?.templateCode
      && (enabledCourses.includes(currentCourse?.templateCode)
        || (enabledCourses.length && enabledCourses[0] === '*'));
  const isInAttendance = allowedAbsencesData?.allowedAbsences
      && totalAbsences <= allowedAbsencesData?.allowedAbsences;
  const shouldShowAttendanceTile = featureFlagOn && (
    (isInAttendance && hasEnabledCourse)
      || (isInAttendance && hasAttribute));
  const courseTemplateCode = currentCourse?.templateCode;
  const daysLeftToPost = () => {
    switch (dayOfWeek) {
      case 'Monday':
        return 1;
      case 'Tuesday':
        return 7;
      case 'Wednesday':
        return 6;
      case 'Thursday':
        return 5;
      case 'Friday':
        return 4;
      case 'Saturday':
        return 3;
      case 'Sunday':
        return 2;
      default:
        return 7;
    }
  };
  const classRoomLink = getClassroomClassUrl(
    getClassroomKey(
      { isCBEDA },
    ),
    { sectionId: currentCourse?.id || '' },
  );
  const isInDangerZone = totalAbsences === 1
  && (dayOfWeek === 'Sunday'
  || dayOfWeek === 'Monday')
  && recentPost === 'none';
  const daysLeft = daysLeftToPost();
  return {
    courseTemplateCode,
    totalAbsences,
    recentPost,
    isFirstWeekOfCourse,
    dayOfWeek,
    displayDonut,
    hideTooltip,
    shouldShowAttendanceTile,
    classRoomLink,
    isInDangerZone,
    daysLeft,
    lastActivityDate,
    isFetching: isProgramsHookFetching
    || isPersonsHookFetching
    || isMembershipsAndCoursesFetching
    || isAttendancesFetching
    || isActivityGroupsFetching,
    isError: isProgramsHookError
    || isPersonsHookError
    || isMembershipsAndCoursesError
    || isAttendancesError
    || isActivityGroupsError,
  };
};
export default UseGetAttendanceTileState;
