import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import AlumniReEnrollTile from '@/components/molecules/alumni-reenroll-tile';
import { toDateTimeFromMs } from '@/myphoenix/utils/date-time-functions';

function AlumniReenrollScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => (
      !courseInfoData.currentCourseMemberships?.length
      && !courseInfoData.futureCourseMembershipsInNext7Days?.length
      && courseInfoData.enrollmentStatus?.status === 'G'
      && courseInfoData.enrollmentApp?.startTime
      && courseInfoData.enrollmentApp?.type === 'ALUMNI'
    ),
    Component: (
      { courseInfoData: {
        primaryProgram,
        enrollmentRep,
        enrollmentApp,
        hasCompletedEnrollmentTasks,
        hasIncompleteEnrollmentTasks,
        courseInfoAuthoredData,
      } }
      : { courseInfoData: CourseInfoData },
    ) => {
      const { enrollmentTiles } = courseInfoAuthoredData;
      const { postSubmitTasksUrl, postSubmitTasksText,
        returnToApplicationUrl, returnToApplicationText } = enrollmentTiles;

      return (
        <AlumniReEnrollTile
          programTitle={enrollmentApp.program?.description}
          contactFirstName={enrollmentRep.firstName}
          contactLastName={enrollmentRep.lastName}
          contactPhoneNumber={enrollmentRep.phoneNumber}
          contactEmail={enrollmentRep.email}
          applicationReviewStatus={enrollmentApp.reviewStatus}
          applicationSubmissionStatus={enrollmentApp.submissionStatus}
          applicationSubmittedAt={
                    Number.isInteger(enrollmentApp.submittedTime)
                      ? toDateTimeFromMs(enrollmentApp.submittedTime).toISO()
                      : null
                }
          hasCompletedEnrollmentTasks={hasCompletedEnrollmentTasks}
          hasIncompleteEnrollmentTasks={hasIncompleteEnrollmentTasks}
          isCBEDA={primaryProgram.isCBEDA}
          postSubmitTasksText={postSubmitTasksText}
          postSubmitTasksUrl={postSubmitTasksUrl}
          returnToApplicationText={returnToApplicationText}
          returnToApplicationUrl={returnToApplicationUrl}
        />
      );
    },

  };
}

export default AlumniReenrollScenario;
