import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StandardButton from '@/components/atoms/standard-button';

type TypographyProps = {
  component?: string;
};

export const DialogPaper = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: 0,
  display: 'flex',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  [theme.breakpoints.up('sm')]: {
    minWidth: theme.spacing(65.5),
    minHeight: theme.spacing(43.7),
    display: 'table',
  },
}));

export const TitleTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.spacing(3),
  marginBottom: theme.spacing(2),
  lineHeight: theme.spacing(4.0),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(3.2),
  },
}));

export const BodyTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.spacing(2),
  marginBottom: theme.spacing(3.8),
}));

export const CtaButton = styled(StandardButton)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  marginBottom: theme.spacing(1.3),
}));
