import React from 'react';
import { List, ListItem } from '@mui/material';
import skillsConstants from '@/constants/skills';
import ArrowLink from '@/components/atoms/arrow-link';
import ToggleTooltip from '@/components/atoms/tooltip';
import theme from '@/helpers/theme';
import { ClassSkillsWrapper, ToggleTooltipWrapper } from './ClassSkills.styles';

type ClassSkillsProps = {
  skills: string[];
  tooltipMessage: string;
};

function ClassSkills({
  skills,
  tooltipMessage,
}: ClassSkillsProps) {
  const componentName = 'ClassSkills';
  return (
    <ClassSkillsWrapper>
      <List aria-label="Class Skills">
        {skills.map((skill) => (
          <ListItem key={skill} aria-label="Class skill">
            {skill}
          </ListItem>
        ))}

        <ToggleTooltipWrapper>
          <ToggleTooltip
            tooltipLink={skillsConstants.skillsLinkUrl}
            tooltipLinkLabel={skillsConstants.skillsLinkLabel}
            tooltipAriaLabel="tooltip Learn More"
            tooltipMessage={tooltipMessage}
            placement="bottom"
            iconHeight={1.6}
            iconWidth={1.6}
            iconColor={theme.palette.almostBlack.main}
          />
        </ToggleTooltipWrapper>
      </List>
      <ArrowLink
        link={skillsConstants.skillsLinkUrl}
        clickEvent={{ componentName }}
      >
        <span>{skillsConstants.skillsLinkLabel}</span>
      </ArrowLink>
    </ClassSkillsWrapper>
  );
}

export default ClassSkills;
