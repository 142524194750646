import { baseApi2 } from '@/store/queries';
import { learningEndpoints } from '@/store/domains/resources/learning';
import type { CourseActivities } from '@/types/api/learning';

export const activitiesApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getActivitiesBySectionId: build.query<CourseActivities, { sectionId: string }>(
      { query: ({ sectionId }) => ({ url: learningEndpoints.getActivitiesBySectionId(sectionId), method: 'GET' }) },
    ),
  }),
});

export const {
  useGetActivitiesBySectionIdQuery,
} = activitiesApi;
