import { useState, useRef, useMemo } from 'react';
import {
  Box, useMediaQuery, Theme,
} from '@mui/material';
import { toDateString } from '@/myphoenix/utils/date-time-functions';
import { visuallyHidden } from '@mui/utils';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { StyledCard, StyledCardContent, LinkContainer, HeadingTypography, DateLink, ShowMoreIcon, ShowMoreButton, ShowMoreText } from './UpcomingTile.styles';
import CourseList from '../course-list';
import { UpcomingTileProps } from './UpcomingTile.types';

const getLabel = (
  { numClasses, mobile, large = false, newStudent = false, selectedCourseIndex }:
  {
    numClasses: number,
    mobile: boolean,
    large?: boolean,
    newStudent?: boolean,
    selectedCourseIndex: number,
  },
) => {
  if (numClasses <= 1) return 'UPCOMING CLASS';
  if (!large || mobile) return newStudent ? 'Welcome to your classes' : 'UPCOMING CLASSES';
  return `UPCOMING CLASS ${selectedCourseIndex + 1} OF ${numClasses}`;
};

function UpcomingTile({
  isCBEDA,
  large,
  link = '/academic-plan.html',
  linkLabelBeforeStartDate = 'Begins',
  linkLabelNoStartDate = 'See More',
  linkLabelSROnly = 'Redirects to Academic Plan',
  upcomingClasses = [],
  newStudent,
  instructors,
  selectedCourseIndex = 0,
  setSelectedCourseIndex,
}: UpcomingTileProps) {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const focusRef = useRef(null);

  const [showMore, setShowMore] = useState(false);
  const sortCourses = useMemo(() => (
    (!large || mobile) && !showMore ? upcomingClasses.slice(0, 3) : upcomingClasses
  ), [large, mobile, showMore, upcomingClasses]);

  const showMoreCourseLength = upcomingClasses.length - 3 || 0;
  const handleShowMore = () => {
    setShowMore(!showMore);
    focusRef.current.focus();
  };

  let currentCourseIndex = selectedCourseIndex;
  const coursesLimit = (!large || mobile) ? 3 : upcomingClasses?.length;
  currentCourseIndex = coursesLimit >= selectedCourseIndex + 1 ? selectedCourseIndex : 0;

  const label = getLabel(
    {
      numClasses: upcomingClasses.length,
      mobile,
      large,
      newStudent,
      selectedCourseIndex,
    },
  );

  const sameClassStartDates = upcomingClasses?.every(
    (val, i, arr) => val.startDate === arr[0].startDate,
  );

  const startDate = upcomingClasses?.[0]?.startDate ? toDateString(upcomingClasses[0].startDate, 'MMMM d') : '';

  let buttonText = (sameClassStartDates && upcomingClasses?.length < 4) ? `${linkLabelBeforeStartDate} ${startDate}`
    : linkLabelNoStartDate;
  if (large) {
    buttonText = 'Go to Academic Plan';
  }

  const componentName = 'UpcomingTile';

  return (
    <StyledCard
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      $large={large}
    >
      {label
        && (
        <HeadingTypography
          variant="body5"
          component="h2"
          tabIndex={(!mobile && showMoreCourseLength > 0) ? -1 : undefined}
          ref={focusRef}
        >
            {label}
        </HeadingTypography>
        )}
      <StyledCardContent
        $large={large}
      >
        <CourseList
          courses={sortCourses}
          userHasSkillsClass={false}
          courseSkills={{}}
          isCBEDA={isCBEDA}
          selectedCourseIndex={currentCourseIndex}
          setSelectedCourseIndex={setSelectedCourseIndex}
          instructors={instructors}
          list={!large || mobile}
          large={large}
          fullWidth
          primaryButtonText="See course overview"
        />
        {((!large || mobile) && showMoreCourseLength > 0) && (
          <ShowMoreButton
            disableFocusRipple
            onClick={handleShowMore}
            aria-expanded={showMore}
          >
            <ShowMoreIcon
              aria-hidden="true"
              icon={showMore ? 'icon-minus-mc' : 'icon-plus-mc'}
              id={showMore ? 'icon-minus-mc' : 'icon-plus-mc'}
              $showMore={showMore}
            />
            <ShowMoreText variant="body4">
              {showMore ? 'Collapse' : `Show ${showMoreCourseLength} more ${showMoreCourseLength > 1 ? 'classes' : 'class'}` }
            </ShowMoreText>
            <Box sx={visuallyHidden}>Re-displays upcoming course list</Box>
          </ShowMoreButton>
        )}
      </StyledCardContent>
      {(!large || mobile) && (
        <LinkContainer>
          <DateLink
            href={link}
          >
            {buttonText}
          </DateLink>
          <Box sx={visuallyHidden}>{linkLabelSROnly}</Box>
        </LinkContainer>
      )}
    </StyledCard>
  );
}

export default UpcomingTile;
