import SingleTile from '@/components/molecules/single-tile';
import { callPhoneNumber } from '@/myphoenix/utils/phone-functions';
import { useChat } from '@/hooks/useChat';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';

type RescheduleProps = {
  parentComponentName?: string;
  counselor: string;
  phone: string;
  small?: boolean;
};

export default function RescheduleSingleTile({
  parentComponentName = '',
  counselor,
  phone,
  small = false,
}: RescheduleProps) {
  const componentName = 'RescheduleSingleTile';
  const startChat = useChat();
  return (
    <SingleTile
      parentComponentName={`${parentComponentName}_${componentName}`}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      tileType="reschedule"
      icon="icon-calendar-mc"
      title="Schedule your next course"
      mainContent="Please call us to get your next course scheduled."
      instructor={counselor}
      phone={phone}
      primaryButtonText="Chat with us"
      primaryButtonCallback={startChat}
      secondaryButtonText="Call us"
      secondaryButtonCallback={() => callPhoneNumber(phone)}
      secondaryButtonAriaLabel={`Click to call ${phone}`}
      small={small}
    />
  );
}
