import { useMemo } from 'react';
import { toDateTime } from '@/myphoenix/utils/date-time-functions';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { useGetProgramProgressDataByProgramIdQuery } from '@/store/queries/programs';
import { AuditDetailType, CreditsSummaryType } from '@/types/api/student/detailProgression';
import { Grid, Tooltip, Typography } from '@mui/material';
import Icon from '@/components/atoms/icon';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import ArrowLink from '@/components/atoms/arrow-link';
import Donut from '@/components/molecules/donut-countdown';
import { COURSE_MESSAGES, TOOLTIP_MESSAGE } from '@/constants/graduation-countdown';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import {
  StyledCard, TitleContainer, StyledIconButton, TextGrid, DonutBox,
} from './GraduationCountdownCard.styles';

export const componentName = 'CountDown';

function getCoursesProgress(
  creditsSummary: CreditsSummaryType,
  auditDetails: AuditDetailType[],
) {
  const coursesRemaining = auditDetails.filter((course) => course.auditStatus === 'SC');
  const filteredAppliedCourses = auditDetails.filter(
    (course) => course.auditStatus === 'AP' && course.activity.internal && course.activity.grade !== 'WC',
  );
  filteredAppliedCourses.sort((a, b) => {
    const dateA = toDateTime(a.activity.startDate).toMillis();
    const dateB = toDateTime(b.activity.startDate).toMillis();
    if (dateA === dateB) return 0;
    if (Number.isNaN(dateA)) return 1;
    if (Number.isNaN(dateB)) return 0;
    return dateB - dateA;
  });
  const totalCredits = creditsSummary.required;
  let addCredits = 0;
  const nearGradThreshold = 24 - (totalCredits - creditsSummary.applied);
  let thresholdIndex = 0;
  for (let index = 0; index < filteredAppliedCourses?.length || 0; index += 1) {
    const requirements = filteredAppliedCourses[index]?.requirementDetails || [];

    const reqCredits = requirements.reduce((a, b) => a + b.creditsSummary.applied, 0);
    if ((addCredits + reqCredits) > nearGradThreshold) {
      thresholdIndex = index;
      break;
    }
    addCredits += reqCredits;

    if (addCredits === nearGradThreshold) {
      thresholdIndex = index + 1;
      break;
    }
  }

  const coursesTakenSince = filteredAppliedCourses.slice(0, thresholdIndex);

  return {
    totalCourses: (coursesRemaining?.length || 0) + (coursesTakenSince?.length || 0),
    remainingCourses: coursesRemaining?.length || 0,
  };
}

function GraduationCountdownCard() {
  const personId = getPersonIdFromSessionStorage();
  const {
    data: { primaryProgram } = {},
    isFetching: programIsLoading,
  } = useGetProgramsByIdHook(personId);
  const {
    data: {
      auditDetails = [],
      programProgress: { creditsSummary = {} } = {},
      isDegreeAudit = false,
    } = {},
    isLoading: programProgressIsLoading,
  } = useGetProgramProgressDataByProgramIdQuery({
    programId: primaryProgram?.id,
  }, { skip: !primaryProgram?.id });
  const isCbeDaProgram = primaryProgram?.cbeDaProgram === 'TRUE';

  const noInfoToShow = useMemo(() => {
    const creditsRemaining = creditsSummary && creditsSummary.scheduled;
    return (
      !isDegreeAudit
      || (creditsRemaining === 0 || creditsRemaining > 24)
      || creditsSummary.notScheduled !== 0
    );
  }, [isDegreeAudit, creditsSummary]);

  const { totalCourses, remainingCourses } = useMemo(
    () => {
      if (creditsSummary && auditDetails) {
        return getCoursesProgress(creditsSummary, auditDetails);
      }
      return {
        totalCourses: 0,
        remainingCourses: 0,
      };
    },
    [creditsSummary, auditDetails],
  );

  if (programIsLoading || programProgressIsLoading || noInfoToShow) return null;

  return (
    <StyledCard
      data-testid="graduation_countdown"
      data-component={componentNameToMyPhxKebabFormat(componentName)}
    >
      <TitleContainer>
        <Typography
          variant="body5"
          sx={{
            color: 'almostBlack.l30',
            textTransform: 'uppercase',
            mr: 0.8,
            mb: 3.2,
            fontWeight: 600,
          }}
        >
          Countdown to Graduation
        </Typography>
        <Tooltip
          title={(<Typography variant="body4">{TOOLTIP_MESSAGE}</Typography>)}
          placement="top"
          arrow
        >
          <StyledIconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <Icon
              id="icon-info-gray"
              icon="icon-info-gray"
            />
          </StyledIconButton>
        </Tooltip>
      </TitleContainer>
      <Grid
        container
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        alignItems={{ xs: 'center', sm: 'normal' }}
        justifyContent="space-between"
      >
        <TextGrid item>
          <Typography variant="body4" component="p" mb={1.6}>
            {COURSE_MESSAGES[remainingCourses] || ''}
          </Typography>
          <ArrowLink
            link={makeAbsoluteUrl('/graduation.html')}
            ariaLabel="Prepare to Graduate"
            clickEvent={{ componentName, properties: [{ remainingCourses }] }}
          >
            <Typography variant="body5" color="primary.main">Prepare to Graduate</Typography>
          </ArrowLink>
        </TextGrid>
        <Grid item>
          <DonutBox>
            <Donut
              countdownConfig={{
                total: totalCourses,
                remaining: remainingCourses,
                creditsCompleted: creditsSummary.applied,
                creditsPossible: creditsSummary.required,
                isCbeDa: isCbeDaProgram,
              }}
            />
          </DonutBox>
        </Grid>
      </Grid>
    </StyledCard>
  );
}

export default GraduationCountdownCard;
