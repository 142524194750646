import { Typography } from '@mui/material';
import { DangerTextSmall, TimeRunningOut, DangerTextLarge, StyledLink } from './AttendanceTile.styles';

export const tooltipMessage = ('Post at least one <a href="https://my.phoenix.edu/kb/article/Attendance-Requirements" target="_blank" aria-label="Academic Related Activity, (A R A)" rel="noreferrer">Academic Related Activity, (ARA)</a> in your online classroom each week. It can take up to 24 hours for <span aria-label="A R A">ARA</span> activity to update.');

export default function AttendanceMessage({ absences,
  templateCode,
  recentPost,
  daysLeftToPost,
  dayOfWeek,
  isFirstWeekOfCourse }:
{ absences: number,
  templateCode: string,
  recentPost: string,
  daysLeftToPost: number,
  dayOfWeek: string,
  isFirstWeekOfCourse: boolean, }) {
  if (recentPost !== 'none') {
    let dayPosted = '';
    if (recentPost === 'today') {
      dayPosted = 'today!';
    } else if (recentPost === 'yesterday') {
      dayPosted = 'yesterday!';
    } else if (recentPost === 'thisWeek') {
      dayPosted = 'this week!';
    }
    return (
      <>
        <Typography
          variant="body4"
          fontWeight="bold"
        >
          You posted
          {' '}
          {dayPosted}
          {' '}
        </Typography>
        { `${templateCode} weekly attendance requirement met.`}
        <div style={{ marginBottom: '1.5rem' }} />
        <Typography
          variant="body4"
          component="p"
        >
          You&apos;re doing great -
          keep it up! Meeting both your weekly attendance requirement
          and participating in class are your keys to success.
        </Typography>
      </>
    );
  }
  if (absences === 0) {
    if (dayOfWeek === 'Monday') {
      return (
        <>
          <Typography
            variant="body4"
            component="p"
          >
            <Typography
              variant="body4"
              fontWeight="bold"
            >
              Today is your last day to post in
              {' '}
              {templateCode}
              {' '}
              this week.
              {' '}
            </Typography>
          </Typography>
          <Typography
            variant="body4"
            component="p"
          >
            You have until Midnight,
            {' '}
            <StyledLink href="/kb/article/Arizona-Clock-in-Blackboard" rel="noreferrer">Arizona time</StyledLink>
            {' '}
            tonight to post an
            {' '}
            <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer" aria-label="A R A">ARA</StyledLink>
            {' '}
            to meet your weekly attendance requirement.
          </Typography>
        </>
      );
    }
    if (dayOfWeek === 'Sunday') {
      return (
        <>
          <Typography
            variant="body4"
            component="p"
          >
            <Typography
              variant="body4"
              fontWeight="bold"
            >
              You have
              {' '}
              {daysLeftToPost}
              {' '}
              days left to post in
              {' '}
              { templateCode }
              .
              {' '}
            </Typography>
          </Typography>
          <Typography
            variant="body4"
            component="p"
          >
            You have until Midnight,
            {' '}
            <StyledLink href="/kb/article/Arizona-Clock-in-Blackboard" rel="noreferrer">Arizona time</StyledLink>
            {' '}
            tomorrow night to post an
            {' '}
            <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer" aria-label="A R A">ARA</StyledLink>
            {' '}
            to meet your weekly attendance requirement.
          </Typography>
        </>
      );
    }
    if (dayOfWeek === 'Friday' || dayOfWeek === 'Saturday') {
      return (
        <>
          <Typography
            variant="body4"
            component="p"
          >
            <Typography
              variant="body4"
              fontWeight="bold"
            >
              You have
              {' '}
              {daysLeftToPost}
              {' '}
              days left to post in
              {' '}
              {templateCode}
              .
              {' '}
            </Typography>
          </Typography>
          <Typography
            variant="body4"
            component="p"
          >

            Be sure to post an
            {' '}
            <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer" aria-label="A R A">ARA</StyledLink>
            {' '}
            to meet your weekly attendance requirement to stay on track and reach your goals
          </Typography>
        </>
      );
    }
    if (isFirstWeekOfCourse) {
      return (
        <>
          <Typography
            variant="body4"
            component="p"
          >
            <Typography
              variant="body4"
              fontWeight="bold"
            >
              Meet your attendance requirement for
              {' '}
              {templateCode}
              {' '}
              to stay on track and reach your goals.
              {' '}
            </Typography>
          </Typography>
          <Typography
            variant="body4"
            component="p"
          >
            Post at least one
            {' '}
            <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer">Academic Related Activity, (ARA)</StyledLink>
            {' '}
            in your online classroom each week.
            {' '}
            <span aria-label="A R A">ARA</span>
            &apos;s include assignment,
            {' '}
            quiz and exam submissions or message and discussion posts.
          </Typography>
          <Typography
            variant="body5"
            component="p"

          >
            Blackboard “Messages” and “Collaborate” nor other
            {' '}
            3rd party application activities do not qualify as an
            {' '}
            <span aria-label="A R A">ARA</span>
            .
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography
          variant="body4"
          component="p"
          fontWeight="bold"
        >
          Keep up the good work!
          {' '}
        </Typography>
        <Typography
          variant="body4"
          component="p"
        >
          Be sure to post an
          {' '}
          <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer" aria-label="A R A">ARA</StyledLink>
          {' '}
          to meet your weekly attendance requirement for
          {' '}
          {templateCode}
          .
        </Typography>
      </>
    );
  }
  if (absences === 1) {
    if (dayOfWeek === 'Monday') {
      return (
        <>
          <DangerTextLarge variant="h5">
            Today is your last day to post.
          </DangerTextLarge>
          <Typography
            variant="body4"
            component="p"
          >
            Stay on track by posting an
            {' '}
            <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer" aria-label="A R A">ARA</StyledLink>
            {' '}
            for
            {' '}
            {templateCode}
            {' '}
            to meet your weekly attendance requirement. You have until Midnight,
            {' '}
            <StyledLink href="/kb/article/Arizona-Clock-in-Blackboard" rel="noreferrer">Arizona time</StyledLink>
            {' '}
            tonight to post an
            {' '}
            <span aria-label="A R A">ARA</span>
            .
          </Typography>
          <Typography
            variant="body4"
            component="p"
            fontWeight="bold"
          >
            You&apos;ll be automatically dropped from your course if you miss this week.
          </Typography>
        </>
      );
    }
    if (dayOfWeek === 'Sunday') {
      return (
        <TimeRunningOut>
          <DangerTextSmall>
            <Typography
              variant="body4"
              fontWeight="bold"
            >
              Time is running out!
            </Typography>
          </DangerTextSmall>
          <Typography
            variant="body4"
            component="p"
          >
            <Typography
              variant="body4"
              fontWeight="bold"
            >
              You have
              {' '}
              {daysLeftToPost}
              {' '}
              days left to post — you&apos;ll be automatically dropped if you miss this week.
              {' '}
            </Typography>
          </Typography>
          <Typography
            variant="body4"
            component="p"
          >
            Stay on track by posting an
            {' '}
            <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer" aria-label="A R A">ARA</StyledLink>
            {' '}
            for
            {' '}
            {templateCode}
            {' '}
            to meet your weekly attendance requirement. You have until Midnight,
            {' '}
            <StyledLink href="/kb/article/Arizona-Clock-in-Blackboard" rel="noreferrer">Arizona time</StyledLink>
            {' '}
            tomorrow to post an
            {' '}
            <span aria-label="A R A">ARA</span>
            .
          </Typography>
        </TimeRunningOut>
      );
    }
    if (dayOfWeek === 'Friday' || dayOfWeek === 'Saturday') {
      return (
        <>
          <Typography
            variant="body4"
            component="p"
          >
            <Typography
              variant="body4"
              fontWeight="bold"
            >
              You have
              {' '}
              {daysLeftToPost}
              {' '}
              days left to post in
              {' '}
              {templateCode}
              .
              {' '}
            </Typography>
          </Typography>
          <Typography
            variant="body4"
            component="p"
          >
            Be sure to post an
            {' '}
            <StyledLink
              href="/kb/article/Attendance-Requirements"
              rel="noreferrer"
              aria-label="A R A"
            >
              ARA
            </StyledLink>
            {' '}
            for
            {' '}
            {templateCode}
            {' '}
            to meet your weekly attendance requirement
            to stay on track and reach your goals.
          </Typography>
          <Typography
            variant="body4"
            component="p"
          >
            <Typography
              variant="body4"
              fontWeight="bold"
            >
              You&apos;ll be automatically dropped from your course if you miss this week.
            </Typography>
          </Typography>
        </>
      );
    }
  }
  return (
    <>
      <Typography
        variant="body4"
        component="p"
      >
        <Typography
          variant="body4"
          fontWeight="bold"
        >
          You have
          {' '}
          {daysLeftToPost}
          {' '}
          days left to post in
          {' '}
          {templateCode}
          .
          {' '}
        </Typography>
      </Typography>
      <Typography
        variant="body4"
        component="p"
      >
        Be sure to post an
        {' '}
        <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer" aria-label="A R A">ARA</StyledLink>
        {' '}
        to meet your weekly attendance requirement for
        {' '}
        {templateCode}
        {' '}
        to stay on track and reach your goals.
        {' '}
      </Typography>
      <Typography
        variant="body4"
        component="p"
      >
        <Typography
          variant="body4"
          fontWeight="bold"
        >
          You&apos;ll be automatically dropped from your course if you miss another week.
        </Typography>
      </Typography>
    </>
  );
}
