import SingleTile from '@/components/molecules/single-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

function LoadingScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (
      { loading },
    ) => (
      loading.primaryProgram
      || loading.tasks
      || loading.enrollmentApp
      || loading.courseMemberships
    ),
    Component: (
      { courseInfoData: { parentComponentName } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <SingleTile parentComponentName={parentComponentName} loading icon="" title="" />
    ),
  };
}

export default LoadingScenario;
