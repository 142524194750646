import SingleTile from '@/components/molecules/single-tile';
import ToggleTooltip from '@/components/atoms/tooltip';
import ErrorMessage from '@/components/atoms/error-message';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import ArrowLink from '@/components/atoms/arrow-link';
import { Typography } from '@mui/material';
import { CardComponent,
  CardContentRoot,
  HeadingWrapperDiv,
  TypographyHeading,
  BoxToolTip,
  BoxCardContent,
  BoxTextContent,
  TypographyCountdownText,
  DangerTextLarge,
  StyledLink,
  CTAWrapper,
  PostedTodayTitle,
  StyledTitle,
  StyledTypography,
  StyledBoldTypography,
  DangerTextParagraph } from './engagement.styles';

export interface EngagementProps {
  componentName: string,
  link: string,
  errorMessage: string,
  errorLinkText: string,
  toolTipMessage: string,
  postedToday: boolean,
  daysLeft: number,
  formattedDropDate: string,
  isInDangerZone: boolean,
  isFirstWeek: boolean,
  daysSinceLastPost: number,
  isError: boolean,
  isFetching: boolean,
}

function DangerMessage({
  componentName,
  daysLeft,
  link,
}:{
  componentName: string,
  daysLeft: number,
  link: string
}) {
  return (
    <>
      <DangerTextLarge>
        Today is your last day to post.
      </DangerTextLarge>
      <DangerTextParagraph
        variant="body4"
        variantMapping={{ body4: 'p' }}
      >
        Post in at least one of your classes before midnight tonight,
        {' '}
        <StyledLink href="/kb/article/Arizona-Clock-in-Blackboard" rel="noreferrer">Phoenix, Arizona time</StyledLink>
        {' '}
        to meet your weekly engagement requirement.
        {' '}
      </DangerTextParagraph>
      <Typography
        variant="body4"
        component="b"
      >
        You&apos;ll be automatically dropped from all your courses if you don&apos;t post today.
      </Typography>
      <CTAWrapper>
        <ArrowLink
          link={link}
          clickEvent={{ componentName, properties: [{ daysLeft }] }}
        >
          Post now
        </ArrowLink>
      </CTAWrapper>
    </>
  );
}

function AttendanceMessage({
  daysLeft,
  postedToday,
  isFirstWeek,
  daysSinceLastPost,
}: { daysLeft: number, postedToday: boolean, isFirstWeek: boolean, daysSinceLastPost: number }) {
  const day = daysSinceLastPost === 1 ? 'day' : 'days';
  if (daysLeft > 1 && daysLeft <= 3 && !isFirstWeek && !postedToday) {
    return (
      <StyledBoldTypography
        variant="body4"
      >
        {`Time is running out! Your last post was ${daysSinceLastPost} ${day} ago.`}
      </StyledBoldTypography>
    );
  } if (daysLeft >= 4 && daysLeft <= 7 && !postedToday && !isFirstWeek) {
    return (
      <StyledBoldTypography
        variant="body4"
      >
        {`Your last post was ${daysSinceLastPost} ${day} ago.`}
      </StyledBoldTypography>
    );
  } if (!postedToday && !isFirstWeek) {
    return (
      <StyledBoldTypography
        variant="body4"
      >
        {`Keep up the good work! Your last post was ${daysSinceLastPost} ${day} ago.`}
      </StyledBoldTypography>
    );
  }
  return null;
}

function Engagement(
  {
    componentName,
    link,
    errorMessage,
    errorLinkText,
    toolTipMessage,
    postedToday,
    daysLeft,
    formattedDropDate,
    isInDangerZone,
    isFirstWeek,
    daysSinceLastPost,
    isError,
    isFetching,
  }: EngagementProps,
) {
  if (daysLeft === 0) {
    return null;
  }

  if (isFetching) {
    return (
      <SingleTile
        data-testid="cbe_engagement-loading"
        parentComponentName={componentName}
        loading
        darkMode={false}
      />
    );
  }

  return (
    <CardComponent
      data-testid="cbe_engagement"
      $dangerzone={isInDangerZone || undefined}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
    >
      {isError ? (
        <ErrorMessage
          errorMessage={errorMessage}
          errorLinkText={errorLinkText}
          errorComponent={componentName}
          aria-label="Engagement Error"
        />
      ) : (
        <CardContentRoot>
          <HeadingWrapperDiv>
            <TypographyHeading variant="body5">
              Engagement
            </TypographyHeading>
            <BoxToolTip>
              <ToggleTooltip
                placement="top"
                tooltipMessage={toolTipMessage}
                tooltipAriaLabel={`tooltip ${componentName}`}
              />
            </BoxToolTip>
          </HeadingWrapperDiv>
          {daysLeft === 1
            ? (
              <DangerMessage
                componentName={componentName}
                daysLeft={daysLeft}
                link={link}
              />
            ) : (
              <BoxCardContent>
                <BoxTextContent>
                  {postedToday
                    ? (
                      <PostedTodayTitle variant="h6">
                        Great work! You’ve met your engagement requirement
                      </PostedTodayTitle>
                    ) : (
                      <StyledTitle
                        variant="h6"
                        $daysLeft={daysLeft}
                      >
                        Post by
                        {' '}
                        {formattedDropDate}
                        {' '}
                        to meet your engagement requirement
                      </StyledTitle>
                    )}
                  <TypographyCountdownText
                    variant="body4"
                    id="engagement-text"
                    aria-label="Attendance message"
                  >
                    <AttendanceMessage
                      postedToday={postedToday}
                      isFirstWeek={isFirstWeek}
                      daysSinceLastPost={daysSinceLastPost}
                      daysLeft={daysLeft}
                    />
                    <StyledTypography
                      variant="body4"
                    >
                      Continue posting in at least one of your classes every 14 days to avoid
                      being dropped from all your courses.
                    </StyledTypography>
                  </TypographyCountdownText>
                  <ArrowLink
                    ariaLabel="Go to your classes"
                    link={link}
                    clickEvent={{ componentName, properties: [{ daysLeft }] }}
                  >
                    Go to your classes
                  </ArrowLink>
                </BoxTextContent>
              </BoxCardContent>
            )}
        </CardContentRoot>
      )}
    </CardComponent>
  );
}
export default Engagement;
