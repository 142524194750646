import SingleTile from '@/components/molecules/single-tile';
import { callPhoneNumber, formatPhoneNumberWithParentheses } from '@/myphoenix/utils/phone-functions';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { navigate } from '@/helpers/window-functions';
import { useTheme } from '@mui/material/styles';

type WelcomeProps = {
  parentComponentName?: string;
  small?: boolean;
  counselor?: string;
  phone?: string;
  selectedProgram: SelectedProgram;
};

type SelectedProgram = {
  cbeProgram: string;
  cbeDaProgram: string;
};

// selectedProgram slice should be added when integrated in CourseInfo
export default function WelcomeSingleTile({
  parentComponentName,
  small = false,
  counselor = '',
  phone = '(800) 366-9699',
  selectedProgram,
}: WelcomeProps) {
  const componentName = 'WelcomeSingleTile';
  const isCBEDA = selectedProgram?.cbeDaProgram === 'TRUE';
  const isCBE = selectedProgram?.cbeProgram === 'TRUE';
  const additionalContent = isCBEDA ? `Let's get you scheduled for your term. Call your enrollment
    representative about your options.` : 'Talk to your Enrollment Representative about your schedule options.';
  let welcomeLink = 'https://multimedia.phoenix.edu/cms/20205040/';
  if (isCBE) {
    welcomeLink = 'https://multimedia.phoenix.edu/cms/20205697/';
  } else if (isCBEDA) {
    welcomeLink = 'https://multimedia.phoenix.edu/cms/202230497/';
  }

  const theme = useTheme();

  return (
    <SingleTile
      parentComponentName={`${parentComponentName}_${componentName}`}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      tileType="welcome"
      icon="icon-class-mc"
      iconColor={theme.palette.common.black}
      title="Welcome"
      mainContent="We're happy you're here!"
      additionalContent={additionalContent}
      small={small}
      phone={formatPhoneNumberWithParentheses(phone)}
      instructor={counselor}
      primaryButtonText="Call us"
      primaryButtonCallback={() => callPhoneNumber(phone)}
      primaryButtonAriaLabel={`Call Enrollment Representative ${counselor}`}
      secondaryButtonText="Student orientation"
      secondaryButtonCallback={() => navigate(welcomeLink)}
    />
  );
}
