import { AchievementTypes } from '@/helpers/enums/notifications';

export type AchievementModalTemplate = {
  header: string;
  message: string;
  imgFile?: string;
  altText?: string;
  ctaText: string;
  icon?: string;
  skill?: string;
  imageUrl?: string;
  acceptBadgeUrl?: string;
};

export const GetTemplate = ({
  achievementType,
  firstName,
  ctaText = 'View in Academic Plan',
  resources = [],
}: {
  achievementType: AchievementTypes,
  firstName: string,
  ctaText: string,
  resources: { resource: { key: string, value: string }[] }[],
}): AchievementModalTemplate => {
  const templates: {
    [AchievementTypes.DeansList]: AchievementModalTemplate,
    [AchievementTypes.PresidentsList]: AchievementModalTemplate,
    [AchievementTypes.Skill]: AchievementModalTemplate,
  } = {
    [AchievementTypes.DeansList]: {
      header: `Congratulations, ${firstName}!`,
      message: 'We\'re so proud of you! You\'ve earned a place on the Dean\'s List.',
      imgFile: 'deans-list-badge.svg',
      altText: 'Dean\'s List badge',
      ctaText,
    },
    [AchievementTypes.PresidentsList]: {
      header: `Congratulations, ${firstName}!`,
      message: 'We applaud you! You\'ve earned a place on the University of Phoenix President\'s List.',
      imgFile: 'presidents-list-badge.svg',
      altText: 'President\'s List badge',
      ctaText,
    },
    [AchievementTypes.Skill]: {
      header: 'Congratulations on earning a new badge!',
      message: 'We\'re so proud of you - you\'ve demonstrated a new skill! Show off what you\'ve learned by sharing your badge.',
      icon: null,
      ctaText: 'Claim my badge',
    },
  };

  const template = templates?.[achievementType];

  if (resources?.[0]?.resource?.length) {
    resources[0].resource.forEach((kvp) => {
      switch (kvp.key) {
        case 'skill':
          template.skill = kvp.value;
          break;
        case 'image_url':
          template.imageUrl = kvp.value;
          break;
        case 'accept_badge_url':
          template.acceptBadgeUrl = kvp.value;
          break;
        default:
          break;
      }
    });
  }

  return template;
};
