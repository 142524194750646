import { Typography, Divider as MuiDivider, Card as MuiCard, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withTransientProps } from '@/helpers/with-transient-props';
import StandardLink from '@/components/atoms/standard-link';
import StandardButton from '@/components/atoms/standard-button';

export const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.almostBlack.l30,
  textTransform: 'uppercase',
  fontSize: theme.spacing(1.4),
  lineHeight: theme.spacing(1.6),
})) as typeof Typography;

export const SubHeading = styled(Typography, withTransientProps)<{ $small?: boolean }>(
  ({ theme }) => ({
    fontWeight: 900,
    lineHeight: theme.spacing(2.8),
    fontSize: theme.spacing(2.4),
    display: 'block',
  }),
) as typeof Typography;

export const Button = styled(StandardButton, withTransientProps)<{ $chat?: boolean }>((
  { theme },
) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: theme.spacing(2.8),
  textTransform: 'none',
  boxShadow: 'none',
  height: theme.spacing(5),
  fontWeight: 900,
  padding: theme.spacing(0, 1),
  marginBottom: theme.spacing(1.2),
  fontSize: theme.spacing(1.6),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(24),
    height: theme.spacing(5),
    bottom: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
  },

}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export const CardBody = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(2.4),
  fontWeight: 500,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    fontWeight: 400,
  },
  fontSize: theme.spacing(1.6),
})) as typeof Typography;

export const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  margin: '0 auto',
  padding: theme.spacing(3),
  '& .MuiCardActions-root': {
    padding: theme.spacing(0),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  display: 'block',
  paddingBottom: theme.spacing(0.1),
  fontSize: theme.spacing(1.6),
  color: theme.palette.text.primary,
  borderBottom: `${theme.spacing(0.2)} solid`,
  textDecoration: 'none',
  '&:hover, &:focus': {
    borderBottomColor: theme.palette.primary.main,
  },
})) as typeof Link;

export const LoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  margin: `${theme.spacing(4)} auto`,
}));
