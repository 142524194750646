import { withTransientProps } from '@/helpers/with-transient-props';
import { styled } from '@mui/material/styles';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import Icon from '@/components/atoms/icon';

export const StyledBox = styled(Box, withTransientProps)<{
  $severity: string }>(({ theme, $severity = theme.palette.custom.infoBlue }) => ({
  border: `${theme.spacing(0.2)} solid ${$severity}`,
  borderRadius: theme.spacing(0.4),
  display: 'flex',
  padding: `${theme.spacing(1.4)} ${theme.spacing(1.6)}`,
  marginBottom: `${theme.spacing(2.4)}`,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.common.white,
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  height: theme.spacing(2.2),
  width: theme.spacing(2.2),
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  paddingBottom: theme.spacing(0.8),
  lineHeight: theme.spacing(2.4),
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginTop: theme.spacing(-0.2),
  marginRight: theme.spacing(-0.4),
}));

export const MessageGridItem = styled(Grid, withTransientProps)<{
  $isDismissable?: boolean }>(({ theme, $isDismissable = false }) => ({
  marginTop: theme.spacing(1.0),
  marginRight: theme.spacing($isDismissable ? -0.7 : 1.2),
}));

export const MessageTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.almostBlack.l30,
  fontWeight: '400',
  lineHeight: theme.spacing(2),
}));

export const AlertGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const ArrowLinkGridItem = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1.2),
}));
