import { useMediaQuery, Theme } from '@mui/material';

type IFrameProps = {
  url?: string,
  content?: string,
  isApplication?: boolean,
  frameProps?: { [item: string]: any },
  styleProps?: { [item: string]: any },
};

function IframeElement({
  url,
  content,
  isApplication,
  frameProps = { scrolling: false, frameBorder: 0 },
  styleProps,
}: IFrameProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const scrolling = !!(isMobile || frameProps?.scrolling);

  let frameWindow: any;

  const writeFrameContent = (frame: any) => {
    if (!frame) {
      return;
    }
    frameWindow = frame;
    if (content) {
      if (!frameWindow.contentDocument) return;
      frameWindow.contentDocument.open();
      frameWindow.contentDocument.write(content);
      frameWindow.contentDocument.close();
    } else if (url) {
      frameWindow.src = url;
    }
  };

  const handleOnLoad = () => {
    const scrollHeight = `${(frameWindow.contentWindow.document.body.scrollHeight || 0) / 10}rem`;
    const scrollWidth = `${(frameWindow.contentWindow.document.body.scrollWidth || 0) / 10}rem`;
    if (content && frameWindow) {
      frameWindow.style.border = 'none';
      if (scrolling || isMobile) {
        frameWindow.style.width = '100%';
        frameWindow.style.height = isApplication ? '75rem' : scrollHeight;
      } else {
        frameWindow.style.width = isApplication ? '80rem' : scrollWidth;
        frameWindow.style.height = isApplication ? '70rem' : scrollHeight;
      }
    }
  };

  return (
    <iframe
      {...frameProps}
      title={frameProps?.title || 'Iframe Content'}
      src="about:blank"
      onLoad={handleOnLoad}
      ref={writeFrameContent}
      style={styleProps}
    />
  );
}

export default IframeElement;
