import React, { PropsWithChildren } from 'react';
import { StyledStandardButton } from './LinkButton.styles';
import { StandardButtonProps } from '../standard-button';

type LinkButtonProps = PropsWithChildren<{
  href?: string,
  children?: React.ReactNode,
  version?: 'default' | 'red-hover',
  [key: string]: any,
}>;

function LinkButton({
  href,
  children,
  version = 'default',
  ...rest
}: LinkButtonProps & StandardButtonProps) {
  return (
    <StyledStandardButton
      href={href}
      version={version}
      {...rest}
    >
      {children}
    </StyledStandardButton>
  );
}

export default LinkButton;
