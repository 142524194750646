import { styled } from '@mui/material/styles';
import { Box, Card, CircularProgress } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2.0, 2.0, 2.0, 2.0),
  '& .MuiCardContent-root:last-child': {
    paddingBottom: 0,
  },
}));

export const SpinnerWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
}));

export const Loading = styled(CircularProgress)(({ theme }) => ({
  minWidth: theme.spacing(15),
  minHeight: theme.spacing(15),
  alignSelf: 'center',
}));
