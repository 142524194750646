import WelcomeSingleTile from '@/components/molecules/welcome-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

function WelcomeScenario(): CourseInfoScenarioInterface {
  return {
    // This is the fall-through scenario, so shouldShow is always true
    shouldShow: (courseInfoData: CourseInfoData) => !!courseInfoData,
    Component({ courseInfoData }: { courseInfoData: CourseInfoData }) {
      const {
        enrollmentRep: { phoneNumber, firstName, lastName },
        parentComponentName,
        selectedProgram,
      } = courseInfoData;
      return (
        <WelcomeSingleTile
          parentComponentName={parentComponentName}
          counselor={firstName && lastName ? `${firstName} ${lastName}` : undefined}
          phone={phoneNumber || undefined}
          selectedProgram={selectedProgram}
        />
      );
    },
  };
}

export default WelcomeScenario;
