import { IconButton, styled } from '@mui/material';
import Icon from '@/components/atoms/icon';
import StandardButton from '@/components/atoms/standard-button';

export const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 2.6, 1.6),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2.7, 2),
  },
}));

export const CloseButton = styled(StandardButton)(({ theme }) => ({
  padding: theme.spacing(0, 0.8, 0.6),
  fontSize: theme.spacing(1.6),
  color: theme.palette.text.primary,
  fontWeight: 400,
  letterSpacing: 'normal',
  minWidth: 'unset',
  '&:hover': {
    background: 'none',
  },
}));

export const CloseButtonLabel = styled('span')(({ theme }) => ({
  width: 'auto',
  borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.common.black}`,
  '&:hover': {
    borderBottomColor: theme.palette.primary.main,
  },
}));

export const TopBar = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}));

export const PrintButton = styled(IconButton)(({ theme }) => ({
  '&:focus': {
    outline: `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
    outlineOffset: theme.spacing(0.3),
  },
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  height: theme.spacing(2.4),
  width: theme.spacing(2.4),
  '&:hover': {
    fill: theme.palette.primary.main,
  },
}));
