/* eslint-disable import/extensions */
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import mapStatus from '@/components/molecules/academic-status/mapValues.json';
import { useDispatch } from 'react-redux';
import { setSelectedProgramId } from '@/store/slices/session';
import { useEffect, useState } from 'react';
import { Status } from '@/types/api/student/studentStatus';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import AcademicStatus from './AcademicStatus.ui';

function AcademicStatusContainer() {
  const { data: {
    primaryProgram,
    programsList,
    selectedProgramId,
  }, isFetching, isError } = useGetProgramsByIdHook(
    getPersonIdFromSessionStorage(),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedProgramId && primaryProgram?.id) {
      dispatch(setSelectedProgramId(primaryProgram?.id));
    }
  }, [selectedProgramId, primaryProgram, dispatch]);

  const [selectedProgram, setSelectedProgram] = useState(primaryProgram);
  useEffect(() => {
    if (programsList) {
      if (selectedProgram?.id !== selectedProgramId) {
        setSelectedProgram(programsList.byId[selectedProgramId]);
      }
    }
  }, [programsList, selectedProgram, selectedProgramId]);

  const selectedProgramStatuses = selectedProgram ? selectedProgram?.statuses
    : primaryProgram?.statuses;
  const statusArr = selectedProgramStatuses || [];

  const filteredStatus = statusArr.filter((object: Status) => {
    const statusObj = mapStatus[object.statusType as keyof typeof mapStatus];
    return (statusObj !== undefined
      && statusObj.statusCode[object.status as keyof typeof statusObj.statusCode] !== undefined
      && ((object.statusType === 'AdmissionStatus' && object.status !== 'AM')
        || (object.statusType === 'CandidacyStatus' && object.status !== '2S')
        || (object.statusType === 'StudentAcademicStatus' && object.status !== 'RG')
        || (object.statusType === 'AcademicStatus' && object.status !== 'RG')
        || (object.statusType === 'EnrollmentStatus'))
    );
  });

  const programCompletionDate = selectedProgram ? selectedProgram?.programCompletionDate
    : primaryProgram?.programCompletionDate;
  const lastDateOfAttendance = selectedProgram ? selectedProgram?.lastActivityDate
    : primaryProgram?.lastActivityDate;

  return (
    <AcademicStatus
      filteredStatus={filteredStatus}
      programCompletionDate={programCompletionDate}
      lastDateOfAttendance={lastDateOfAttendance}
      isError={isError}
      isFetching={isFetching}
    />
  );
}

export default AcademicStatusContainer;
