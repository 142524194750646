import { Grid, Theme, useMediaQuery } from '@mui/material';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { Body, Heading, StyledButton, BodyHeading, BodyContent } from '@/components/molecules/b2b-interrupter/B2bInterrupter.styles';

function B2bInterrupter({
  parentComponentName = '',
}: {
  parentComponentName?: string
}) {
  const componentName = parentComponentName ? `${parentComponentName}B2bInterrupter` : 'B2bInterrupter';
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const B2bCTA = 'https://tuitionsavings.phoenix.edu/5250/8/db.html';

  return (
    <Body container direction="column" data-component={componentNameToMyPhxKebabFormat(componentName)}>
      <Grid container item direction="row" justifyContent="space-between">
        <Grid item md={6}>
          <Heading variant="h5" component="h3">Your employer + UOPX</Heading>
        </Grid>
        <Grid item>
          {!isMobile && <StyledButton href={B2bCTA}>Maximize your benefits</StyledButton>}
        </Grid>
      </Grid>
      <Grid container direction="column">
        <BodyHeading variant="body4">
          You’re almost there! You’ll unlock free courses when you reach $5,250 in tuition payments.
        </BodyHeading>
        <BodyContent variant="body4">
          Make sure you complete 8 courses in 2024 to maximize your benefits.
          Keep it up and you could save thousands.
        </BodyContent>
        {isMobile && <StyledButton href={B2bCTA}>Maximize your benefits</StyledButton>}
      </Grid>
    </Body>
  );
}

export default B2bInterrupter;
