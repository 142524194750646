import { Box, Grid, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withTransientProps } from '@/helpers/with-transient-props';
import Icon from '@/components/atoms/icon';
import GrayPhoenix from 'public/images/gray-phoenix.png';

export const ContentWrapper = styled('div')(() => ({
  zIndex: '1',
}));

export const StyledBackground = styled('div', withTransientProps)<{
  $hiddenDivScaleFactor?: number
}>((
  { theme, $hiddenDivScaleFactor },
) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundImage: `url(${GrayPhoenix})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  overflowX: 'hidden',
  height: theme.spacing($hiddenDivScaleFactor * 75),
  width: theme.spacing($hiddenDivScaleFactor * 60),
}));

export const StyledCard = styled(Card, withTransientProps)<{
  $hiddenDivScaleFactor?: number
}>((
  { theme, $hiddenDivScaleFactor },
) => ({
  width: theme.spacing($hiddenDivScaleFactor * 41.9),
}));

export const MessageText = styled(Typography, withTransientProps)<{
  $hiddenDivScaleFactor?: number }>((
  { theme, $hiddenDivScaleFactor },
) => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(
    $hiddenDivScaleFactor * 3.1,
    $hiddenDivScaleFactor * 3.1,
    $hiddenDivScaleFactor * 2.6,
    $hiddenDivScaleFactor * 3.1,
  ),
  fontSize: theme.spacing($hiddenDivScaleFactor * 2.1),
}));

export const DonutGrid = styled(Grid, withTransientProps)<{
  $hiddenDivScaleFactor?: number
}>((
  { theme, $hiddenDivScaleFactor },
) => ({
  width: theme.spacing($hiddenDivScaleFactor * 20),
}));

export const PercentageText = styled(Typography, withTransientProps)<{
  $hiddenDivScaleFactor?: number, $completed? : boolean,
}>((
  { theme, $hiddenDivScaleFactor, $completed },
) => ({
  display: 'block',
  textAlign: 'center',
  marginBottom: theme.spacing($hiddenDivScaleFactor * 2),
  fontWeight: 800,
  color: $completed ? theme.palette.donut.systemGreen : theme.palette.common.black,
  fontSize: theme.spacing($hiddenDivScaleFactor * 3.8),
}));

export const CreditsText = styled(Typography, withTransientProps)<{
  $hiddenDivScaleFactor?: number
}>((
  { theme, $hiddenDivScaleFactor },
) => ({
  display: 'block',
  fontSize: theme.spacing($hiddenDivScaleFactor * 2.1),
  lineHeight: theme.spacing($hiddenDivScaleFactor * 2.1),
}));

export const CompletedText = styled(Typography, withTransientProps)<{
  $hiddenDivScaleFactor?: number
}>((
  { theme, $hiddenDivScaleFactor },
) => ({
  textAlign: 'center',
  fontSize: theme.spacing($hiddenDivScaleFactor * 1.3),
}));

export const ZeroCredits = styled('div', withTransientProps)<{
  $hiddenDivScaleFactor?: number
}>((
  { theme, $hiddenDivScaleFactor },
) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  fontWeight: 'bold',
  fontStyle: 'italic',
  marginLeft: theme.spacing($hiddenDivScaleFactor * -0.4),
  fontSize: theme.spacing($hiddenDivScaleFactor * 3.1),
}));

export const NameText = styled(Typography, withTransientProps)<{
  $hiddenDivScaleFactor?: number
}>((
  { theme, $hiddenDivScaleFactor },
) => ({
  textAlign: 'center',
  fontSize: theme.spacing($hiddenDivScaleFactor * 2.6),
  fontWeight: 800,
  padding: theme.spacing(
    $hiddenDivScaleFactor * 3.7,
    $hiddenDivScaleFactor * 1.5,
    $hiddenDivScaleFactor * 0.8,
  ),
  display: 'flex',
  justifyContent: 'center',
}));

export const ProgramText = styled(Typography, withTransientProps)<{
  $hiddenDivScaleFactor?: number
}>((
  { theme, $hiddenDivScaleFactor },
) => ({
  display: 'block',
  textAlign: 'center',
  padding: theme.spacing(0, $hiddenDivScaleFactor * 1.3, $hiddenDivScaleFactor * 1.3),
  fontSize: theme.spacing($hiddenDivScaleFactor * 1.8),
  marginBottom: theme.spacing($hiddenDivScaleFactor * 2.1),
}));

export const DonutContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const LogoIcon = styled(Icon, withTransientProps)<{ $hiddenDivScaleFactor?: number }>((
  { theme, $hiddenDivScaleFactor },
) => ({
  width: theme.spacing($hiddenDivScaleFactor * 19),
  height: theme.spacing($hiddenDivScaleFactor * 5.5),
  marginBottom: theme.spacing($hiddenDivScaleFactor * 2.6),
}));
