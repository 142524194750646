import { useState } from 'react';
import { useMediaQuery, Theme, Grid } from '@mui/material';
import type { CourseInfoData } from '@/types/courseInfoData';
import type { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import CourseList from '@/components/molecules/course-list';
import InfoCard from '@/components/molecules/info-card';
import {
  getDroppedCourseMemberships,
  getDroppedCourses,
  getEnrolledCourseMemberships,
  getEnrolledCourses,
} from '@/myphoenix/utils/course-functions';

function CurrentCourseScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => (
      courseInfoData?.currentCourseMemberships?.length >= 1
      && (
        getEnrolledCourseMemberships(courseInfoData.currentCourseMemberships)?.length >= 1
        || getDroppedCourseMemberships(courseInfoData.currentCourseMemberships)?.length > 1
      )
    ),
    Component: (
      { courseInfoData: {
        parentComponentName,
        currentCourses,
        primaryProgram: {
          name: programName = '',
          isCBEDA,
        },
        courseSkills,
        canUserViewSkills,
        instructorsBySectionId,
      } }
      : { courseInfoData: CourseInfoData },
    ) => {
      const [selectedCourseIndex, setSelectedCourseIndex] = useState(0);
      const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
      const enrolledCourses = getEnrolledCourses(currentCourses);
      const droppedCourses = getDroppedCourses(currentCourses);
      const enrolledAndDroppedCourses = droppedCourses?.length
        ? [...enrolledCourses, ...droppedCourses] : enrolledCourses;

      const hasSkills = courseSkills
        && Object.values(courseSkills)?.some((skillList) => skillList?.length > 0);

      if (isMobile) {
        return (
          <Grid container spacing={2.5} flexDirection="column">
            {enrolledAndDroppedCourses?.map((course, index) => (
              <Grid item key={course.id}>
                <InfoCard
                  parentComponentName={parentComponentName}
                  heading={programName}
                  tileType={`current-course-${index}`}
                  content={(
                    <CourseList
                      courses={[course]}
                      courseSkills={courseSkills}
                      selectedCourseIndex={selectedCourseIndex}
                      setSelectedCourseIndex={setSelectedCourseIndex}
                      userHasSkillsClass={canUserViewSkills && hasSkills}
                      instructors={instructorsBySectionId}
                      isCBEDA={isCBEDA}
                      primaryButtonText="Go to class"
                    />
                  )}
                />
              </Grid>
            ))}
          </Grid>
        );
      }

      return enrolledAndDroppedCourses.length ? (
        <InfoCard
          parentComponentName={parentComponentName}
          heading={
            enrolledAndDroppedCourses.length > 1
              ? `Class ${selectedCourseIndex + 1} of ${enrolledAndDroppedCourses.length}`
              : programName
          }
          tileType="current-course"
          content={(
            <CourseList
              courses={enrolledAndDroppedCourses}
              courseSkills={courseSkills}
              selectedCourseIndex={selectedCourseIndex}
              setSelectedCourseIndex={setSelectedCourseIndex}
              userHasSkillsClass={canUserViewSkills && hasSkills}
              instructors={instructorsBySectionId}
              isCBEDA={isCBEDA}
              fullWidth
              primaryButtonText="Go to class"
            />
          )}
        />
      ) : null;
    },
  };
}

export default CurrentCourseScenario;
