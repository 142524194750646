/* eslint-disable import/extensions */
import mapStatus from '@/components/molecules/academic-status/mapValues.json';
import { toDateString } from '@/myphoenix/utils/date-time-functions';
import ErrorMessage from '@/components/atoms/error-message';
import ToggleTooltip from '@/components/atoms/tooltip';
import {
  CircularProgress,
  Fade,
  ListItem,
  Typography,
} from '@mui/material';
import { Status } from '@/types/api/student/studentStatus';
import { StyledList, StatusLabel, DateItem, TooltipBox } from './AcademicStatus.styles';

interface AcademicStatusProps {
  filteredStatus: Status[],
  programCompletionDate: string,
  lastDateOfAttendance: string,
  isFetching: boolean,
  isError: boolean,
}

export default function AcademicStatus({
  filteredStatus,
  programCompletionDate,
  lastDateOfAttendance,
  isError,
  isFetching,
}: AcademicStatusProps) {
  const displayStatus = filteredStatus.map((object: any) => {
    const statusObj = mapStatus[object.statusType as keyof typeof mapStatus];
    const description = statusObj.statusCode[object.status as keyof typeof statusObj.statusCode];
    return (
      <div key={statusObj.statusLabel}>
        <ListItem disableGutters>
          <div>
            <StatusLabel variant="body4">{statusObj.statusLabel}</StatusLabel>
            <TooltipBox>
              <ToggleTooltip
                tooltipLinkLabel={statusObj.statusLabel}
                tooltipMessage={statusObj.tooltipMessage}
                tooltipAriaLabel={`tooltip ${statusObj.statusLabel}`}
                placement="bottom"
              />
            </TooltipBox>
          </div>
          <Typography variant="body4">{description}</Typography>
        </ListItem>
      </div>
    );
  });
  const displayDate = () => {
    const toolTipMessage = programCompletionDate ? "This is the date of the last activity that completed your program's requirements." : 'This is the most recent date you were active in this program.';
    const label = programCompletionDate ? 'Program Completed' : 'Last Date of Attendance';
    const date = programCompletionDate || lastDateOfAttendance;
    const formattedDate = toDateString(date, 'LLL dd, yyyy');
    return (
      <DateItem key="dateOfCompletionOrAttendance" disableGutters>
        <div>
          <StatusLabel variant="body4">{label}</StatusLabel>
          <TooltipBox>
            <ToggleTooltip
              tooltipLinkLabel={label}
              tooltipMessage={toolTipMessage}
              tooltipAriaLabel={`tooltip ${label}`}
              placement="bottom"
            />
          </TooltipBox>
        </div>
        <Typography variant="body4">
          {formattedDate}
        </Typography>
      </DateItem>
    );
  };

  return (
    <StyledList>
      {!isError ? (
        <>
          {isFetching && (
            <ListItem disableGutters>
              <Fade in timeout={500} style={{ transitionDelay: '500ms' }}>
                <CircularProgress />
              </Fade>
            </ListItem>
          )}
          {!isFetching && (
          <>
            {displayStatus}
            {(lastDateOfAttendance || programCompletionDate) && displayDate()}
          </>
          )}
        </>
      ) : (
        <ListItem disableGutters>
          <ErrorMessage errorComponent="AcademicStatus" />
        </ListItem>
      )}
    </StyledList>
  );
}
