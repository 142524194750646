import SingleTile from '@/components/molecules/single-tile';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { navigate } from '@/helpers/window-functions';

type NearGradTileProps = {
  parentComponentName?: string,
  primaryProgramName: string,
};

export default function NearGradTile({
  parentComponentName,
  primaryProgramName,
}: NearGradTileProps) {
  const componentName = 'NearGradTile';
  const tileType = 'near-grad';

  return (
    <SingleTile
      parentComponentName={`${parentComponentName}_${componentName}`}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      tileType={tileType}
      icon="icon-grad-cap-mc"
      label={primaryProgramName}
      title="Congratulations! You're almost a graduate."
      mainContent="Take a moment to pause and acknowledge your accomplishment, we're proud of you. We're waiting on your final grades before you officially graduate."
      primaryButtonText="Prepare for commencement"
      primaryButtonCallback={() => navigate('/commencement.html')}
    />
  );
}
