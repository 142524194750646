import { toDateString } from '@/myphoenix/utils/date-time-functions';
import { getBrightspaceClassUrl } from '@/myphoenix/utils/brightspace-functions';
import { useDisplayEventTrackingOnMount } from '@/hooks/useDisplayEventTracking';
import {
  componentNameToMyPhxKebabFormat,
  mapVariableToValue,
} from '@/helpers/string-utils';
import ToggleTooltip from '@/components/atoms/tooltip';
import ArrowLink from '@/components/atoms/arrow-link';
import Donut from '@/components/molecules/donut-countdown';
import { Typography } from '@mui/material';
import {
  StyledCard,
  StyledHeading,
  HeadingWrapper,
  StyledCardContentRoot,
  StyledCardContent,
  StyledTextContent,
  StyledCountDownText,
  StyledDonutBox,
  StyledWarningTitle,
  DangerTextLarge,
  CTAWrapper,
  StyledLink,
} from './CBEDAAttendance.styles';
import {
  heading,
  toolTipMessage,
  linkLabel,
  urgentLinkLabel,
  postedTodayMessage,
  postedTodaySecondLine,
  between10And14DaysToPostMessage,
  between10And14DaysToPostSecondLine,
  between4And10DaysToPostBoldedMessage,
  between4And10DaysToPostMessage,
  between4And10DaysToPostSecondLine,
  between1And4DaysWarningTitle,
  between1And4DaysToPostMessage,
  between1And4DaysToPostSecondLine,
} from './message/constants';

type CBEDAAttendanceProps = {
  parentComponentName: string,
  lastPost: string,
  postedToday: boolean,
  daysLeft: number,
};

function DangerMessage({
  componentName,
  daysLeft,
  link,
}:{
  componentName: string,
  daysLeft: number,
  link: string
}) {
  return (
    <>
      <DangerTextLarge>
        Today is your last day to post.
      </DangerTextLarge>
      <Typography variant="body4">
        Stay on track by posting an
        {' '}
        <StyledLink href="/kb/article/Attendance-Requirements" rel="noreferrer" aria-label="A R A">ARA</StyledLink>
        {' '}
        to meet your weekly attendance requirement. You have until Midnight,
        {' '}
        <StyledLink href="/kb/article/Arizona-Clock-in-Blackboard" rel="noreferrer">Arizona time</StyledLink>
        {' '}
        tonight to post an
        {' '}
        <span aria-label="A R A">ARA</span>
        .
      </Typography>
      <Typography
        variant="body4"
        component="b"
      >
        You&apos;ll be automatically dropped from your course if you miss this week.
      </Typography>
      <CTAWrapper>
        <ArrowLink
          link={link}
          clickEvent={{ componentName, properties: [{ daysLeft }] }}
        >
          Post now
        </ArrowLink>
      </CTAWrapper>
    </>
  );
}

function AttendanceMessage({
  days,
  date,
  postedToday,
}: { days: number, date: string, postedToday: boolean }): JSX.Element {
  if (days > 1 && days <= 3 && !postedToday) {
    return (
      <>
        <p>
          <StyledWarningTitle>
            {mapVariableToValue(
              'Time is running out!',
              between1And4DaysWarningTitle || '',
            )}
          </StyledWarningTitle>
        </p>
        <p>
          <b>
            {mapVariableToValue(
              `Your last post was on ${date}`,
              between1And4DaysToPostMessage || '',
              { '#{date}': date },
            )}
          </b>
        </p>
        <p>
          {mapVariableToValue(
            'Stay on track by posting in at least one of your classes to avoid being withdrawn from all your scheduled courses for the term.',
            between1And4DaysToPostSecondLine || '',
          )}
        </p>
      </>
    );
  } if (days >= 4 && days <= 10 && !postedToday) {
    return (
      <>
        <p>
          <b>{between4And10DaysToPostBoldedMessage}</b>
          {' '}
          {mapVariableToValue(
            ` Your last post was on ${date}`,
            between4And10DaysToPostMessage || '',
            { '#{date}': date },
          )}
        </p>
        <p>
          {mapVariableToValue(
            'You’ll need to post in at least one of your classes to avoid being withdrawn from all your scheduled courses for the term.',
            between4And10DaysToPostSecondLine || '',
          )}
        </p>
      </>
    );
  } if (days > 10 && days <= 14 && !postedToday) {
    return (
      <>
        <p>
          {mapVariableToValue(
            `Your last post was on ${date}`,
            between10And14DaysToPostMessage || '',
            { '#{date}': date },
          )}
        </p>
        <p>
          {mapVariableToValue(
            'Be sure to post in at least one of your classes to avoid being withdrawn from all your scheduled courses for the term.',
            between10And14DaysToPostSecondLine || '',
          )}
        </p>
      </>
    );
  } if (postedToday) {
    return (
      <>
        <p>
          <b>
            {mapVariableToValue(
              'You’ve met your attendance requirement.',
              postedTodayMessage || '',
            )}
          </b>
        </p>
        <p>
          {mapVariableToValue(
            'Keep it up! Completing your academic activities and participating in class is the key to success.',
            postedTodaySecondLine || '',
          )}
        </p>
      </>
    );
  }
  return null;
}

function CBEDAAttendance({
  parentComponentName = '',
  lastPost = '',
  postedToday = false,
  daysLeft = 14,
}: CBEDAAttendanceProps) {
  const componentName: string = `${parentComponentName}AttendanceTile`;
  const formattedLastPost: string = toDateString(lastPost, 'DDD');
  const link: string = getBrightspaceClassUrl();
  const formattedDate: string = lastPost ? formattedLastPost : null;
  const linkLabelValue: string = !postedToday && daysLeft <= 3 ? urgentLinkLabel : linkLabel;

  const isInDangerZone = (daysLeft === 1 && !postedToday);

  useDisplayEventTrackingOnMount(
    componentName,
    [{ daysLeft }, { postedToday }],
  );

  return (
    <StyledCard
      data-testid="cbeda_engagement-attendance"
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      $dangerzone={isInDangerZone || undefined}
    >
      <StyledCardContentRoot>
        <HeadingWrapper>
          <StyledHeading variant="body5">
            {heading}
          </StyledHeading>
          <ToggleTooltip
            tooltipMessage={toolTipMessage}
            tooltipAriaLabel={`tooltip ${heading}`}
            placement="right"
          />
        </HeadingWrapper>
        {isInDangerZone
          ? (
            <DangerMessage
              componentName={componentName}
              daysLeft={daysLeft}
              link={link}
            />
          ) : (
            <StyledCardContent>
              <StyledDonutBox>
                <Donut
                  cbeDaEngagementConfig={{
                    remaining: daysLeft,
                    postedToday,
                  }}
                />
              </StyledDonutBox>
              <StyledTextContent>
                <StyledCountDownText
                  variant="body4"
                  id="engagement-text"
                >
                  <AttendanceMessage
                    days={daysLeft}
                    date={formattedDate}
                    postedToday={postedToday}
                  />
                </StyledCountDownText>
                <ArrowLink
                  ariaLabel={linkLabelValue}
                  link={link}
                  clickEvent={{ componentName, properties: [{ daysLeft }] }}
                >
                  {linkLabelValue}
                </ArrowLink>
              </StyledTextContent>
            </StyledCardContent>
          )}
      </StyledCardContentRoot>
    </StyledCard>
  );
}

export default CBEDAAttendance;
