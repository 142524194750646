import SingleTile from '@/components/molecules/single-tile';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { navigate } from '@/helpers/window-functions';
import { callPhoneNumber, formatPhoneNumberWithParentheses } from '@/myphoenix/utils/phone-functions';
import { useChat } from '@/hooks/useChat';
import { DateTime } from 'luxon';
import { getCurrentDate } from '@/myphoenix/utils/date-time-functions';

type WithdrawProps = {
  parentComponentName?: string;
  counselor?: string;
  phone?: string;
  small?: boolean;
  subType?: string;
  withdrawnDate?: DateTime;
  primaryProgramName: string;
};

export default function GenericWithdrawTile({
  parentComponentName,
  counselor,
  phone,
  small = false,
  subType,
  withdrawnDate,
  primaryProgramName,
}: WithdrawProps) {
  const componentName = 'GenericWithdraw';
  const tileType = 'generic-withdraw';
  const aYearAgo = getCurrentDate().minus({ months: 12 });
  const formattedSubType = subType?.toUpperCase();
  const formattedWithdrawnDate = withdrawnDate?.setZone('America/Phoenix');
  const startChat = useChat();

  if (formattedSubType === 'OW' && formattedWithdrawnDate < aYearAgo) {
    return (
      <SingleTile
        parentComponentName={`${parentComponentName}_${componentName}`}
        data-component={componentNameToMyPhxKebabFormat(componentName)}
        tileType={tileType}
        label={primaryProgramName}
        icon="icon-missed-mc"
        title="We've missed you!"
        mainContent="Our records show you haven't attended class in awhile. We'd love to have you back and help you finish what you started. Call to speak to an enrollment representative for assistance. We're here for you."
        phone="(866) 992-8146"
        primaryButtonText="Re-enroll now"
        primaryButtonCallback={() => navigate('https://phoenix.edu/application/admissions')}
        secondaryButtonText="Chat with us"
        secondaryButtonCallback={startChat}
        small={small}
      />
    );
  }
  if (formattedSubType === 'OW' && formattedWithdrawnDate > aYearAgo) {
    return (
      <SingleTile
        parentComponentName={`${parentComponentName}_${componentName}`}
        data-component={componentNameToMyPhxKebabFormat(componentName)}
        tileType={tileType}
        label={primaryProgramName}
        icon="icon-missed-mc"
        title="We've missed you!"
        mainContent="Our records show you haven't attended class in awhile. We'd love to have you back and help you finish what you started. A university representative can help get you back on track."
        phone={formatPhoneNumberWithParentheses(phone)}
        instructor={counselor}
        primaryButtonText="Chat with us"
        primaryButtonCallback={startChat}
        small={small}
      />
    );
  }
  if (formattedSubType === 'UW' && formattedWithdrawnDate < aYearAgo) {
    return (
      <SingleTile
        parentComponentName={`${parentComponentName}_${componentName}`}
        data-component={componentNameToMyPhxKebabFormat(componentName)}
        tileType={tileType}
        label={primaryProgramName}
        icon="icon-missed-mc"
        title="We've missed you!"
        mainContent="Our records show you haven't attended class in awhile. We'd love to have you back and help you finish what you started. Call to speak to an enrollment representative for assistance. We're here for you."
        phone="(866) 992-8146"
        primaryButtonText="Re-enroll now"
        primaryButtonCallback={() => navigate('https://phoenix.edu/application/admissions')}
        secondaryButtonText="Chat with us"
        secondaryButtonCallback={startChat}
        small={small}
      />
    );
  }
  if (formattedSubType === 'UW' && formattedWithdrawnDate > aYearAgo) {
    return (
      <SingleTile
        parentComponentName={`${parentComponentName}_${componentName}`}
        data-component={componentNameToMyPhxKebabFormat(componentName)}
        tileType={tileType}
        label={primaryProgramName}
        icon="icon-missed-mc"
        title="We've missed you!"
        mainContent="Our records show you haven't attended class in awhile. Contact us to schedule your next course. "
        phone={formatPhoneNumberWithParentheses(phone)}
        instructor={counselor}
        primaryButtonText="Chat with us"
        primaryButtonCallback={startChat}
        small={small}
      />
    );
  }
  if (formattedSubType === 'AW') {
    return (
      <SingleTile
        parentComponentName={`${parentComponentName}_${componentName}`}
        data-component={componentNameToMyPhxKebabFormat(componentName)}
        tileType={tileType}
        icon="icon-warning-mc"
        title="You have been withdrawn from the University."
        mainContent="If you have questions or feel this is an error, please contact us immediately for assistance."
        phone={formatPhoneNumberWithParentheses(phone)}
        instructor={counselor}
        small={small}
      />
    );
  }
  return (
    <SingleTile
      parentComponentName={`${parentComponentName}_${componentName}`}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      tileType={tileType}
      label={primaryProgramName}
      icon="icon-missed-mc"
      title="We've missed you!"
      mainContent="Our records show you haven't attended class in awhile. We'd love to have you back and help you finish what you started. A university representative can help get you back on track."
      phone={formatPhoneNumberWithParentheses(phone)}
      instructor={counselor}
      primaryButtonText="Chat with us"
      primaryButtonCallback={startChat}
      secondaryButtonText={`Call us: ${formatPhoneNumberWithParentheses(phone)}`}
      secondaryButtonCallback={() => callPhoneNumber(phone)}
      small={small}
    />
  );
}
