import VultronCard from '@/components/atoms/vultron-card';
import LeftTile, { LeftTileType } from './left-tile';
import RightTile, { RightTileType } from './right-tile';

type PrepareForClassTileType = LeftTileType & RightTileType & { id?: string };

function PrepareForClassTile({
  id,
  // Props for PrepareForClass tile
  showTermInfo,
  termStartDate,
  termEndDate,
  isCBEDA,
  contactFirstName,
  contactLastName,
  contactPhoneNumber,
  contactEmail,
  // Props for ApplicationStatus tile
  programTitle,
  applicationReviewStatus,
  applicationSubmissionStatus,
  postSubmitTasksText,
  postSubmitTasksUrl,
  returnToApplicationUrl,
  returnToApplicationText,
  applicationSubmittedAt,
  hasIncompleteEnrollmentTasks,
  hasCompletedEnrollmentTasks,
  applicationId,
  applicationType,
}: PrepareForClassTileType) {
  const componentName = 'PrepareForClassTile';
  return (
    <VultronCard
      id={`PrepareForClass-${id}`}
      componentName={componentName}
      leftChild={(
        <LeftTile
          showTermInfo={showTermInfo}
          termStartDate={termStartDate}
          termEndDate={termEndDate}
          applicationSubmissionStatus={applicationSubmissionStatus}
          hasIncompleteEnrollmentTasks={hasIncompleteEnrollmentTasks}
          isCBEDA={isCBEDA}
          contactFirstName={contactFirstName}
          contactLastName={contactLastName}
          contactPhoneNumber={contactPhoneNumber}
          contactEmail={contactEmail}
          postSubmitTasksUrl={postSubmitTasksUrl}
          postSubmitTasksText={postSubmitTasksText}
          returnToApplicationUrl={returnToApplicationUrl}
          returnToApplicationText={returnToApplicationText}
        />
      )}
      rightChild={(
        <RightTile
          programTitle={programTitle}
          applicationSubmissionStatus={applicationSubmissionStatus}
          applicationReviewStatus={applicationReviewStatus}
          returnToApplicationUrl={returnToApplicationUrl}
          returnToApplicationText={returnToApplicationText}
          applicationSubmittedAt={applicationSubmittedAt}
          hasIncompleteEnrollmentTasks={hasIncompleteEnrollmentTasks}
          hasCompletedEnrollmentTasks={hasCompletedEnrollmentTasks}
          applicationId={applicationId}
          applicationType={applicationType}
        />
      )}
    />
  );
}

export default PrepareForClassTile;
