import { visuallyHidden } from '@mui/utils';
import { Box, Step, Stepper } from '@mui/material';
import StepsTrackerIcon from './steps-tracker-icon';
import { StyledConnector, StyledLabel } from './StepsTracker.styles';

type StepType = {
  title: string;
  subTitle?: string;
  checked: boolean;
};

type StepsTrackerType = {
  steps: StepType[];
  orientation?: 'vertical' | 'horizontal';
  moveLabelBelow?: boolean;
  lazyActiveStyle?: boolean;
};

function getLastStepCompleted(steps: StepType[]) {
  let lastStepChecked = -1;
  steps.forEach((step, index) => {
    if (step.checked) lastStepChecked = index;
  });

  return lastStepChecked > -1 ? lastStepChecked + 1 : 0;
}

function StepsTracker({
  steps,
  orientation = 'horizontal',
  moveLabelBelow = false,
  lazyActiveStyle = false,
}: StepsTrackerType) {
  const activeStep = getLastStepCompleted(steps);

  return (
    <Stepper
      orientation={orientation}
      activeStep={activeStep}
      alternativeLabel={orientation === 'horizontal' && moveLabelBelow}
      connector={<StyledConnector $lazy={lazyActiveStyle} />}
    >
      {steps?.map((step, index) => (
        <Step key={step.title}>
          <StyledLabel
            StepIconComponent={StepsTrackerIcon}
          >
            <Box sx={visuallyHidden}>
              {`Step ${index + 1} of ${steps.length}: ${step.title} is ${step.checked ? 'complete.' : 'incomplete.'}`}
            </Box>
            <p>
              {step.title}
              {' '}
              {orientation === 'horizontal' && (<br />)}
              <span>
                {step.subTitle}
              </span>
            </p>
          </StyledLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default StepsTracker;
