import { styled } from '@mui/material/styles';
import Icon from '@/components/atoms/icon';
import { Card, CardContent, Typography } from '@mui/material';
import { withTransientProps } from '@/helpers/with-transient-props';
import StandardLink from '@/components/atoms/standard-link';
import StandardButton from '@/components/atoms/standard-button';

export const StyledCard = styled(Card, withTransientProps)<{
  $large?: boolean }>(
  ({ theme, $large }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    padding: theme.spacing(2),
    height: '100%',
    '& .MuiCardContent-root': {
      padding: 0,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: $large ? theme.spacing(27.5) : theme.spacing(44),
    },
  }),
);

export const StyledCardContent = styled(CardContent, withTransientProps)<{
  $large?: boolean
}>(
  ({ theme, $large }) => ({
    marginLeft: $large ? theme.spacing(1.4) : 0,
    marginRight: $large ? theme.spacing(1.5) : 0,
    marginBottom: $large ? theme.spacing(1.5) : 'auto',
  }),
);

export const LinkContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(2.1),
  },
}));

export const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontWeight: 700,
  textTransform: 'uppercase',
  lineHeight: 1,
  marginBottom: theme.spacing(3),
  outlineColor: theme.palette.text.primary,
})) as typeof Typography;

export const DateLink = styled(StandardLink)(({ theme }) => ({
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: theme.spacing(1.6),
  fontWeight: 500,
  color: theme.palette.text.primary,
  borderBottom: `${theme.spacing(0.3)} solid`,
  '&:hover, &:focus': {
    textDecoration: 'none',
    borderColor: theme.palette.primary.main,
  },
  '&:focus': {
    outlineStyle: 'solid',
    outlineColor: theme.palette.text.primary,
    outlineOffset: theme.spacing(0.3),
  },
}));

export const ShowMoreButton = styled(StandardButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: theme.spacing(2),
}));

export const ShowMoreIcon = styled(Icon, withTransientProps)<{
  $showMore?: boolean
}>(
  ({ theme, $showMore }) => ({
    width: theme.spacing(1.5),
    height: $showMore ? theme.spacing(0.2) : theme.spacing(1.5),
    marginRight: theme.spacing(1),
    fill: theme.palette.custom.brandRed,
  }),
);

export const ShowMoreText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));
