import SingleTile from '@/components/molecules/single-tile';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { callPhoneNumber } from '@/myphoenix/utils/phone-functions';
import { navigate } from '@/helpers/window-functions';

type Course = {
  title: string;
  templateCode?: string;
};

type DropProps = {
  parentComponentName?: string;
  courses?: Course[];
  counselor?: string;
  phone?: string;
  small: boolean;
  primaryProgramName: string;
};

export default function DroppedSingleTile({
  parentComponentName,
  courses,
  counselor = '',
  phone = '(800) 366-9699',
  small = false,
  primaryProgramName,
}: DropProps) {
  const componentName = 'DroppedSingleTile';
  const tileType = 'dropped';
  const secondaryButtonLink = '/kb/article/Options-When-a-Class-is-Dropped';

  if (courses?.length === 1) {
    const course = courses[0];
    const courseTitle = course.title;

    return (
      <SingleTile
        parentComponentName={`${parentComponentName}_${componentName}`}
        data-dropped="container"
        data-component={componentNameToMyPhxKebabFormat(componentName)}
        tileType={tileType}
        icon="icon-warning-mc"
        title={`Your class ${courseTitle} has been dropped`}
        mainContent={`${course?.templateCode} ${courseTitle} was dropped.
           Please call our helpful counselors for options on how to reschedule and resume your courses.`}
        phone={phone}
        instructor={counselor || null}
        label={primaryProgramName}
        primaryButtonText="Call for assistance"
        primaryButtonCallback={() => callPhoneNumber(phone)}
        primaryButtonAriaLabel={`Call counselor ${counselor || ''} at ${phone}`}
        secondaryButtonText="More info"
        secondaryButtonCallback={() => navigate(secondaryButtonLink, '_blank')}
        small={small}
      />
    );
  }

  return (
    <SingleTile
      parentComponentName={componentName}
      tileType={tileType}
      icon="icon-warning-mc"
      title="Unfortunately, your classes have been dropped."
      mainContent="We know life can get challenging, but getting back on track doesn’t have to be. Give us a call to reschedule, so you can get back to class and continue as planned."
      phone={phone}
      instructor={counselor || null}
      label={primaryProgramName}
      primaryButtonText="Call for reschedule"
      primaryButtonCallback={() => callPhoneNumber(phone)}
      primaryButtonAriaLabel={`Call counselor ${counselor || ''} at ${phone}`}
      secondaryButtonText="More info"
      secondaryButtonCallback={() => navigate(secondaryButtonLink, '_blank')}
      small={small}
    />
  );
}
