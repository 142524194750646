import { Grid } from '@mui/material';
import Image from 'next/image';
import CapDegree from 'public/images/cap-degree.jpeg';
import ComputerBooks from 'public/images/computer-books.jpeg';
import ComputerInfo from 'public/images/computer-info.jpeg';
import RectangleNotes from 'public/images/rectangle-notes.jpeg';
import { replaceCreditVerbiageIfNeeded } from '@/helpers/string-utils';
import { ContentWrapper, StyledBackground, StyledCard, MessageText, DonutGrid, PercentageText, CreditsText, ProgramText, CompletedText, NameText, DonutContentBox, LogoIcon, ZeroCredits } from './ShareProgramProgress.styles';
import ProgressDonut from './progress-donut';

type ShareProgramProgressProps = {
  name: string,
  creditsAccrued: number,
  totalCredits: number,
  program: string,
  isCBEDA?: boolean,
  desiredImageWidth?: number,
};

function ShareProgramProgress(props: ShareProgramProgressProps) {
  const {
    creditsAccrued,
    totalCredits,
    name,
    program,
    isCBEDA = false,
    desiredImageWidth = 600,
  } = props;
  const percentage = Math.round((creditsAccrued / totalCredits) * 100);

  const hiddenDivScaleFactor = desiredImageWidth / 600;
  const getUnitsOrCredits = replaceCreditVerbiageIfNeeded(isCBEDA, 'Credits');
  const zeroPercentMessage = 'Your journey begins';

  const altTextList = {
    graduationCompleted: 'Graduation diploma, cap and confetti. You did it!',
    rectangleNotes: 'The journey up and toward finishing.',
    computerInfo: 'Things are going on. Calendars, conversations, assignments.',
    computerBooks: 'Opened computer and textbooks.',
  };

  function viewHandler() {
    let message = '';
    let png = null;
    let altText = '';

    if (percentage === 100) {
      message = 'You did it! Congratulations! Time to celebrate your incredible achievement.';
      png = CapDegree;
      altText = altTextList.graduationCompleted;
    } else if (percentage > 75 && percentage < 100) {
      message = "You're almost finished! We're proud of you, you should be too.";
      png = RectangleNotes;
      altText = altTextList.rectangleNotes;
    } else if (percentage === 75) {
      message = "You're working hard and it shows.";
      png = RectangleNotes;
      altText = altTextList.rectangleNotes;
    } else if (percentage > 50 && percentage < 75) {
      message = "Keep going strong! We're proud of you!";
      png = ComputerInfo;
      altText = altTextList.computerInfo;
    } else if (percentage === 50) {
      message = "You're halfway there! You're doing great!";
      png = ComputerInfo;
      altText = altTextList.computerInfo;
    } else if (percentage > 25 && percentage < 50) {
      message = "You're on your way! Keep going!";
      png = ComputerBooks;
      altText = altTextList.computerBooks;
    } else if (percentage === 25) {
      message = "You're making excellent progress. Keep going!";
      png = ComputerBooks;
      altText = altTextList.computerBooks;
    } else if (percentage > 0 && percentage < 25) {
      message = "You're doing great! Keep it up!";
      png = ComputerBooks;
      altText = altTextList.computerBooks;
    } else if (percentage === 0) {
      message = "Welcome to UOPX. You're a Phoenix now!";
      png = ComputerBooks;
      altText = altTextList.computerBooks;
    }
    return {
      Message: message,
      Png: png,
      AltText: altText,
    };
  }

  const { Message, Png, AltText } = viewHandler();

  const getDescription = () => {
    let description = `${name}'s program progress at the University of Phoenix. ${Message}`;
    if (percentage) {
      description += ` ${percentage}%. ${creditsAccrued} out of ${totalCredits} ${getUnitsOrCredits.toLowerCase()} completed in ${program}.`;
    } else {
      description += ` ${zeroPercentMessage} in ${program}.`;
    }
    description += ` Image showing ${AltText} Image showing University of Phoenix logo.`;
    return description;
  };

  return (
    <ContentWrapper>
      <StyledBackground>
        <StyledCard $hiddenDivScaleFactor={hiddenDivScaleFactor} aria-label={getDescription()}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <MessageText $hiddenDivScaleFactor={hiddenDivScaleFactor} variant="body4">{Message}</MessageText>
            </Grid>
            <DonutGrid item $hiddenDivScaleFactor={hiddenDivScaleFactor}>
              <ProgressDonut
                progressConfig={{ accrued: creditsAccrued, total: totalCredits }}
              >
                <DonutContentBox>
                  {percentage ? (
                    <>
                      <PercentageText
                        variant="body4"
                        $hiddenDivScaleFactor={hiddenDivScaleFactor}
                        $completed={percentage === 100}
                      >
                        {percentage}
                        %
                      </PercentageText>
                      <CreditsText variant="body4" $hiddenDivScaleFactor={hiddenDivScaleFactor}>
                        {creditsAccrued}
                        {' '}
                        of
                        {' '}
                        {totalCredits}
                      </CreditsText>
                      <CompletedText variant="body4" $hiddenDivScaleFactor={hiddenDivScaleFactor}>
                        {`${getUnitsOrCredits.toLowerCase()} completed`}
                      </CompletedText>
                    </>
                  ) : (
                    <ZeroCredits $hiddenDivScaleFactor={hiddenDivScaleFactor}>
                      {zeroPercentMessage}
                    </ZeroCredits>
                  )}
                </DonutContentBox>
              </ProgressDonut>
            </DonutGrid>
            <Grid item>
              <NameText variant="body4" $hiddenDivScaleFactor={hiddenDivScaleFactor}>{name}</NameText>
              <ProgramText variant="body4" $hiddenDivScaleFactor={hiddenDivScaleFactor}>{program}</ProgramText>
            </Grid>
            <Grid item>
              <Image
                alt={AltText}
                src={Png}
                width={hiddenDivScaleFactor * 319}
                height={hiddenDivScaleFactor * 143}
              />
            </Grid>
            <Grid item>
              <LogoIcon icon="icon-public-logo" id="university of phoenix" $hiddenDivScaleFactor={hiddenDivScaleFactor} />
            </Grid>
          </Grid>
        </StyledCard>
      </StyledBackground>
    </ContentWrapper>
  );
}

export default ShareProgramProgress;
