import Icon from '@/components/atoms/icon';
import theme from '@/helpers/theme';
import {
  DivDataInfo,
  DivInnerDataContainer,
  TypographyNumerator,
  CircleChunk,
  CircleRing,
  TypographyPercentageSeparator,
  TypographyAttendanceSeparator,
  TypographyCreditBreakdown,
  TypographyCBEMessaging,
  SpanCreditsNewLine,
  EngagementCircle,
  DivRoot,
  CBEDAEngagementCircle,
  NumeratorSmall,
} from './Donut.styles';

type DonutProps = {
  countdownConfig?: {
    total: number,
    remaining: number,
    creditsCompleted: number,
    creditsPossible: number,
    isCbeDa: boolean,
  },
  cbeDaEngagementConfig?: {
    remaining: number;
    postedToday: boolean;
  },
  engagementConfig?: {
    remaining: number;
    postedToday: boolean;
  },
  attendanceCountDownConfig?: {
    recentPost: string;
    remaining: number;
    total: number;
  },
};

function Donut({
  countdownConfig,
  cbeDaEngagementConfig,
  attendanceCountDownConfig,
  engagementConfig,
}: DonutProps) {
  const donutWidth = attendanceCountDownConfig ? 3 : 2;

  const donutRadius = 16;
  const donutDiameter = donutRadius * 2 + donutWidth;
  let numeratorColor: string = theme.palette.secondary.main;

  function getEngagementColor(days: number) {
    if (days >= 1 && days <= 3) {
      return theme.palette.donut.dangerRed;
    }
    if (days >= 4 && days <= 7) {
      return theme.palette.donut.burntOrange;
    }
    if (days >= 8 && days <= 10) {
      return theme.palette.donut.lightOrange;
    }
    return theme.palette.donut.green;
  }

  if (engagementConfig) {
    numeratorColor = getEngagementColor(engagementConfig.remaining);
  }

  function getCbeDaEngagementColor(days: number) {
    if (days >= 1 && days <= 5) {
      return theme.palette.donut.phoenixRed;
    } if (days >= 6 && days <= 10) {
      return theme.palette.donut.sunriseOrange;
    }
    return theme.palette.donut.systemGreen;
  }

  const getAttendanceDonutColor = (days: number) => {
    if (attendanceCountDownConfig.recentPost === 'none') {
      if (days === 4 || days === 3) {
        return theme.palette.donut.brightOrange;
      }
      if (days <= 2) {
        return theme.palette.donut.dangerRed;
      }
    }
    return theme.palette.donut.systemGreen;
  };

  if (cbeDaEngagementConfig) {
    numeratorColor = getCbeDaEngagementColor(cbeDaEngagementConfig.remaining);
  }

  const buildDonutChunks = (buildChunksConfig: {
    total: number;
    remaining: number;
  }) => {
    let currentLength = 0;
    if (buildChunksConfig) {
      const circles = [];
      const chunkPercentage = (1 / buildChunksConfig.total) * 100;
      for (let i = 0; i < buildChunksConfig.total; i += 1) {
        currentLength += i !== 0 ? chunkPercentage : 0;
        circles.push(
          <CircleChunk
            $isDark={!(buildChunksConfig.total - 1 - i >= buildChunksConfig.remaining)}
            key={`countdown-chunk-${i}`}
            cx={donutDiameter / 2}
            cy={donutDiameter / 2}
            r={donutRadius}
            strokeWidth={donutWidth}
            strokeDasharray={`${Math.max(0, chunkPercentage - 0.25)} ${
              100 - chunkPercentage + 0.25
            }`}
            strokeDashoffset={
              i !== 0 ? 100 - currentLength + (24 + 0.25) : 24 + 0.25
            }
            data-testid="donut-chunk"
          />,
        );
      }
      return circles;
    }
    if (engagementConfig) {
      const circles = [];
      const chunkPercentage = 7.142857143;
      for (let i = 0; i < 14; i += 1) {
        currentLength += i !== 0 ? chunkPercentage : 0;
        circles.push(
          <EngagementCircle
            $remainingColor={14 - i <= engagementConfig.remaining
              ? getEngagementColor(engagementConfig.remaining)
              : theme.palette.donut.grey}
            key={`countdown-chunk-${i}`}
            cx={donutDiameter / 2}
            cy={donutDiameter / 2}
            r={donutRadius}
            strokeWidth={donutWidth}
            strokeDasharray={`${Math.max(0, chunkPercentage + 0.25)} ${
              100 - chunkPercentage - 0.25
            }`}
            strokeDashoffset={i !== 0 ? 100 + currentLength + 32.25 : 32.25}
            data-testid="donut-chunk"
          />,
        );
      }
      return circles;
    }
    if (attendanceCountDownConfig) {
      const circles = [];

      const chunkPercentage = (1 / attendanceCountDownConfig.total) * 100;
      let { remaining } = attendanceCountDownConfig;
      if (attendanceCountDownConfig.recentPost !== 'none') {
        remaining = 0;
      }
      for (let i = 0; i < attendanceCountDownConfig.total; i += 1) {
        currentLength += i !== 0 ? chunkPercentage : 0;
        circles.push(
          <CircleChunk
            $isDark={!(attendanceCountDownConfig.total - 1 - i >= remaining)}
            fillcolor={getAttendanceDonutColor(attendanceCountDownConfig.remaining)}
            key={`countdown-chunk-${i}`}
            cx={donutDiameter / 2}
            cy={donutDiameter / 2}
            r={donutRadius}
            strokeWidth={donutWidth}
            strokeDasharray={`${Math.max(0, chunkPercentage - 0.25)} ${
              100 - chunkPercentage + 0.25
            }`}
            strokeDashoffset={
            i !== 0 ? 100 - currentLength + (24 + 0.25) : 24 + 0.25
          }
            data-testid="donut-chunk"
          />,
        );
      }
      return circles;
    }

    if (cbeDaEngagementConfig) {
      const circles = [];
      const chunkPercentage = 7.142857143;
      for (let i = 0; i < 14; i += 1) {
        currentLength += i !== 0 ? chunkPercentage : 0;
        circles.push(
          <CBEDAEngagementCircle
            $numeratorColor={14 - i <= cbeDaEngagementConfig.remaining
              ? getCbeDaEngagementColor(cbeDaEngagementConfig.remaining)
              : theme.palette.almostBlack.l70}
            cx={donutDiameter / 2}
            cy={donutDiameter / 2}
            r={donutRadius}
            strokeWidth={donutWidth}
            strokeDasharray={`${Math.max(0, chunkPercentage + 0.25)} ${
              100 - chunkPercentage - 0.25
            }`}
            strokeDashoffset={i !== 0 ? 100 + currentLength + 32.25 : 32.25}
            data-testid="donut-chunk"
            key={`countdown-chunk-${i}`}
          />,
        );
      }
      return circles;
    }
    return null;
  };

  const renderInnerContent = () => {
    if (countdownConfig) {
      const { isCbeDa } = countdownConfig;
      return (
        <DivDataInfo
          aria-hidden="false"
          role="img"
          aria-labelledby="coursesRemainingCountdown coursesRemainingTextCountdown"
        >
          <DivInnerDataContainer>
            <TypographyNumerator color={numeratorColor} id="coursesRemainingCountdown">
              {countdownConfig.remaining}
            </TypographyNumerator>
            <TypographyPercentageSeparator variant="body4" id="coursesRemainingTextCountdown">
              {countdownConfig.remaining !== 1 ? 'courses left' : 'course left'}
            </TypographyPercentageSeparator>
            {countdownConfig.creditsCompleted !== undefined
                  && countdownConfig.creditsPossible !== undefined && (
                    <TypographyCreditBreakdown
                      aria-label={`${countdownConfig.creditsCompleted} out of ${countdownConfig.creditsPossible} ${isCbeDa ? 'competency units' : 'credits'} completed.`}
                      variant="body4"
                    >
                      {countdownConfig.creditsCompleted}
                      /
                      {countdownConfig.creditsPossible}
                      {isCbeDa ? (
                        <>
                          <SpanCreditsNewLine>competency</SpanCreditsNewLine>
                          <SpanCreditsNewLine>units</SpanCreditsNewLine>
                        </>
                      ) : (
                        <SpanCreditsNewLine>credits</SpanCreditsNewLine>
                      )}
                    </TypographyCreditBreakdown>
            )}
          </DivInnerDataContainer>
        </DivDataInfo>
      );
    }

    if (engagementConfig) {
      return (
        <DivDataInfo
          aria-hidden="false"
          role="img"
          aria-labelledby="daysRemainingEngagement daysRemainingTextEngagement"
        >
          <DivInnerDataContainer>
            <TypographyNumerator $numeratorColor={numeratorColor} id="daysRemainingEngagement">
              {engagementConfig.postedToday ? (
                <Icon id="icon-checkmark" data-testid="checkmark" color="#388164" icon="icon-checkmark" />
              ) : (
                engagementConfig.remaining
              )}
            </TypographyNumerator>
            <TypographyCBEMessaging variant="body5" id="daysRemainingTextEngagement">
              {engagementConfig.postedToday ? 'You posted today!' : null}
              {!engagementConfig.postedToday && engagementConfig.remaining > 1
                ? 'days left to post in any class'
                : null}
              {!engagementConfig.postedToday && engagementConfig.remaining === 1
                ? 'day left to post in any class'
                : null}
            </TypographyCBEMessaging>
          </DivInnerDataContainer>
        </DivDataInfo>
      );
    }
    if (cbeDaEngagementConfig) {
      return (
        <DivDataInfo
          aria-hidden="false"
          role="img"
          aria-labelledby="daysRemainingEngagement daysRemainingTextEngagement"
        >
          <DivInnerDataContainer>
            <TypographyNumerator
              $numeratorColor={numeratorColor}
              id="daysRemainingEngagement"
              variant="body1"
            >
              {cbeDaEngagementConfig.postedToday
                ? <Icon id="icon-checkmark" icon="icon-checkmark" data-testid="checkmark" color="#008A68" /> : (
                  cbeDaEngagementConfig.remaining
                )}
            </TypographyNumerator>
            <TypographyCBEMessaging variant="body5" id="daysRemainingTextEngagement">
              {cbeDaEngagementConfig.postedToday && 'You posted today!'}
              {!cbeDaEngagementConfig.postedToday && cbeDaEngagementConfig.remaining > 1
                && 'days left to post in a scheduled course'}
              {!cbeDaEngagementConfig.postedToday && cbeDaEngagementConfig.remaining === 1
                && 'day left to post in a scheduled course'}
            </TypographyCBEMessaging>
          </DivInnerDataContainer>
        </DivDataInfo>
      );
    }
    if (attendanceCountDownConfig) {
      const {
        recentPost, remaining,
      } = attendanceCountDownConfig;
      const completed = recentPost !== 'none';
      return (
        <DivDataInfo
          aria-hidden="false"
          role="img"
          aria-labelledby="coursesRemainingCountdown coursesRemainingTextCountdown"
        >
          <DivInnerDataContainer>
            {completed ? (
              <Icon id="icon-checkmark" icon="icon-checkmark" data-testid="checkmark" color="#008A68" />
            ) : (
              <>
                <NumeratorSmall variant="body4" id="coursesRemainingCountdown">
                  {remaining}
                </NumeratorSmall>
                <TypographyAttendanceSeparator variant="body5" id="coursesRemainingTextCountdown">
                  {remaining !== 1 ? 'days' : 'day'}
                </TypographyAttendanceSeparator>
              </>
            )}
          </DivInnerDataContainer>
        </DivDataInfo>
      );
    }
    return null;
  };

  return (
    <DivRoot>
      <svg viewBox={`0 0 ${donutDiameter} ${donutDiameter}`} aria-hidden="true">
        <CircleRing
          cx={donutDiameter / 2}
          cy={donutDiameter / 2}
          r={donutRadius}
          strokeWidth={donutWidth}
        />
        {buildDonutChunks(countdownConfig)}
      </svg>
      {renderInnerContent()}
    </DivRoot>
  );
}
export default Donut;
