import { Loading, SpinnerWrapper, StyledCard } from '@/micro-frontends/careers/step-card/StepCard.styles';
import { Fade } from '@mui/material';

function LoadingComponent() {
  return (
    <StyledCard>
      <SpinnerWrapper data-testid="step-card-loading">
        <Fade in timeout={500} style={{ transitionDelay: '500ms' }}>
          <Loading aria-label="careers step card loading" />
        </Fade>
      </SpinnerWrapper>
    </StyledCard>
  );
}

export default LoadingComponent;
