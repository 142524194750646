import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@/components/atoms/icon';
import StandardLink from '@/components/atoms/standard-link';

export const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: `0 0 ${theme.spacing(1.6)} ${theme.spacing(-0.2)} rgb(0 0 0 / 20%)`,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  textAlign: 'center',
  height: '100%',
  ':hover': {
    backgroundColor: theme.palette.almostBlack.l90,
  },
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1.9),
    height: theme.spacing(5.5),
    width: theme.spacing(5.5),
    marginRight: theme.spacing(0),
  },
  marginRight: theme.spacing(2.3),
  height: theme.spacing(4),
  width: theme.spacing(4),
}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(4.1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    ':focus': {
      border: `${theme.spacing(0.2)} solid ${theme.palette.common.black}`,
      paddingTop: theme.spacing(2.3),
      paddingBottom: theme.spacing(3.9),
      paddingLeft: theme.spacing(3.8),
      paddingRight: theme.spacing(3.8),
      textDecoration: 'underline',
      textDecorationColor: theme.palette.custom.brandRed,
    },
  },
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: `${theme.spacing(2.4)} ${theme.spacing(3.2)}`,
  fontWeight: 'inherit',
  color: 'inherit',
  textDecoration: 'none',
  height: '100%',
  ':focus': {
    border: `${theme.spacing(0.2)} solid ${theme.palette.common.black}`,
    padding: `${theme.spacing(2.2)} ${theme.spacing(3)}`,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.custom.brandRed,
  },
}));
