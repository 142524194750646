import Icon from '@/components/atoms/icon';
import {
  Grid, Box, Typography,
} from '@mui/material';
import IconItem from '@/components/molecules/icon-item';
import { formatPhoneNumberWithParentheses } from '@/myphoenix/utils/phone-functions';
import { useChat } from '@/hooks/useChat';
import ProgramContacts from '@/components/organisms/program-contacts';
import {
  CardBody, Heading, SubHeading, TermHeading, ContactHeading, ContactBox,
  Button, Divider, StyledLink,
} from './PrepareForClass.styles';

type CardProps = {
  applicationCompleted: boolean,
  headingText: JSX.Element,
  subheadingText: string,
  body: JSX.Element,
  hasTasks: boolean,
  primaryButtonText: string,
  primaryButtonUrl: string,
};

export type PrepareForClassTileType = {
  isCBEDA?: boolean,
  termStartDate?: string,
  termEndDate?: string,
  contactFirstName?: string,
  contactLastName?: string,
  contactPhoneNumber?: string,
  contactEmail?: string,
  showTermInfo?: boolean,
  applicationSubmissionStatus: string,
  postSubmitTasksUrl?: string,
  postSubmitTasksText?: string,
  returnToApplicationUrl?: string,
  returnToApplicationText?: string,
  hasIncompleteEnrollmentTasks?: boolean,
};

function Card({ applicationCompleted,
  hasTasks,
  headingText,
  subheadingText,
  primaryButtonText,
  primaryButtonUrl,
  body }: CardProps) {
  if (!applicationCompleted) {
    return (
      <Box px={1}>
        <Icon
          icon="icon-congratulations-mc"
          id="icon-congratulations-mc"
        />
        <SubHeading
          variant="body4"
          $small
          py={2}
          display="inline-block"
        >
          We&apos;re glad you&apos;re coming back
        </SubHeading>
        <CardBody component="p" variant="body4">
          Your application is almost complete.
          Let&apos;s finish now so you can keep moving forward!
        </CardBody>
        <Button variant="contained" href={primaryButtonUrl}>
          {primaryButtonText}
        </Button>
        <div>
          <Divider orientation="horizontal" aria-hidden />
          <ProgramContacts
            isReEnroll
            isAlumni
          />
        </div>
      </Box>
    );
  }

  return (
    <div>
      <TermHeading>{headingText}</TermHeading>
      <SubHeading variant="body4">{subheadingText}</SubHeading>
      <CardBody component="p" pt={1} variant="body4">
        {applicationCompleted && hasTasks
          ? 'Let\'s keep moving forward - complete your tasks so you can get scheduled for class. Reach out to your enrollment representative for support when you need it.'
          : 'Your enrollment representative will reach out to you to schedule your first class - contact us if you need support sooner.'}
      </CardBody>
      <div>{body}</div>
    </div>
  );
}

function PrepareForClassTile({
  showTermInfo,
  applicationSubmissionStatus,
  termStartDate,
  termEndDate,
  isCBEDA = false,
  contactFirstName,
  contactLastName,
  contactPhoneNumber = '602-387-6372',
  contactEmail,
  postSubmitTasksText,
  postSubmitTasksUrl,
  returnToApplicationText,
  returnToApplicationUrl,
  hasIncompleteEnrollmentTasks = false,
}: PrepareForClassTileType) {
  const hasTasks = hasIncompleteEnrollmentTasks;
  const applicationCompleted = applicationSubmissionStatus === 'COMPLETED';
  const primaryButtonText = applicationCompleted ? postSubmitTasksText : returnToApplicationText;
  const primaryButtonUrl = applicationCompleted ? postSubmitTasksUrl : returnToApplicationUrl;
  const headingText = () => {
    if (showTermInfo) {
      return (
        <>
          <Heading variant="body4">Prepare for your term</Heading>
          {termStartDate && termEndDate && (
            <Heading variant="body4">{`${termStartDate} - ${termEndDate}`}</Heading>
          )}
        </>
      );
    }
    if (applicationCompleted) {
      return (<Heading variant="body4">Prepare for class</Heading>);
    }
    return null;
  };

  const subheadingText = () => {
    if (applicationCompleted) {
      if (hasTasks) {
        return 'You have tasks to complete';
      }
      return isCBEDA ? 'You\'re not currently enrolled in class' : 'We\'re getting your schedule ready.';
    }
    return 'We\'re glad you\'re coming back';
  };

  const startChat = useChat();
  const body = () => {
    const hasContact = contactFirstName && contactLastName && contactPhoneNumber && contactEmail;

    if (applicationCompleted && !hasTasks && hasContact) {
      return (
        <ContactBox>
          <Grid container alignItems="center">
            <Grid item xs={12} pb={1}>
              <ContactHeading variant="body4">
                {contactFirstName}
                {' '}
                {contactLastName}
              </ContactHeading>
            </Grid>
            <Grid item xs={12} sm={5}>
              <IconItem
                text={`Email ${contactFirstName}`}
                icon="icon-email-mc"
                url={`mailto:${contactEmail}`}
                id={`sr_rep_email_ac_${contactFirstName}_${contactLastName}`}
                type="link"
              />
            </Grid>
            <Grid item xs={12} sm={5} ml={{ xs: 0, sm: 1 }}>
              <IconItem
                text={formatPhoneNumberWithParentheses(contactPhoneNumber)}
                icon="icon-support-mc"
                url={`tel:${contactPhoneNumber}`}
                id={`sr_rep_phone_ac_${contactFirstName}_${contactLastName}`}
                type="link"
              />
            </Grid>
          </Grid>
          <Box pt={{ xs: 5, sm: 8 }} pr={{ xs: 0, sm: 1 }}>
            <Button variant="contained" $chat onClick={() => startChat()}>Chat with us</Button>
          </Box>
        </ContactBox>
      );
    }
    if (hasTasks) {
      return (
        <div>
          <Button variant="contained" href={primaryButtonUrl}>
            {primaryButtonText}
          </Button>

          <Divider orientation="horizontal" />
          <ProgramContacts
            isReEnroll
            isAlumni
          />
        </div>
      );
    }
    return (
      <ContactBox>
        <Typography mb={1.2} fontWeight={500} variant="body4" display="block">Contact Us</Typography>
        <StyledLink
          href={`tel:${contactPhoneNumber}`}
          underline="none"
        >
          {formatPhoneNumberWithParentheses(contactPhoneNumber)}
        </StyledLink>
        <Box pt={{ xs: 5, sm: 8 }} pr={{ xs: 0, sm: 1 }}>
          <Button variant="contained" $chat onClick={() => startChat()}>Chat with us</Button>
        </Box>
      </ContactBox>
    );
  };

  return (
    <Card
      applicationCompleted={applicationCompleted}
      hasTasks={hasTasks}
      headingText={headingText()}
      subheadingText={subheadingText()}
      body={body()}
      primaryButtonText={primaryButtonText}
      primaryButtonUrl={primaryButtonUrl}
    />
  );
}

export default PrepareForClassTile;
