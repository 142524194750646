import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@/components/atoms/icon';
import { v4 as uuidv4 } from 'uuid';
import { withTransientProps } from '@/helpers/with-transient-props';

type StepsTrackerIconType = {
  active?: boolean;
  completed?: boolean;
};

const IconCircle = styled(
  Icon,
  withTransientProps,
)<{ $active: boolean }>(({ theme, $active = false }) => ({
  fill: $active ? theme.palette.primary.main : theme.palette.almostBlack.l30,
  width: theme.spacing(1.9),
  height: theme.spacing(1.9),
  margin: theme.spacing(-0.1),
}));

const IconCheck = styled(
  Icon,
)(({ theme }) => ({
  fill: theme.palette.primary.main,
  width: theme.spacing(1.6),
  height: theme.spacing(1.6),
}));

function StepsTrackerIcon({ active, completed }: StepsTrackerIconType) {
  const id = uuidv4();

  return (
    <Box id={`step-icon-${id}`} sx={{ display: 'flex' }}>
      {completed ? (
        <IconCheck
          id={`step-icon-circle-${id}`}
          icon="icon-circle-check"
        />
      ) : (
        <IconCircle
          id={`step-icon-circle-${id}`}
          icon="icon-circle"
          $active={active}
        />
      )}
    </Box>
  );
}

export default StepsTrackerIcon;
