import ToggleTooltip from '@/components/atoms/tooltip';
import DonutChart from '@/components/molecules/donut-chart';
import ProgramProgressGPA from '@/components/molecules/program-progress-gpa';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { useGetProgramProgressDataByProgramIdQuery } from '@/store/queries/programs';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useMediaQuery, useTheme, CircularProgress, Grid } from '@mui/material';
import ErrorMessage from '@/components/atoms/error-message';
import { useGetOfficialNameQuery } from '@/store/queries/person';
import { componentNameToMyPhxKebabFormat, replaceCreditVerbiageIfNeeded } from '@/helpers/string-utils';
import config from '@/helpers/config';
import {
  StyledCard,
  StyledHeading,
  HeadingWrapper,
  StyledGrid,
  StyledDivider,
  DonutGrid,
  GpaGrid,
  LoadingGrid,
} from './ProgramProgress.styles';

type ProgramProgressProps = {
  studentProgramId: string,
  showGradOnly?: boolean,
};
function ProgramProgress(props: ProgramProgressProps) {
  const { studentProgramId, showGradOnly = false } = props;
  const { breakpoints } = useTheme();
  const isColumn = useMediaQuery(breakpoints.down('md'));
  const programToolTip = 'Program Credits shows your progress in this program. You can see how many credits are applied, scheduled, and need to be scheduled for this program';
  const personId = getPersonIdFromSessionStorage();
  const componentName = 'ProgramProgress';
  const { ecampus, multimedia, Portal } = config;

  const { data: personNames } = useGetOfficialNameQuery({ personId }, { skip: !personId });
  const firstName = personNames?.firstName || '';
  const lastName = personNames?.lastName || '';

  const {
    data: { programsList } = {},
    isFetching: isProgramLoading,
    isError: isProgramError,
  } = useGetProgramsByIdHook(personId);
  const studentProgram = programsList?.byId[studentProgramId];

  const {
    data: { creditsSummary, isDegreeAudit } = {},
    isLoading: isProgressLoading,
    isError: isProgressError,
  } = useGetProgramProgressDataByProgramIdQuery({
    programId: studentProgramId,
  }, { skip: !studentProgramId });

  const donutDataDegree = [
    { label: 'Applied', value: creditsSummary?.applied, color: 'navyTeal' },
    { label: 'Scheduled', value: creditsSummary?.scheduled, color: 'babyMelonGreen' },
    { label: 'Not Scheduled', value: creditsSummary?.notScheduled, color: 'borderGray' },
  ];

  const donutDataProgram = [
    { label: 'Completed', value: studentProgram?.creditsCompleted, color: 'navyTeal' },
    { label: 'Remaining', value: studentProgram?.creditsRemaining, color: 'borderGray' },
  ];

  const isCBEDA = studentProgram?.cbeDaProgram === 'TRUE';
  const isCBE = studentProgram?.cbeProgram === 'TRUE';

  const isPrimaryProgramDegreeAndCompletedLessThenRequired = !isCBEDA && !isCBE
      && creditsSummary?.completed > 0
      && creditsSummary?.completed < creditsSummary?.required
      && studentProgram?.type === 'Degree';

  const showCalculateLink = isPrimaryProgramDegreeAndCompletedLessThenRequired || showGradOnly;
  const transcriptsLink = `${ecampus.baseUrl}/Portal/Portal/Public/Login.aspx?ReturnUrl=${encodeURIComponent(`${Portal}/te/transcripts/index.html`)}`;

  const calculateLabel = showGradOnly ? 'Order transcripts' : 'Estimate your future GPA';
  const calculateLink = showGradOnly ? transcriptsLink : `${multimedia.baseUrl}cms/gpa-estimator`;

  // NOTE: The showNonDegreeMessage present in the AEM implementation has been removed since it
  // is not needed anymore. Since it is defaulted to false, only the logic for this scenario has
  // been ported over.

  return (
    <StyledCard
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      data-testid="home_program_progress"
      id="programprogress"
    >
      <HeadingWrapper>
        <StyledHeading variant="body5">
          {replaceCreditVerbiageIfNeeded(isCBEDA, 'Program Credits', true)}
        </StyledHeading>
        <ToggleTooltip
          tooltipMessage={replaceCreditVerbiageIfNeeded(isCBEDA, programToolTip, true)}
          placement="top"
        />
      </HeadingWrapper>
      <StyledGrid
        container
        direction={isColumn ? 'column' : 'row'}
        wrap="nowrap"
        justifyContent={isCBEDA ? 'center' : 'flex-start'}
      >
        {(isProgramLoading || isProgressLoading) && (
          <LoadingGrid item xs={12}>
            <CircularProgress size={150} />
          </LoadingGrid>
        )}
        {(isProgramError || isProgressError) && (
          <Grid item>
            <ErrorMessage errorComponent="ProgramProgress" />
          </Grid>
        )}
        {!isProgramLoading && !isProgramError && !isProgressLoading && !isProgressError && (
          <>
            <DonutGrid item>
              <DonutChart
                data={isDegreeAudit ? donutDataDegree : donutDataProgram}
                showNumericalValuesInLegend
                shareProgress
                isCBEDA={isCBEDA}
                downloadFileName="programprogress"
                programName={studentProgram?.description}
                firstName={firstName}
                lastName={lastName}
              />
            </DonutGrid>
            {!isCBEDA && (
            <>
              <StyledDivider orientation={isColumn ? 'horizontal' : 'vertical'} flexItem />
              <GpaGrid item>
                <ProgramProgressGPA
                  calculateLabel={calculateLabel}
                  calculateLink={calculateLink}
                  showCalculateLink={showCalculateLink}
                  gpa={studentProgram?.programGpa}
                  heading="Your current GPA"
                />
              </GpaGrid>
            </>
            )}
          </>
        )}
      </StyledGrid>
    </StyledCard>
  );
}

export default ProgramProgress;
