import { useMemo } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { getCurrentDate, toDateTime } from '@/myphoenix/utils/date-time-functions';
import { getItem, setItem } from '@/helpers/session-storage';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import usePrefersReducedMotion from '@/hooks/usePrefersReducedMotion';
import {
  useGetOfficialNameQuery,
  useGetDemographicsByPersonIdQuery,
  useGetMilitaryStatusQuery,
} from '@/store/queries/person';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { useGetProgramProgressDataByProgramIdQuery } from '@/store/queries/programs';
import Confetti from '@/components/atoms/confetti';
import Emoji from '@/components/atoms/emoji';
import { componentNameToMyPhxKebabFormat, resolveExternalConfigs } from '@/helpers/string-utils';
import {
  Blank,
  GreetingContainer,
  LoadingBarContainer,
} from '@/components/molecules/greeting/Greeting.styles';
import { sortMemberships } from '@/helpers/filter-courses';
import useGetMembershipsAndCourses from '@/hooks/useGetMembershipsAndCourses';

enum ConfettiStorageKeys {
  Birthday = 'myphoenix-confetti-played-birthday',
  VeteransDay = 'myphoenix-confetti-played-veteransday',
  Program = 'myphoenix-confetti-played',
}

export const componentName = 'Greeting';

function GreetingUi({ children, testId }: { children: any, testId?: string }) {
  return (
    <GreetingContainer
      component="h1"
      variant="body4"
      data-testid={testId}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
    >
      { children }
    </GreetingContainer>
  );
}

type GreetingProps = {
  morningEmoji?: string;
  morningEmojiLabel?: string;
  morningGreeting?: string;
  afternoonEmoji?: string;
  afternoonEmojiLabel?: string;
  afternoonGreeting?: string;
  eveningEmoji?: string;
  eveningEmojiLabel?: string;
  eveningGreeting?: string;
  // Countdown personalizations
  oneClassGreeting?: string;
  twoClassGreeting?: string;
  threeClassesGreeting?: string;
  fourClassesGreeting?: string;
  fiveClassesGreeting?: string;
  sixClassesGreeting?: string;
  sevenClassesGreeting?: string;
  eightClassesGreeting?: string;
  pendingFinalGradeGreeting?: string;
  alumniGreeting?: string;
  // Birthday Personalization
  birthdayGreeting?: string;
  birthdayEmoji?: string;
  birthdayEmojiLabel?: string;
  // Veteran Personalization
  veteransDayGreeting?: string;
  // Emoji
  oneClassEmoji?: string;
  oneClassLabel?: string;
  twoClassEmoji?: string;
  twoClassLabel?: string;
  threeClassesEmoji?: string;
  threeClassesLabel?: string;
  fourClassesEmoji?: string;
  fourClassesLabel?: string;
  fiveClassesEmoji?: string;
  fiveClassesLabel?: string;
  sixClassesEmoji?: string;
  sixClassesLabel?: string;
  sevenClassesEmoji?: string;
  sevenClassesLabel?: string;
  eightClassesEmoji?: string;
  eightClassesLabel?: string;
  alumniEmoji?: string;
  alumniLabel?: string;
  veteranEmoji?: string;
  veteranEmojiLabel?: string;
};

function Greeting({
  // Time based personalizations
  morningEmoji = 'SUN_BEHIND_CLOUD',
  morningEmojiLabel = 'sun behind clouds, emoji',
  morningGreeting = 'Good Morning, #{0}.',
  afternoonEmoji = 'SUNNY',
  afternoonEmojiLabel = 'sun emoji',
  afternoonGreeting = 'Good Afternoon, #{0}.',
  eveningEmoji = 'CRESENT_MOON',
  eveningEmojiLabel = 'crescent moon emoji',
  eveningGreeting = 'Good Evening, #{0}.',
  // Countdown personalizations
  oneClassGreeting = "We're proud of you, #{0} ",
  twoClassGreeting = 'Keep going strong, #{0}! ',
  threeClassesGreeting = 'You can do it; all your work will pay off ',
  fourClassesGreeting = "#{0}, you're making progress every day! ",
  fiveClassesGreeting = "#{0}, keep going, you've got this! ",
  sixClassesGreeting = 'You can do it, #{0}! ',
  sevenClassesGreeting = '#{0}, all your effort is worth it! ',
  eightClassesGreeting = "#{0}, you're working hard – keep it up! ",
  pendingFinalGradeGreeting = "You're nearly finished #{0}.",
  alumniGreeting = 'Congratulations, #{0}!',
  // Birthday Personalization
  birthdayGreeting = 'Happy Birthday, #{0}!',
  birthdayEmoji = 'BIRTHDAY_CAKE',
  birthdayEmojiLabel = 'birthday cake',
  // Veteran Personlization
  veteransDayGreeting = '#{0}, thank you for your service! ',
  // Emoji
  oneClassEmoji = 'GRINNING_FACE',
  oneClassLabel = 'beaming face with smiling eyes, emoji',
  twoClassEmoji = 'MUSCLE',
  twoClassLabel = 'flexed biceps, emoji',
  threeClassesEmoji = 'THUMBS_UP',
  threeClassesLabel = 'thumbs up, emoji',
  fourClassesEmoji = 'CHART_WITH_UPWARD_TREND',
  fourClassesLabel = 'increasing, emoji',
  fiveClassesEmoji = 'ATHETIC_SHOE',
  fiveClassesLabel = 'athletic shoe, emoji',
  sixClassesEmoji = 'GOLD_STAR',
  sixClassesLabel = 'glowing star, emoji',
  sevenClassesEmoji = 'GOLD_MEDAL',
  sevenClassesLabel = 'medal, emoji',
  eightClassesEmoji = 'CLAPPING_HANDS',
  eightClassesLabel = 'clapping hands, emoji',
  alumniEmoji = 'MORTAR_BOARD',
  alumniLabel = 'graduation cap, emoji',
  veteranEmoji = 'AMERICAN_FLAG',
  veteranEmojiLabel = 'american flag, emoji',
}: GreetingProps) {
  const personId = getPersonIdFromSessionStorage();
  const {
    memberships,
    courses,
    isFetching: statusCourses,
  } = useGetMembershipsAndCourses(personId);
  const { data: { firstName, preferredName } = {} } = useGetOfficialNameQuery({
    personId,
  }, { skip: !personId });
  const {
    data: { serviceType } = {}, isLoading: statusServiceType,
  } = useGetMilitaryStatusQuery({ personId }, { skip: !personId });
  const {
    data: { dateOfBirth } = {}, isLoading: statusDemographics,
  } = useGetDemographicsByPersonIdQuery({ personId }, { skip: !personId });
  const {
    data: { primaryProgram } = {},
    isFetching: statusPrograms,
  } = useGetProgramsByIdHook(personId);
  const {
    data: {
      auditDetails = [],
      programProgress: { creditsSummary = {} } = {},
    } = {},
    isLoading: statusProgramProgress,
  } = useGetProgramProgressDataByProgramIdQuery({
    programId: primaryProgram?.id,
  }, { skip: !primaryProgram?.id });

  const enrollmentStatus = primaryProgram?.statuses
    ? primaryProgram.statuses.find(
      (status: { statusType: string }) => status.statusType === 'EnrollmentStatus',
    )
    : undefined;
  const sortedMembershipIds = sortMemberships(memberships);

  const currentCourses = sortedMembershipIds.currentMembershipIds.map(
    (membershipId: string) => courses.find((course: { id: string }) => course.id === membershipId),
  );
  const futureCourses = sortedMembershipIds.futureMembershipIds.map(
    (membershipId: string) => courses.find((course: { id: string }) => course.id === membershipId),
  );

  // time based off of user's local TZ
  const usersTimeZone = DateTime.local()?.zone?.name;
  const localNow = getCurrentDate(usersTimeZone);

  // Veterans Day Time stuff, based on local TZ
  const veteransDay = DateTime.fromObject({ month: 11, day: 11 });
  const isVeteransDayAndIsVeteran = veteransDay.toFormat('MM DD') === localNow.toFormat('MM DD') && !!serviceType;
  const userOs = (typeof navigator !== 'undefined') ? navigator.appVersion : 'unknown';

  // Birthday Time stuff, based on local TZ
  const usersBirthday = toDateTime(dateOfBirth, 'local');
  const isBirthday = usersBirthday.toFormat('MM DD') === localNow.toFormat('MM DD');
  const prefersReducedMotion = usePrefersReducedMotion();

  const theme = useTheme();

  // Confetti checks
  const birthdayConfettiPreviouslyPlayed = useMemo(
    () => !!getItem(ConfettiStorageKeys.Birthday),
    [],
  );
  const confettiPlayedBirthday = !statusDemographics
    ? birthdayConfettiPreviouslyPlayed
    : false;
  const veteransDayConfettiPreviouslyPlayed = useMemo(
    () => !!getItem(ConfettiStorageKeys.VeteransDay),
    [],
  );
  const confettiPlayedVeteransDay = !statusServiceType
    ? veteransDayConfettiPreviouslyPlayed
    : false;
  const programConfettiPreviouslyPlayed = useMemo(
    () => !!getItem(ConfettiStorageKeys.Program),
    [],
  );
  const confettiPlayed = !statusPrograms
    ? programConfettiPreviouslyPlayed
    : false;

  // finishedCourses === not marked as completed yet, but student finished their final course
  const finishedCourses = !!auditDetails
    && enrollmentStatus
    && enrollmentStatus.status === 'F'
    && !currentCourses.length
    && !futureCourses.length
    && !statusProgramProgress
    && creditsSummary?.notScheduled === 0;
  const programCompleted = enrollmentStatus
    && enrollmentStatus.status === 'C'
    && !currentCourses.length
    && !futureCourses.length;
  const alumni = enrollmentStatus && enrollmentStatus.status === 'G';
  const countDownToGraduation = creditsSummary.notScheduled === 0 && creditsSummary.scheduled <= 24;
  const coursesRemaining = auditDetails.filter(
    (course: { auditStatus: string }) => course.auditStatus === 'SC' && course,
  );
  const isLoading = statusCourses || statusPrograms || statusProgramProgress;

  function nameWithGreeting(
    greeting: string,
    emoji?: string,
    emojiLabel?: string,
    emojiBeforeGreeting?: boolean,
  ) {
    const name = preferredName || firstName;
    const fullGreeting = resolveExternalConfigs(greeting, [name]);
    return (
      <>
        {emoji && emojiBeforeGreeting && (
          <Emoji emojiKey={emoji} label={emojiLabel} />
        )}
        {fullGreeting}
        {emoji && !emojiBeforeGreeting && (
          <Emoji emojiKey={emoji} label={emojiLabel} />
        )}
      </>
    );
  }

  const vetGreeting = () => {
    if (userOs.indexOf('Win') !== -1) {
      return nameWithGreeting(
        veteransDayGreeting,
        veteranEmoji,
        veteranEmojiLabel,
      );
    }
    return nameWithGreeting(veteransDayGreeting, veteranEmoji);
  };

  // greetings based on local TZ
  function timeBasedGreeting() {
    const { hour } = localNow;
    if (hour < 12) {
      return nameWithGreeting(morningGreeting, morningEmoji, morningEmojiLabel);
    }
    if (hour < 17 && hour >= 12) {
      return nameWithGreeting(
        afternoonGreeting,
        afternoonEmoji,
        afternoonEmojiLabel,
      );
    }
    if (hour >= 17) {
      return nameWithGreeting(eveningGreeting, eveningEmoji, eveningEmojiLabel);
    }
    return null;
  }

  function gradGreeting() {
    if (alumni || programCompleted) {
      return nameWithGreeting(alumniGreeting, alumniEmoji, alumniLabel, true);
    }
    if (finishedCourses) {
      return nameWithGreeting(pendingFinalGradeGreeting);
    }
    return null;
  }

  function countDownGreeting(length: number) {
    switch (length) {
      case 7:
        return nameWithGreeting(
          sevenClassesGreeting,
          sevenClassesEmoji,
          sevenClassesLabel,
        );
      case 6:
        return nameWithGreeting(
          sixClassesGreeting,
          sixClassesEmoji,
          sixClassesLabel,
        );
      case 5:
        return nameWithGreeting(
          fiveClassesGreeting,
          fiveClassesEmoji,
          fiveClassesLabel,
        );
      case 4:
        return nameWithGreeting(
          fourClassesGreeting,
          fourClassesEmoji,
          fourClassesLabel,
        );
      case 3:
        return nameWithGreeting(
          threeClassesGreeting,
          threeClassesEmoji,
          threeClassesLabel,
        );
      case 2:
        return nameWithGreeting(twoClassGreeting, twoClassEmoji, twoClassLabel);
      case 1:
        return nameWithGreeting(oneClassGreeting, oneClassEmoji, oneClassLabel);
      default:
        return nameWithGreeting(
          eightClassesGreeting,
          eightClassesEmoji,
          eightClassesLabel,
        );
    }
  }

  const setConfettiDashboardStyles = () => {
    const dashboard = (typeof document !== 'undefined') ? document.querySelectorAll<HTMLElement>('#dashboard')[0] : undefined;
    if (dashboard) {
      dashboard.style.position = 'relative';
      dashboard.style.zIndex = '2';
    }
  };

  // Returns below this point
  if ((preferredName || firstName) && !isLoading) {
    // if its your birthday
    // Priority: 4
    if (isBirthday) {
      setConfettiDashboardStyles();
      setItem(ConfettiStorageKeys.Birthday, 'true');
      return (
        <Box data-testid="greetings-4" className="personalized-greeting-with-confetti">
          <GreetingUi>
            {nameWithGreeting(
              birthdayGreeting,
              birthdayEmoji,
              birthdayEmojiLabel,
              true,
            )}
          </GreetingUi>
          {!confettiPlayedBirthday && !prefersReducedMotion && (
            <Confetti
              extraClass="confetti"
              ariaLabel="Confetti! Happy Birthday!"
            />
          )}
        </Box>
      );
    }
    // if it's Veterans Day and student is a veteran
    // Priority: 3
    if (isVeteransDayAndIsVeteran) {
      setConfettiDashboardStyles();
      setItem(ConfettiStorageKeys.VeteransDay, 'true');
      return (
        <Box data-testid="greetings-3" className="personalized-greeting-with-confetti">
          <GreetingUi>{vetGreeting()}</GreetingUi>
          {!confettiPlayedVeteransDay && !prefersReducedMotion && (
            <Confetti
              extraClass="confetti"
              ariaLabel="Confetti! Thank you for your service"
              colors={[
                theme.palette.veteransDayConfetti.white,
                theme.palette.veteransDayConfetti.blue,
                theme.palette.veteransDayConfetti.red,
                theme.palette.veteransDayConfetti.black,
              ]}
            />
          )}
        </Box>
      );
    }
    // if program is complete and confetti has not played
    // Priority: 2
    if (
      programCompleted
      && !confettiPlayed
      && !prefersReducedMotion
      && !isBirthday
    ) {
      setConfettiDashboardStyles();
      setItem(ConfettiStorageKeys.Program, 'true');
      return (
        <Box data-testid="greetings-2" className="personalized-greeting-with-confetti">
          <GreetingUi>{gradGreeting()}</GreetingUi>
          <Confetti
            extraClass="confetti"
            ariaLabel="Confetti! We're excited you're close to graduation"
          />
        </Box>
      );
    }

    // if alumni / program over
    // Priority: 2
    if (
      alumni
      || finishedCourses
      || (programCompleted && confettiPlayed)
      || (programCompleted && prefersReducedMotion)
    ) {
      return (
        <GreetingUi testId="greetings-2alumni">{gradGreeting()}</GreetingUi>
      );
    }

    // if less than 8 courses left
    // Priority: 1
    if (countDownToGraduation) {
      return (
        <GreetingUi testId="greetings-1">
          {countDownGreeting(coursesRemaining.length)}
        </GreetingUi>
      );
    }

    // Fall through to time based greeting
    // Priority: 0
    if (!isLoading) {
      return (
        <GreetingUi testId="greetings-0">
          {timeBasedGreeting()}
        </GreetingUi>
      );
    }

    return <Blank />;
  }

  // Fall Back to skeleton when all information isnt present
  if (isLoading) {
    return (
      <LoadingBarContainer data-testid="greetings-loading">
        <LinearProgress color="secondary" aria-label="Progress bar" />
      </LoadingBarContainer>
    );
  }

  return <Blank data-testid="greetings-blank" />;
}

export default Greeting;
