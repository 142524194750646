export const COURSE_MESSAGES = [
  '',
  'This is it, just one more course!',
  'You\'re almost done!',
  'You\'re so close now!',
  'You\'re on the home stretch now!',
  'You\'re doing great!',
  'Keep going, you\'ve got this!',
  'We know you\'re working hard, and it shows!',
  'Your countdown to the finish line begins.',
];

export const TOOLTIP_MESSAGE = 'This is an estimate of your remaining program courses. All program requirements need to be met in order to be eligible to graduate.';
