import GenericModal from '@/components/atoms/generic-modal';
import IframeElement from '@/components/atoms/iframe';
import { useLazyGetSubmittedApplicationQuery } from '@/store/queries/document';
import { CardContent, CircularProgress, Fade, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import ProgramContacts from '@/components/organisms/program-contacts';
import { navigate } from '@/helpers/window-functions';
import { Heading, SubHeading, Divider, CardBody, Card, Button, StyledLink, LoadingContainer } from './ReEnrollmentTile.styles';

export type ReEnrollmentTileProps = {
  applicationId?: string
  applicationType?: string
  enrollmentAppStatus: string
  programName: string
  returnToApplicationText: string;
  returnToApplicationUrl: string;
};

function ReEnrollmentTile({
  applicationId,
  applicationType,
  enrollmentAppStatus,
  programName,
  returnToApplicationText,
  returnToApplicationUrl,
}: ReEnrollmentTileProps) {
  const componentName = 'ReEnrollmentTile';
  const [isSubmittedApplicationOpen, setIsSubmittedApplicationOpen] = useState(false);
  const [
    loadApplicationTrigger,
    { data, isLoading, isFetching, isSuccess, isError },
  ] = useLazyGetSubmittedApplicationQuery();

  const openModalAndLoadData = () => {
    setIsSubmittedApplicationOpen(true);
    loadApplicationTrigger({ applicationId, applicationType }, true);
  };

  const isCompleted = enrollmentAppStatus === 'COMPLETED';
  const title = isCompleted ? 'Your re-enrollment forms have been submitted' : 'We\'re glad you\'re coming back';
  const content = isCompleted
    ? 'Your assigned enrollment representative will reach out to you soon to help you through the next steps. Reach out now if you need help immediately.'
    : 'Your re-enrollment application is almost complete. Let\'s finish now so you can keep moving forward!';

  return (
    <Card>
      <CardContent data-component={componentNameToMyPhxKebabFormat(componentName)}>
        <Box>
          <Heading variant="body4" component="h2">{programName}</Heading>
          <SubHeading variant="body4" component="h3" pt={2} pb={1}>{title}</SubHeading>
          <CardBody variant="body4">
            {content}
          </CardBody>
        </Box>
        {enrollmentAppStatus !== 'COMPLETED' && (
          <Box pt={2}>
            <Button variant="contained" href={returnToApplicationUrl}>
              {returnToApplicationText}
            </Button>
          </Box>
        )}
        {enrollmentAppStatus === 'COMPLETED' && (
        <>
          <Box py={2}>
            <StyledLink component="button" role="button" onClick={() => navigate(returnToApplicationUrl)}>
              {returnToApplicationText}
            </StyledLink>
          </Box>
          {applicationType === 'REENROLL-I' && (
          <Box py={1}>
            <StyledLink component="button" role="button" onClick={openModalAndLoadData}>
              View your submitted application
            </StyledLink>
          </Box>
          )}
        </>
        )}
        <Divider />
        <ProgramContacts isReEnroll />
      </CardContent>
      <GenericModal
        open={isSubmittedApplicationOpen}
        toggleModal={setIsSubmittedApplicationOpen}
        maxWidth="md"
        fullWidth={isLoading || isError}
      >
        {(isLoading || isFetching) && (
          <LoadingContainer>
            <Fade in timeout={500} style={{ transitionDelay: '500ms' }}>
              <CircularProgress size={150} />
            </Fade>
          </LoadingContainer>
        )}
        {isSuccess && data && (
          <IframeElement isApplication content={data} frameProps={{ title: 'View submitted application' }} styleProps={{ maxWidth: '100%' }} />
        )}
        {isError && (
          <Typography variant="body4">
            Sorry, we could not find your application. Please, try again later.
          </Typography>
        )}
      </GenericModal>
    </Card>
  );
}

export default ReEnrollmentTile;
