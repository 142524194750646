import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: theme.spacing(0),
  },
}));

export const NoContactMessage = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(0.8),
  marginTop: theme.spacing(0.8),
  fontSize: theme.spacing(1.6),
}));

export const ContactMessage = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(0.8),
  fontSize: theme.spacing(1.6),
}));

export const Summary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: theme.spacing(1),
  padding: theme.spacing(0),
  '&.Mui-expanded': {
    minHeight: theme.spacing(1),
  },
  '&.Mui-focused': {
    backgroundColor: 'transparent',
    outline: `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
  },
  '& .MuiAccordionSummary-content': {
    margin: `${theme.spacing(1.5)} 0`,
  },
  '& .MuiIconButton-edgeEnd': {
    marginRight: theme.spacing(-1),
  },
}));

export const SummaryHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '700',
  color: theme.palette.text.disabled,
}));

export const SummaryLabel = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(0.5),
}));

export const Details = styled(AccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(0.3),
  marginTop: theme.spacing(-0.5),
  padding: 0,
}));

export const ContactName = styled(Box)(({ theme }) => ({
  fontSize: theme.spacing(2.4),
  fontWeight: 600,
}));

export const EnrollmentRepName = styled(Box)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  fontWeight: 700,
  lineHeight: theme.spacing(2.4),
  marginBottom: theme.spacing(1.4),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(0),
  },
}));

export const ContactEmail = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  '& .empty': {
    height: theme.spacing(0),
    width: theme.spacing(0),
  },
}));

export const UserIcon = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));

export const UserIconContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const DropdownContainer = styled('div')(({ theme }) => ({
  minWidth: theme.spacing(20),
  paddingBottom: theme.spacing(1),
}));

export const StyledCampus = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(0.8),
  marginTop: theme.spacing(0.8),
  fontWeight: 400,
  lineHeight: theme.spacing(1.5),
}));

export const EnrollmentRep = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(0),
  },
}));
