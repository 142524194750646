import { useMemo } from 'react';
import { useGetPersonByIdQuery, useGetUserAttributesByPersonIdQuery } from '@/store/queries/v2/person';

const useGetPersonsHook = (personId: string) => {
  const {
    data: personResponse,
    isFetching: isGetPersonByIdFetching,
    isError: isGetPersonByIdError,
  } = useGetPersonByIdQuery({ personId }, { skip: !personId });

  const {
    data: userAttributeResponse,
    isFetching: isGetUserAttributesByPersonIdFetching,
    isError: isGetUserAttributesByPersonIdError,
  } = useGetUserAttributesByPersonIdQuery({ personId }, { skip: !personId });

  const data = useMemo(() => {
    if (
      isGetPersonByIdError
            || isGetPersonByIdFetching
            || isGetUserAttributesByPersonIdError
            || isGetUserAttributesByPersonIdFetching
    ) {
      return {};
    }
    const userDefined = userAttributeResponse?.attributes?.['User Defined'] || [];
    const personEmail = personResponse?.externalSystemIds?.UNIVERSITY_EMAIL?.[0] || '';
    const salesForceId = personResponse?.externalSystemIds?.SALESFORCE_ID?.[0];

    return { userDefined, personEmail, salesForceId };
  }, [isGetPersonByIdError,
    isGetPersonByIdFetching,
    isGetUserAttributesByPersonIdError,
    isGetUserAttributesByPersonIdFetching,
    personResponse?.externalSystemIds?.SALESFORCE_ID,
    personResponse?.externalSystemIds?.UNIVERSITY_EMAIL,
    userAttributeResponse?.attributes]);

  return {
    data,
    isFetching: isGetPersonByIdFetching || isGetUserAttributesByPersonIdFetching,
    isError: isGetPersonByIdError || isGetUserAttributesByPersonIdError,
  };
};

export default useGetPersonsHook;
