const heading: string = 'Engagement';
const toolTipMessage: string = 'To maintain engagement, you’ll need to complete a Knowledge Check or a Competency Assessment.';
const linkLabel: string = 'Go to your classes';
const urgentLinkLabel: string = 'Post now';
const postedTodayMessage: string = 'You’ve met your attendance requirement.';
const postedTodaySecondLine: string = 'Keep it up! Completing your academic activities and participating in class is the key to success.';
const between10And14DaysToPostMessage: string = 'Your last post was on #{date}.';
const between10And14DaysToPostSecondLine: string = 'Be sure to post in at least one of your classes to avoid being withdrawn from all your scheduled courses for the term.';
const between4And10DaysToPostBoldedMessage: string = 'Be sure to post soon!';
const between4And10DaysToPostMessage: string = 'Your last post was on #{date}.';
const between4And10DaysToPostSecondLine: string = 'You’ll need to post in at least one of your classes to avoid being withdrawn from all your scheduled courses for the term.';
const between1And4DaysWarningTitle: string = 'Time is running out!';
const between1And4DaysToPostMessage: string = 'Your last post was on #{date}.';
const between1And4DaysToPostSecondLine: string = 'Stay on track by posting in at least one of your classes to avoid being withdrawn from all your scheduled courses for the term.';

export {
  heading,
  toolTipMessage,
  linkLabel,
  urgentLinkLabel,
  postedTodayMessage,
  postedTodaySecondLine,
  between10And14DaysToPostMessage,
  between10And14DaysToPostSecondLine,
  between4And10DaysToPostBoldedMessage,
  between4And10DaysToPostMessage,
  between4And10DaysToPostSecondLine,
  between1And4DaysWarningTitle,
  between1And4DaysToPostMessage,
  between1And4DaysToPostSecondLine,
};
