import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import {
  useGetPersonByIdQuery,
  useGetOfficialNameQuery,
} from '@/store/queries/person';
import useGetPrimaryOrDefaultEmailByPersonIdHook from '@/store/hooks/useGetPrimaryOrDefaultEmailByPersonIdHook';
import useGetPrimaryOrDefaultPhoneByPersonIdHook from '@/store/hooks/useGetPrimaryOrDefaultPhoneByPersonIdHook';
import { useCallback } from 'react';
import { useProactiveChat } from './useProactiveChat';
import { startChat } from './useChat.helper';

export const useChat = ({ personId }: { personId?: string } = {}) => {
  const personIdToFetch = personId || getPersonIdFromSessionStorage();
  const { data: personData } = useGetPersonByIdQuery({
    personId: personIdToFetch,
  }, { skip: !personIdToFetch });
  const { data: personNames } = useGetOfficialNameQuery({
    personId: personIdToFetch,
  }, { skip: !personIdToFetch });
  const { data: primaryEmail } = useGetPrimaryOrDefaultEmailByPersonIdHook(
    personIdToFetch,
  );
  const { data: primaryPhone } = useGetPrimaryOrDefaultPhoneByPersonIdHook(
    personIdToFetch,
  );

  const firstName: string = personNames?.firstName || '';
  const lastName: string = personNames?.lastName || '';
  const [profileId]: string[] = personData?.externalSystemIds?.PROFILE_ID || [''];
  const studentIRN: string = personData?.issuerId || '';
  const email: string = primaryEmail?.emailAddress || '';
  const phoneNumber: string = primaryPhone?.phoneNumber || '';

  const startChatCallback = useCallback(
    (serviceName: string = '') => startChat({
      firstName,
      lastName,
      email,
      profileId,
      studentIRN,
      phoneNumber,
      serviceName,
    }),
    [firstName, lastName, email, profileId, studentIRN, phoneNumber],
  );

  useProactiveChat({
    personId: personIdToFetch,
    firstName,
    lastName,
    profileId,
    irn: studentIRN,
    email,
    phoneNumber,
  });

  return startChatCallback;
};

export default useChat;
