import NearGradTile from '@/components/molecules/near-grad-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

function NearGraduatedScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: ({
      currentCourseMemberships,
      futureCourseMembershipsInNext180Days,
      enrollmentStatus,
      primaryProgramProgress,
    }: CourseInfoData) => currentCourseMemberships.length === 0
        && futureCourseMembershipsInNext180Days.length === 0
        && enrollmentStatus?.status === 'F'
        && primaryProgramProgress?.creditsSummary?.notScheduled === 0,
    Component: (
      { courseInfoData: { parentComponentName, primaryProgram } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <NearGradTile
        parentComponentName={parentComponentName}
        primaryProgramName={primaryProgram.name}
      />
    ),
  };
}

export default NearGraduatedScenario;
