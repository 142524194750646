export async function convertHtmlToImage(element: HTMLElement) {
  const { toJpeg } = await import('html-to-image');

  const options = { quality: 0.95 };
  // eslint-disable-next-line no-console
  return toJpeg(element, options).catch((error) => console.error(error));
}

export async function convertHtmlToBase64(element: HTMLElement) {
  const child = element?.firstElementChild || element;
  const result = await convertHtmlToImage(child as HTMLElement);
  return (result || '').replace(/^data:image\/\w+;base64,/, '');
}

export async function convertHtmlToBase64FromSelector(selector: string) {
  const element = document.getElementById(selector);
  return convertHtmlToBase64(element);
}

export async function downloadImage(element: HTMLElement, fileName: string = 'download.jpeg') {
  const child = element?.firstElementChild || element;
  const fileNameFromElementOrDefault = child.getAttribute('data-filename') || fileName;
  const result = await convertHtmlToImage(child as HTMLElement);
  const link = document.createElement('a');
  link.download = fileNameFromElementOrDefault;
  link.href = result || '';
  link.click();
}

export async function downloadImageFromSelector(selector: string, fileName?: string) {
  const element = document.getElementById(selector);
  return downloadImage(element, fileName);
}
