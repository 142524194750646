import { withTransientProps } from '@/helpers/with-transient-props';
import { TableCell, Typography, Table } from '@mui/material';
import { styled } from '@mui/material/styles';
import StandardLink from '@/components/atoms/standard-link';

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  animation: '$appear 0.5s ease',
  transformOrigin: '0% 0%',
  '& table': {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
    boxSizing: 'border-box',
  },
  '& tbody': {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1.2),
    },
  },
  '& table, tbody, thead, tr, td': {
    display: 'block',
  },
  '& tr': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderBottom: `${theme.spacing(0.1)} solid ${theme.palette.custom.lightGrey}`,
    [theme.breakpoints.up('sm')]: {
      display: 'table-row',
      border: 'none',
    },
  },
  '& tr:last-child': {
    border: 'none',
  },
  '& td': {
    border: 'none',
    padding: 0,
    verticalAlign: 'top',
    lineHeight: '1.5',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'table-cell',
    },
  },
  '@keyframes appear': {
    '0%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  '& caption': {
    captionSide: 'top',
    fontWeight: 'bold',
    width: theme.spacing(20),
    padding: 0,
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
    fontSize: theme.spacing(1.4),
    color: theme.palette.text.disabled,
  },
}));

export const StyledCourse = styled(TableCell)(({ theme }) => ({
  width: '100%',
  fontSize: theme.spacing(1.6),
}));

export const StyledCourseCode = styled(StandardLink)(({ theme }) => ({
  paddingBottom: theme.spacing(0.3),
  borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.text.secondary}`,
  color: theme.palette.text.primary,
  textDecoration: 'none',
  fontSize: theme.spacing(1.6),
  '&:hover, &:focus': {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.primary.main}`,
  },
  '&:focus': {
    outlineStyle: 'solid',
    outlineColor: theme.palette.text.primary,
    outlineOffset: theme.spacing(0.3),
  },
}));

export const StyledCourseDescription = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.9),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.up('sm')]: {
    display: 'inline-block',
    maxWidth: theme.spacing(40),
  },
  [theme.breakpoints.up('md')]: {
    display: 'inline-block',
    maxWidth: theme.spacing(42),
  },
}));

export const CourseTitle = styled(Typography)(({ theme }) => ({
  display: 'block',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
  fontWeight: 'bold',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    display: 'inline',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
  },
})) as typeof Typography;

export const CourseLink = styled('div')(({ theme }) => ({
  verticalAlign: 'top',
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(0.7),
  '& a:focus': {
    textDecoration: 'none',
    outlineStyle: 'solid',
    outlineColor: theme.palette.text.primary,
    outlineOffset: theme.spacing(0.3),
  },
  '& a:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    marginTop: theme.spacing(-1.2),
  },
  [theme.breakpoints.up('md')]: {
    display: 'inline-block',
    marginTop: theme.spacing(0.8),
    paddingLeft: theme.spacing(1.5),
  },
}));

export const StyledTableCell = styled(
  TableCell,
  withTransientProps,
)<{ $hasSpecialGrades: boolean,
  $hasCourseGrades: boolean }>((
  { theme, $hasSpecialGrades, $hasCourseGrades },
) => ({
  ...($hasSpecialGrades && {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.4),
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(18),
      fontSize: theme.spacing(1.6),
    },
  }),
  ...(!$hasSpecialGrades && {
    width: theme.spacing(13),
    minWidth: theme.spacing(12),
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.4),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(1.6),
    },
  }),
  ...($hasCourseGrades && {
    color: theme.palette.primary.main,
  }),
  ...(!$hasCourseGrades && {
    color: theme.palette.text.disabled,
  }),
}));

export const GradeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexFlow: 'row',
  paddingLeft: theme.spacing(0.7),
  [theme.breakpoints.up('sm')]: {
    flexFlow: 'row',
    paddingTop: theme.spacing(1.3),
    marginLeft: theme.spacing(-2),
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(0.8),
  },
  '& svg': {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.2),
  },
  '& span': {
    alignItems: 'center',
    marginLeft: theme.spacing(0.3),
    lineBreak: 'normal',
    '& svg': {
      marginRight: 'unset',
      marginTop: 'unset',
    },
  },
  '& div': {
    display: 'inline-flex',
    verticalAlign: 'text-top',
  },
  '& button.MuiFab-root .MuiFab-label svg': {
    fill: theme.palette.common.black,
  },
  '& button.MuiFab-root .MuiFab-label': {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.4),
  },
  '& p': {
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(16),
    },
  },
}));

export const DueDate = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(3.5),
  fontSize: theme.spacing(1.6),
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1.3),
  },
  '& span': {
    fontWeight: '600',
  },
}));

export const HiddenSpan = styled('span')(({ theme }) => ({
  position: 'absolute',
  width: theme.spacing(0.1),
  height: theme.spacing(0.1),
  padding: 0,
  margin: theme.spacing(-0.1),
  overflow: 'hidden',
  border: 0,
}));

export const StyledGrade = styled('span')(({ theme }) => ({
  paddingRight: theme.spacing(0.4),
  fontWeight: 'bold',
}));
