import SingleTile from '@/components/molecules/single-tile';
import { callPhoneNumber } from '@/myphoenix/utils/phone-functions';
import { navigate } from '@/helpers/window-functions';
import { toDateTime, toSpokenDateFromISO } from '@/myphoenix/utils/date-time-functions';
import { useChat } from '@/hooks/useChat';
import { CourseCode, CourseTitle, CourseDescription } from './LOA.styles';

export type LeaveOfAbsenceProps = {
  futureCourses?: {
    membershipId?: string,
    sectionId?: string,
    title?: string,
    description?: string,
    statusSubCode?: string,
    type?: string,
    templateCode?: string,
    startDate?: string,
    allowedAbsences?: string,
    activityIds?: string[],
    grade?: string,
  }[],
  phone: string,
  loaReturnDateString: string,
  small?: boolean,
  contactName?: string,
  parentComponentName?: string,
};

function LeaveOfAbsenceTile({
  futureCourses,
  phone = '',
  loaReturnDateString = '',
  small = false,
  contactName = '',
  parentComponentName = '',
}: LeaveOfAbsenceProps) {
  const tileType = 'leave-of-absence';
  const componentName = 'LeaveOfAbsenceSingleTile';

  const startChat = useChat();
  const loaReturnDate = toDateTime(loaReturnDateString).startOf('day');
  const withinLoa = futureCourses?.filter((course) => {
    const startDate = toDateTime(course.startDate).startOf('day');
    return startDate.valueOf() <= loaReturnDate.valueOf();
  });
  const title = 'You are currently on a Leave of Absence';
  if (withinLoa?.length >= 1) {
    const scheduledFutureCourses = () => {
      let currentClosestCourse = withinLoa[0];
      withinLoa.forEach((course: any) => {
        const startDate = toDateTime(course.startDate);
        const closestDate = toDateTime(currentClosestCourse.startDate);
        if (startDate.valueOf() >= closestDate.valueOf()) {
          currentClosestCourse = course;
        }
      });
      return {
        courseTitle: currentClosestCourse?.title,
        startDate: toSpokenDateFromISO(currentClosestCourse?.startDate),
      };
    };

    const multipleReturnCourses = withinLoa?.map((course: { [item: string]: string }) => (
      <CourseDescription key={course?.templateCode}>
        <CourseCode variant="body4">{course.templateCode}</CourseCode>
        <CourseTitle variant="body4">{course.title}</CourseTitle>
      </CourseDescription>
    ));

    const mainContent = withinLoa.length === 1
      ? `You are set to resume ${scheduledFutureCourses().courseTitle} on ${scheduledFutureCourses().startDate}. If you would like to return sooner or change your return date, contact us for assistance.`
      : `You are set to return to your classes on ${scheduledFutureCourses().startDate}. Contact your academic representative if you would like to return sooner or change your return date.`;
    return (
      <SingleTile
        parentComponentName={`${parentComponentName}_${componentName}`}
        data-loa="container"
        tileType={tileType}
        icon="icon-pause-mc"
        title={title}
        mainContent={mainContent}
        list={withinLoa.length > 1 ? multipleReturnCourses : undefined}
        primaryButtonText="Academic Plan"
        primaryButtonCallback={() => navigate('/academic-plan.html')}
        small={small}
        phone={phone}
        instructor={contactName}
      />
    );
  }

  return futureCourses ? (
    <SingleTile
      parentComponentName={`${parentComponentName}_${componentName}`}
      data-loa="container"
      tileType={tileType}
      darkMode
      icon="icon-alert-mc"
      title="You have not returned from your Leave of Absence"
      mainContent={`Your leave of absence return date is ${toSpokenDateFromISO(loaReturnDateString)}. Please call your academic advisor to schedule your return course.`}
      secondaryButtonText="Call us"
      secondaryButtonCallback={() => callPhoneNumber(phone)}
      primaryButtonText="Chat with us"
      primaryButtonCallback={startChat}
      small={small}
      phone={phone}
      instructor={contactName}
    />
  ) : null;
}

export default LeaveOfAbsenceTile;
