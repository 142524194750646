import { styled } from '@mui/material/styles';
import {
  Box,
  List,
  ListItem,
  Typography,
} from '@mui/material';

export const StyledList = styled(List)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: theme.spacing(20),
  },
  '& .MuiListItem-root': {
    display: 'block',
  },
}));

export const StatusLabel = styled(Typography)(({ theme }) => ({
  display: 'inline',
  fontWeight: 700,
  marginTop: theme.spacing(0.7),
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const TooltipBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  marginLeft: theme.spacing(0.5),
  verticalAlign: 'top',
  '& button.MuiFab-root .MuiFab-label svg': {
    fill: theme.palette.almostBlack.l30,
  },
}));

export const DateItem = styled(ListItem)(({ theme }) => ({
  borderTop: `${theme.spacing(0.1)} solid ${theme.palette.almostBlack.l80}`,
  paddingTop: theme.spacing(1.5),
  marginTop: theme.spacing(2),
}));
