import { styled } from '@mui/material/styles';
import { StepConnector, StepLabel } from '@mui/material';
import { withTransientProps } from '@/helpers/with-transient-props';

export const StyledConnector = styled(
  StepConnector,
  withTransientProps,
)<{ $lazy?: boolean }>(({ theme, $lazy = false }) => ({
  '&.MuiStepConnector-alternativeLabel': {
    top: theme.spacing(0.7),
    marginTop: 0,
    left: `calc(-50% + ${theme.spacing(1.5)})`,
    right: `calc(50% + ${theme.spacing(1.5)})`,
  },
  '& .MuiStepConnector-line': {
    borderWidth: theme.spacing(0.2),
  },
  '&.Mui-active > .MuiStepConnector-line, &.Mui-completed > .MuiStepConnector-line': {
    borderColor: $lazy ? theme.palette.almostBlack.l70 : theme.palette.primary.main,
  },
  '&.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel': {
    marginTop: 0,
  },
  '&.MuiStepConnector-vertical': {
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    marginLeft: theme.spacing(0.7),
    '& .MuiStepConnector-line': {
      borderWidth: theme.spacing(0.3),
    },
  },
  '& .MuiStepConnector-lineVertical': {
    borderColor: $lazy ? theme.palette.almostBlack.l30 : theme.palette.almostBlack.l70,
    minHeight: $lazy ? theme.spacing(6) : theme.spacing(3.7),
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(5.4),
    },
  },
}));

export const StyledLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    fontSize: theme.spacing(1.2),
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: theme.spacing(1.8),
    marginTop: 0,
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
    '& p': {
      margin: 0,
    },
  },
  '&.MuiStepLabel-horizontal .MuiStepLabel-labelContainer': {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .Mui-completed p': {
      fontWeight: 800,
      '& span': {
        fontWeight: 400,
      },
    },
  },
  '&.MuiStepLabel-horizontal .MuiStepLabel-labelContainer > .MuiStepLabel-alternativeLabel': {
    width: `calc(50% + ${theme.spacing(0.8)})`,
    '& .MuiStepLabel-label': {
      textAlign: 'left',
    },
  },
  '&.MuiStepLabel-vertical': {
    padding: 0,
  },
}));
