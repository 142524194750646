import { useEffect } from 'react';
import SingleTile from '@/components/molecules/single-tile';
import theme from '@/helpers/theme';
import { trackComponentError } from '@/myphoenix/utils/error-functions';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { navigate } from '@/helpers/window-functions';

type ErrorTileProps = {
  parentComponentName?: string,
  errorTitle: string,
  errorText: string,
  errorComponent?: string,
  errorButtonText?: string,
  handlePrimaryButtonClick: (...args: any[]) => any,
  errorSecondaryButtonText?: string,
  errorSecondaryButtonLink?: string,
  small?: boolean,
};

export default function ErrorSingleTile({
  parentComponentName,
  errorTitle = 'Oh dear!',
  errorText = 'We\'re sorry for the inconvenience, there seems to be a problem. Let\'s give it another try when you are ready!',
  errorComponent = 'N/A',
  errorButtonText = 'Try again',
  handlePrimaryButtonClick,
  errorSecondaryButtonText,
  errorSecondaryButtonLink,
  small = false,
}: ErrorTileProps) {
  const componentName = 'ErrorSingleTile';
  const tileType = 'error';

  useEffect(() => {
    trackComponentError(errorComponent, errorText);
  }, [errorComponent, errorText]);

  return (
    <SingleTile
      parentComponentName={`${parentComponentName}_${componentName}`}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      title={errorTitle}
      icon="icon-oh-dear-mc"
      iconColor={theme.palette.custom.skyBound}
      mainContent={errorText}
      tileType={tileType}
      secondaryButtonText={errorSecondaryButtonText}
      secondaryButtonCallback={() => navigate(errorSecondaryButtonLink)}
      primaryButtonText={errorButtonText}
      primaryButtonCallback={handlePrimaryButtonClick}
      small={small}
    />
  );
}
