import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useGetAudiencesQuery } from '@/store/queries/segment';
import { FeatureVariableKeys } from '@/helpers/features';
import { useFeatureVariableValue } from '@/helpers/feature-provider';

const UseGetStepCardState = () => {
  const isEnabled = useFeatureVariableValue(FeatureVariableKeys.MfeCareersStepCard, false);
  const personId = getPersonIdFromSessionStorage();
  const {
    data: audiencesFromSegment = [],
  } = useGetAudiencesQuery({ personId }, { skip: !personId });

  const hasSavedCareer = audiencesFromSegment?.includes('has_saved_career');
  const hasSavedJobTitle = audiencesFromSegment?.includes('has_saved_job_titles');

  return {
    isEnabled,
    hasSavedCareer,
    hasSavedJobTitle,
  };
};
export default UseGetStepCardState;
