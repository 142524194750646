import { styled } from '@mui/material/styles';
import { Box, ListItem, Typography } from '@mui/material';

type InputProps = {
  color: string,
};

export const StyledLabel = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(6),
}));

export const StyledValue = styled(Typography)(() => ({
  display: 'inline-block',
  position: 'absolute',
  right: 0,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'block',
  padding: 0,
  marginBottom: theme.spacing(0.5),
  whiteSpace: 'nowrap',
}));

export const ModalContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}));

export const StyledBox = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'color' },
)<InputProps>(({ theme, color }) => {
  function colorPicker(shade: string) {
    switch (shade) {
      case 'coral':
        return theme.palette.donut.coral;
      case 'maize':
        return theme.palette.donut.maize;
      case 'gray':
        return theme.palette.almostBlack.l50;
      case 'borderGray':
        return theme.palette.almostBlack.l70;
      case 'seafoam':
        return theme.palette.donut.seafoam;
      case 'teal':
        return theme.palette.donut.teal;
      case 'navyTeal':
        return theme.palette.donut.navyTeal;
      case 'babyMelonGreen':
        return theme.palette.donut.babyMelonGreen;
      case 'dangerRed':
        return theme.palette.donut.dangerRed;
      case 'phoenixRed':
        return theme.palette.donut.phoenixRed;
      case 'burntOrange':
        return theme.palette.donut.burntOrange;
      case 'brightOrange':
        return theme.palette.donut.brightOrange;
      case 'lightOrange':
        return theme.palette.donut.lightOrange;
      case 'sunriseOrange':
        return theme.palette.donut.sunriseOrange;
      case 'green':
        return theme.palette.donut.green;
      case 'systemGreen':
        return theme.palette.donut.systemGreen;
      default:
        return theme.palette.donut.coral;
    }
  }
  return ({
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '100%',
    backgroundColor: colorPicker(color),
    marginRight: theme.spacing(1),
  });
});
