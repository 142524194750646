import Interrupter from '@/components/molecules/interrupter';
import B2BInterrupter from '@/components/molecules/b2b-interrupter';
import useGetHomePageInterrupterState from '@/components/organisms/home-page-interrupter/useGetHomePageInterrupter.state';

function HomePageInterrupterContainer() {
  const componentName = 'HomePageInterrupter';
  const { shouldShowInterrupter = false } = useGetHomePageInterrupterState();
  return shouldShowInterrupter ? (
    <Interrupter
      parentComponentName={componentName}
    >
      <B2BInterrupter parentComponentName={componentName} />
    </Interrupter>
  ) : null;
}

export default HomePageInterrupterContainer;
