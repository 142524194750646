import StandardButton from '@/components/atoms/standard-button';
import { Grid, Typography, styled } from '@mui/material';

export const Body = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    alignContent: 'start',
    alignItems: 'start',
  },
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  lineHeight: theme.spacing(2.8),
  marginTop: theme.spacing(0.6),
})) as typeof Typography;

export const BodyHeading = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: theme.spacing(1.6),
  lineHeight: theme.spacing(2.8),
  marginTop: theme.spacing(0.6),
})) as typeof Typography;

export const BodyContent = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  marginBottom: theme.spacing(2),
})) as typeof Typography;

export const StyledButton = styled(StandardButton)(({ theme }) => ({
  borderRadius: theme.spacing(2.8),
  height: theme.spacing(4),
  width: '100%',
  textTransform: 'none',
  boxShadow: 'none',
  marginBottom: theme.spacing(2.4),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.dark,
    outline: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}));
