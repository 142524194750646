import { styled } from '@mui/material/styles';
import {
  Typography,
  Box,
} from '@mui/material';

export const Blank = styled(Box)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(5.12),
}));

export const LoadingBarContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const GreetingContainer = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.spacing(3.2),
  fontWeight: 300,
  lineHeight: '1.6',
  letterSpacing: theme.spacing(0.0075),
})) as typeof Typography;
