import AlumniSingleTile from '@/components/molecules/alumni-single-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { toDateTime } from '@/myphoenix/utils/date-time-functions';

function AlumniScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => (courseInfoData.enrollmentStatus?.status === 'G'),
    Component: (
      { courseInfoData: { parentComponentName, primaryProgram, pastCourses } }
      : { courseInfoData: CourseInfoData },
    ) => {
      const pastCoursesSortedByMostRecent = pastCourses?.sort(
        (a, b) => toDateTime(b?.endDate).toUnixInteger()
          - toDateTime(a?.endDate).toUnixInteger(),
      );
      return (
        <AlumniSingleTile
          parentComponentName={parentComponentName}
          studentProgramType={primaryProgram?.type}
          qualificationLevel={primaryProgram?.qualificationLevel}
          primaryProgramName={primaryProgram?.name}
          courseTitle={
            pastCoursesSortedByMostRecent?.[0]?.title
          }
        />
      );
    },
  };
}

export default AlumniScenario;
