import { styled } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';
import Icon from '@/components/atoms/icon';
import Avatar from '@mui/material/Avatar';

type InputProps = {
  color: string,
};

export const DonutGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const LegendGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(3.5),
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  fontSize: theme.spacing(2.4),
  fontWeight: 900,
  backgroundColor: theme.palette.custom.darkGrey,
}));

export const DivDataInfo = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
  '& p': {
    lineHeight: '1',
    display: 'inline',
  },
}));

export const DivInnerDataContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const TypographyNumerator = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: theme.spacing(3.2),
  lineHeight: theme.spacing(3.2),
  fontWeight: '600',
  margin: 0,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(4),
    lineHeight: theme.spacing(4),
  },
}));

export const TypographyDenominator = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  margin: 0,
  fontSize: theme.spacing(2.4),
  lineHeight: theme.spacing(2.4),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(3.2),
    lineHeight: theme.spacing(3.2),
  },
}));

export const DashIcon = styled(Icon)(({ theme }) => ({
  width: theme.spacing(2.7),
  height: theme.spacing(0.5),
  fill: theme.palette.almostBlack.l70,
  paddingBottom: theme.spacing(0.3),
}));

export const TypographyPercentageSeperator = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.custom.darkGrey,
    marginBottom: theme.spacing(0.3),
    '& svg': {
      width: theme.spacing(1.5),
      [theme.breakpoints.up('md')]: {
        width: 'unset',
      },
    },
  }),
);

export const DivRoot = styled('div')(({ theme }) => ({
  width: theme.spacing(11.2),
  height: theme.spacing(11.2),
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(16.7),
    height: theme.spacing(16.7),
  },
  '& .MuiAvatar-root': {
    position: 'absolute',
    width: '76%',
    height: '76%',
    top: '12%',
    left: '12%',
    fontSize: theme.spacing(4.4),
    '& img': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(5.4),
    },
  },
}));
export const CircleRing = styled('circle')(() => ({
  fill: 'transparent',
  stroke: '#FFF',
}));

export const CircleChunk = styled(
  'circle',
  { shouldForwardProp: (prop) => prop !== 'color' },
)<InputProps>(({ theme, color }) => {
  function colorPicker(shade: string) {
    switch (shade) {
      case 'coral':
        return theme.palette.donut.coral;
      case 'maize':
        return theme.palette.donut.maize;
      case 'gray':
        return theme.palette.almostBlack.l50;
      case 'borderGray':
        return theme.palette.almostBlack.l70;
      case 'seafoam':
        return theme.palette.donut.seafoam;
      case 'teal':
        return theme.palette.donut.teal;
      case 'navyTeal':
        return theme.palette.donut.navyTeal;
      case 'babyMelonGreen':
        return theme.palette.donut.babyMelonGreen;
      case 'dangerRed':
        return theme.palette.donut.dangerRed;
      case 'phoenixRed':
        return theme.palette.donut.phoenixRed;
      case 'burntOrange':
        return theme.palette.donut.burntOrange;
      case 'brightOrange':
        return theme.palette.donut.brightOrange;
      case 'lightOrange':
        return theme.palette.donut.lightOrange;
      case 'sunriseOrange':
        return theme.palette.donut.sunriseOrange;
      case 'green':
        return theme.palette.donut.green;
      case 'systemGreen':
        return theme.palette.donut.systemGreen;
      default:
        return theme.palette.donut.coral;
    }
  }
  return ({
    fill: 'transparent',
    stroke: colorPicker(color),
  });
});
