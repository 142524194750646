import { styled } from '@mui/material/styles';
import { Typography, Box, Card, CardContent } from '@mui/material';
import StandardLink from '@/components/atoms/standard-link';
import { withTransientProps } from '@/helpers/with-transient-props';

export const CardComponent = styled(Card, withTransientProps)<{ $dangerzone?: boolean }>(
  ({ theme, $dangerzone = false }) => ({
    padding: theme.spacing(2.0, 2.0, 3.0, 2.0),
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2.0, 5.5, 2.0, 2.0),
    },
    border: $dangerzone ? `${theme.spacing(0.2)} solid ${theme.palette.primary.main}` : 'unset',
  }),
);

export const CardContentRoot = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0,
}));

export const HeadingWrapperDiv = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3.2),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1.4),
  },
}));

export const TypographyHeading = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  color: theme.palette.almostBlack.l30,
  marginRight: theme.spacing(0.8),
}));
export const BoxToolTip = styled(Box)(() => ({
  '& button.MuiFab-root .MuiFab-label svg': {
    fill: '#757575',
  },
}));

export const BoxCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& a': {
      marginLeft: '70%',
    },
  },
  [theme.breakpoints.up('md')]: {
    '& a': {
      marginLeft: '90%',
    },
  },
}));

export const BoxTextContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(-2),
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}));

export const TypographyCountdownText = styled(Typography)(({ theme }) => ({
  countDownText: {
    marginBottom: theme.spacing(1.6),
  },
  display: 'flex',
  flexDirection: 'column',
}));

export const DangerTextLarge = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  [theme.breakpoints.up('xs')]: {
    fontSize: theme.spacing(3.2),
  },
}));

export const DangerTextParagraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const PostedTodayTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1.5),
  color: theme.palette.donut.green,
}));

export const StyledTitle = styled(Typography, withTransientProps)<{ $daysLeft?: number }>(
  ({ theme, $daysLeft = 0 }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
    color: $daysLeft > 6 ? theme.palette.secondary.main : theme.palette.primary.main,
  }),
);

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecorationColor: theme.palette.almostBlack.d20,
  '&:hover, &:focus': {
    textDecorationColor: theme.palette.primary.main,
  },
}));

export const CTAWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'flex-end',
  },
  alignSelf: 'flex-start',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const StyledBoldTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 'bold',
}));
