import { getCurrentDate, toDateTime } from '@/myphoenix/utils/date-time-functions';

export const hasSpecialGrades = (grade: string) => ['I', 'IP', 'IX'].includes(grade);

export const getGradeDisplayText = (courseGrade: string) => {
  switch (courseGrade) {
    case 'MA':
      return 'Mastered';
    case 'ME':
      return 'Met';
    case 'NM':
      return 'Not Met';
    case 'I':
      return 'Incomplete';
    case 'IP':
      return 'In Progress';
    case 'IX':
      return 'In Progress Extension';
    case '':
    case null:
    case undefined:
      return 'Pending';
    default:
      return courseGrade;
  }
};

export const getTooltipVariations = (grade: string, deadDate: string = '') => {
  const inProgress = 'Additional time to finish and submit all assignments is determined by the instructor.';
  const incomplete = 'You\'ll have additional time from the scheduled course completion date to complete and submit all coursework assignments.';
  const inProgressExt = 'The Registrar\'s Office will work with the Disability Services Counselor or the Title IX Coordinator to determine the course completion date for ADA accommodations or Title IX accommodations.';
  const today = getCurrentDate();
  const formattedDeadDate = toDateTime(deadDate);
  if (grade === 'I') {
    return incomplete;
  } if (grade === 'IP') {
    return inProgress;
  } if (grade === 'IX') {
    if (today.hasSame(formattedDeadDate, 'day')) {
      return inProgressExt;
    } if (today.diff(formattedDeadDate, 'day').as('days') <= 7) {
      return inProgressExt;
    } if (today > formattedDeadDate) {
      return inProgressExt;
    } return inProgressExt;
  }
  return null;
};
